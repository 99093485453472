/* Khong hieu sao phai cho menu ra ngoai file rieng thi moi o duoi appbar */
import * as React from "react";
import { Menu, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import TimelineIcon from "@mui/icons-material/Timeline";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles((theme) => ({
  menuText: {
    color: "white",
  },
  menuIcon: {
    color: "white",
  },
}));

export default function ButtonTimKiem(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (props.onClick) {
      props.onClick(event);
    }
  };
  const handleRightClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        variant="contained"
        startIcon={<SearchIcon fontSize="small" />}
        color={props.color ? props.color : "primary"}
        disabled={props.disabled}
        fullWidth={props.fullWidth ? true : false}
        onClick={handleClick}
        onContextMenu={handleRightClick}
      >
        {props.title === undefined ? "Tìm kiếm" : props.title}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            if (props.onRightClickMenu) {
              props.onRightClickMenu({
                menuName: "CodeSearch",
                title: "",
              });
            }
            handleClose();
          }}
        >
          <ListItemIcon>
            <AttachMoneyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Generate code tìm kiếm phân trang</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (props.onRightClickMenu) {
              props.onRightClickMenu({
                menuName: "Columns",
                title: "",
              });
            }
            handleClose();
          }}
        >
          <ListItemIcon>
            <AttachMoneyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Cấu hình columns trong grid</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (props.onRightClickMenu) {
              props.onRightClickMenu({
                menuName: "Generate",
                title: "",
              });
            }
            handleClose();
          }}
        >
          <ListItemIcon>
            <AttachMoneyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Generate code</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (props.onRightClickMenu) {
              props.onRightClickMenu({
                menuName: "GenerateJs",
                title: "",
              });
            }
            handleClose();
          }}
        >
          <ListItemIcon>
            <AttachMoneyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Generate InputForm</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (props.onRightClickMenu) {
              props.onRightClickMenu({
                menuName: "Reload",
                title: "",
              });
            }
            handleClose();
          }}
        >
          <ListItemIcon>
            <AttachMoneyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Reload form</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
