/**Nhap danh muc chu tau */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  OutlinedInput
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  showError,
} from "../components/common";

const server = require("../lib/server");


const styles = () => ({
  red: {
    color: "white",
    backgroundColor: "#fc9003",
  },
  blue: {
    color: "white",
    backgroundColor: "#0384fc",
  },
  uppercaseText: {
    textTransform: "uppercase",
  },
  quantrong: {
    fontWeight: "bold !important",
  },
  colorBlue: {
    color: "blue",
  },
});


function isEmpty(v) {
  return v === undefined || v === null || v + "" === "";
}
function validate_Empty(data, fields) {
  var ret = {};
  fields.map((name) => {
    if (isEmpty(data[name])) {
      ret[name] = true;
    }
  });
  return ret;
}
function validateConfigs(data) {
  var ret = validate_Empty(data, ["ShipCode", "ShipName", "LoginURL", "Account", "Pwd", "DataURL"]);
  if (Object.keys(ret).length == 0) {
    // if (data.PWD2 !== data.PWD) {
    //   ret.PWD2 = true;
    // }
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}

class ShipConfigDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      after: {
        ShipCode: "",
        ShipName: "",
        LoginURL: "",
        Account: "",
        Pwd: "",
        DataURL: "",
        ...(props.edittingRecord ? props.edittingRecord : {}),
      }
    };
  }

  componentDidMount() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_Table_GetAll",
        ThamSo: {
          TableNames: "DM_Ship",
        },
      })
      .then((response) => {
        let data = response.DM_Ship[0];
        if (data) {
          this.saveField("ShipCode", data.ShipCode);
          this.saveField("ShipName", data.ShipName);
          this.saveField("LoginURL", data.LoginURL);
          this.saveField("Account", data.Account);
          this.saveField("Pwd", data.Pwd);
          this.saveField("DataURL", data.DataURL);
        }
      })
      .catch((error) => {
        showError(error);
      });
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    var check = validateConfigs(this.state.after);
    if (!check.error) {
      this.setState({ validateStatus: check, saving: true, errorLog: "" });
      server
        .post("Data/DoRequest", {
          Function: "Proc_DM_Ship_Update",
          ThamSo: {
            ShipCode: this.state.after.ShipCode,
            ShipName: this.state.after.ShipName,
            LoginURL: this.state.after.LoginURL,
            Account: this.state.after.Account,
            Pwd: this.state.after.Pwd,
            DataURL: this.state.after.DataURL,
          },
        })
        .then(() => {
          var before = {};
          var after = {
            ...this.state.after,
          };
          Object.assign(before, after);
          this.setState({
            before: before,
            after: after,
            dataChanged: false,
            canSaved: false,
            saving: false,
            errorMessage: "",
            errorLog: "",
          });
          alert("Cập nhật thành công!");
          this.props.close(after);
        })
        .catch((error) => this.handleServerError(error));
    } else {
      this.setState({ validateStatus: check });
      toast.error("Vui lòng kiểm tra các trường đánh dấu đỏ!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  };
  handleServerError(error) {
    var message = "";
    var log = "";
    try {
      var obj = JSON.parse(error);
      message = obj.message;
      log = obj.traceLog;
      var log_detail = JSON.parse(log);

      if (log_detail && log_detail.Message) {
        message += "\n<br>" + log_detail.Message;
        if (log_detail.ExceptionMessage) {
          message = log_detail.ExceptionMessage + "\n" + message;
        }
      }
    } catch (e) {
      message = error + "";
    }
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"sm"}
        fullWidth={true}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cài đặt thông tin tàu</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>

              <Grid item xs={6}>
                <FormLabel>Mã(*):</FormLabel>
              </Grid>
              <Grid item xs={18}>
                <OutlinedInput
                  fullWidth
                  inputProps={{
                    className: classes.uppercaseText,
                    maxLength: 20,
                  }}
                  value={this.state.after.ShipCode}

                  onChange={(event) => {
                    this.saveData({
                      ShipCode: event.target.value,
                    });
                  }}
                  error={this.state.validateStatus.ShipCode !== undefined}
                />
              </Grid>

              <Grid item xs={6}>
                <FormLabel>Tên tàu(*):</FormLabel>
              </Grid>
              <Grid item xs={18}>
                <OutlinedInput
                  fullWidth
                  autoFocus
                  value={this.state.after.ShipName}
                  onChange={(event) => {
                    this.saveField("ShipName", event.target.value);
                  }}
                  error={this.state.validateStatus.ShipName !== undefined}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel>URL đăng nhập(*):</FormLabel>
              </Grid>
              <Grid item xs={18}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.LoginURL}
                  onChange={(event) => {
                    this.saveField("LoginURL", event.target.value);
                  }}
                  error={this.state.validateStatus.LoginURL !== undefined}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel>Tên đăng nhập(*):</FormLabel>
              </Grid>
              <Grid item xs={18}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.Account}
                  onChange={(event) => {
                    this.saveField("Account", event.target.value);
                  }}
                  error={this.state.validateStatus.Account !== undefined}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel>Mật khẩu(*):</FormLabel>
              </Grid>
              <Grid item xs={18}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.Pwd}
                  type="password"
                  onChange={(event) => {
                    this.saveField("Pwd", event.target.value);
                  }}
                  error={this.state.validateStatus.Pwd !== undefined}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel>URL lấy dữ liệu(*):</FormLabel>
              </Grid>
              <Grid item xs={18}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.DataURL}
                  onChange={(event) => {
                    this.saveField("DataURL", event.target.value);
                  }}
                  error={this.state.validateStatus.DataURL !== undefined}
                />
              </Grid>


            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            Lưu
          </Button>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {this.props.confirm ? "CANCEL" : "Thoát"}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
export default withStyles(styles, { withTheme: true })(ShipConfigDialog);
