/* Khong hieu sao phai cho menu ra ngoai file rieng thi moi o duoi appbar */
import * as React from "react";
import { Menu, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MenuItem, Divider, ListItemIcon, ListItemText } from "@mui/material";
import ClassIcon from "@mui/icons-material/Class";
import Typography from "@mui/material/Typography";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import BusinessIcon from "@mui/icons-material/Business";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import PagesIcon from "@mui/icons-material/Pages";
import {
  LocationOn,
  MenuBook,
  LocationCity,
  Money,
  MoneyOutlined,
  AttachMoney,
  MapOutlined,
  LegendToggleOutlined,
  Person,
  TrackChangesOutlined,
  AdjustOutlined,
  Adjust,
  Approval,
  Flag,
  CorporateFare,
  Sailing,
  DirectionsBoat,
  Email,
} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  menuText: {
    color: "#FFF",
  },
  menuIcon: {
    color: "#FFF",
  },
}));

export default function MenuDanhMuc(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const hasReportData = global.hasRoles("R11");

  const hasThietBi = global.hasRoles("R13");
  const hasLoaiTau = global.hasRoles("R12");
  const hasTaiNan = global.hasRoles("R15");
  const hasVungBien = global.hasRoles("R17");
  const hasDoiTac = global.hasRoles("R16");
  const hasQuocGia = global.hasRoles("R18");

  const hasKhuVuc = global.hasRoles("R1B");
  const hasTauSar = global.hasRoles("R1E");
  const hasThoiTiet = global.hasRoles("R1A");
  return (
    <>
      <IconButton
        id="basic-button"
        sx={{ marginLeft: "auto" }}
        size="large"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {open ? (
          <ArrowDropDownIcon className={classes.menuIcon} />
        ) : (
          <ArrowRightIcon className={classes.menuIcon} />
        )}
        <Typography className={classes.menuText} variant="h6" noWrap>
          {"LIST"}
        </Typography>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {hasReportData ? (
          <>
            <MenuItem
              disabled={!hasReportData}
              onClick={() => {
                if (props.onClick) {
                  props.onClick({
                    name: "config",
                    title: "",
                  });
                }
                handleClose();
              }}
            >
              <ListItemIcon>
                <DirectionsBoat fontSize="small" />
              </ListItemIcon>
              <ListItemText>Config</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem
              disabled={!hasReportData}
              onClick={() => {
                if (props.onClick) {
                  props.onClick({
                    name: "email",
                    title: "",
                  });
                }
                handleClose();
              }}
            >
              <ListItemIcon>
                <Email fontSize="small" />
              </ListItemIcon>
              <ListItemText>Update email</ListItemText>
            </MenuItem>
          </>
        ) : null}
        
      </Menu>
    </>
  );
}
