import "@inovua/reactdatagrid-enterprise/index.css";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import Dropzone from "react-dropzone";
import uuid from "react-uuid";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SyncIcon from "@mui/icons-material/Sync";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyNumberEditor from "../components/MyNumberEditor";
import SimpleDialog from "../components/SimpleDialog";
import { formStyles, handleServerError, showError } from "../components/common";
import FieldNameDialog from "./FieldNameDialog";
import { HighlightSharp, RepeatOnSharp } from "@mui/icons-material";
import MyGroupHeader from "../components/MyGroupHeader";
import MySelect from "../components/MySelect";
import moment from "moment";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Departure Report" : "Báo cáo tàu đến cảng - DR";
const fieldLabels_EN = {
  Captain: "CAPT:",
  VoyageCode: "Voyage:",
  CodeTrip: "Code",
  ReportTypeID: "ReportTypeID",
  ReportTime: "Time:",
  TimeDD: "Time DD:",
  PilotTime: "Pilot on:",
  PilotTime2: "Pilot Of:",
  TimeDC: "Time DC:",
  Position: "Position:",
  Tugs: "Tug:",
  TugTime: "Hrs/Power:",
  TugPower: "Power:",
  DistanceDC: "Distance:",
  PortName: "From:",
  PortName2: "To:",
  Cont20F: "20F/RF/E:",
  Cont40F: "40F/RF/E:",
  Cont45F: "45F/RF/E:",
  ContTotal: "TTL/TEU:",
  ARForeDraft: "ARR Draft:",
  VoyCode: "Pre Voy.:",
  VoyTime: "Finished at:",
  VoyRobFO: "ROB:",
  VoyRobDO: "",
  ForeDraft: "Draft:",
  AftDraft: "",
  CargoMT: "Cargo:",
  CargoTon: "MTs",
  CargoName: "name",
  DisToGo: "D.T.G:",
  ETA: "E.T.A:",
  WeatherSea: "Weather:",
  WeatherWind: "",
  LacanWater: "Lacan:",
  SlopWater: "Slop:",
  RobFODD: "ROB - DD:",
  RobDODD: "",
  RobSumpDD: "LO:",
  RobSysDD: "",
  RobCylDD: "",
  RobAEDD: "",
  RobHydDD: "",
  RobFWDD: "FW:",
  RobFODC: "ROB - DC:",
  RobDODC: "",
  RobSumpDC: "LO:",
  RobSysDC: "",
  RobCylDC: "",
  RobAEDC: "",
  RobHydDC: "",
  RobFWDC: "FW:",
  RpmCounterMEDD: "DD:",
  RpmCounterMEDC: "DC:",
  FlowMEDD: "FM:",
  FlowAEDD: "",
  FlowBLRDD: "",
  FlowTempDD: "T.FM:",
  METempDD: "T.ME:",
  FlowMEDC: "FM:",
  FlowAEDC: "",
  FlowBLRDC: "",
  FlowTempDC: "T.FM:",
  METempDC: "T.ME:",
  ConsFOBLRDD: "BLR",
  ConsDOBLRDD: "",
  ConsFOAEDD: "BF-DD: Cons",
  ConsDOAEDD: "AE",
  ConsCylDD: "LO:",
  ConsSysDD: "",
  ConsAEDD: "",
  ConsHydDD: "",
  ConsFOMEDC: "DD-DC: Cons",
  ConsDOMEDC: "ME",
  ConsFOBLRDC: "BLR",
  ConsDOBLRDC: "",
  ConsFOAEDC: "AE",
  ConsDOAEDC: "",
  ConsCylDC: "LO:",
  ConsSysDC: "",
  ConsAEDC: "",
  ConsHydDC: "",
  RunhAE1DD: "BF-DD:",
  RunhAE2DD: "",
  RunhAE3DD: "",
  RunhIGDD: "IGG",
  RunhBLRDD: "BLR",

  RunhMEDC: "DD-DC:",
  RunhAE1DC: "AE1/AE2/AE3",
  RunhAE2DC: "",
  RunhAE3DC: "",
  RunhIGDC: "IG",
  RunhBLRDC: "BLR",

  ReceiveDOFO: "Receive:",
  RepairLog: "Repair log:",
  MaterialLog: "Material log:",
  Remark: "Remark:",
  GMBallast: "GM & Ballast:",
  Bunker: "B/R + BDN file:",
  OperationMode: "Order sailing mode:",
};
const fieldLabels_VN = {
  VoyageCode: "REF",
  ReportTypeID: "ReportTypeID",
  ReportTime: "Time:",
  TimeDD: "DD:",
  PilotTime: "PILOT:",
  PilotTime2: "",
  TimeDC: "DC:",
  Position: "PSN",
  Tugs: "Number of tug used",
  TugTime: "",
  TugPower: "",
  PortName: "Port",
  Voy: "Voy",
  VoyTime: "Fn",
  VoyRobFO: "ROB",
  VoyRobDO: "",
  ForeDraft: "DRAFT F/A",
  AftDraft: "",
  CargoMT: "CARGO",
  CargoTon: "MTs",
  CargoName: "name",
  DisToGo: "DISTOGO",
  ETA: "ETA",
  WeatherSea: "SEA/WIND",
  WeatherWind: "",
  LacanWater: "BILG WATER",
  SlopWater: "SLOP WATER",
  RobFODD: "ROB-DD:",
  RobDODD: "",
  RobSumpDD: "LO:",
  RobSysDD: "",
  RobCylDD: "",
  RobAEDD: "",
  RobHydDD: "",
  RobFWDD: "FW",
  RobFODC: "ROB-DC:",
  RobDODC: "",
  RobSumpDC: "LO:",
  RobSysDC: "",
  RobCylDC: "",
  RobAEDC: "",
  RobHydDC: "",
  RobFWDC: "FW",
  RpmCounterMEDD: "DD: M/E RPM CTR",
  RpmCounterMEDC: "DC: M/E RPM CTR",
  FlowMEDD: "ME FM/AE FM/BLR FM",
  FlowAEDD: "",
  FlowBLRDD: "",
  FlowTempDD: "T.FM/T.ME",
  METempDD: "",
  FlowMEDC: "ME FM/AE FM/BLR FM",
  FlowAEDC: "",
  FlowBLRDC: "",
  FlowTempDC: "T.FM/T.ME",
  METempDC: "",
  ConsFOBLRDD: "BLR",
  ConsDOBLRDD: "",
  ConsFOAEDD: "CONS(BF-DD): AE",
  ConsDOAEDD: "",
  ConsCylDD: "LO:",
  ConsSysDD: "",
  ConsAEDD: "",
  ConsHydDD: "",
  ConsFOMEDC: "ME",
  ConsDOMEDC: "",
  ConsFOBLRDC: "BLR",
  ConsDOBLRDC: "",
  ConsFOAEDC: "CONS(DD-DC): ME",
  ConsDOAEDC: "",
  ConsCylDC: "LO:",
  ConsSysDC: "",
  ConsAEDC: "",
  ConsHydDC: "",
  RunhAE1DD: "Rnh (BF-DD): AE1/AE2/AE3",
  RunhAE2DD: "",
  RunhAE3DD: "",
  RunhIGDD: "IG",
  RunhBLRDD: "BLR",
  RunhMEDC: "Rnh (DD-DC): ME",
  RunhAE1DC: "AE1/AE2/AE3",
  RunhAE2DC: "",
  RunhAE3DC: "",
  RunhIGDC: "IG",
  RunhBLRDC: "BLR",
  ReceiveDOFO: "Receive",
  RepairLog: "Repair log",
  MaterialLog: "Material log",
  Remark: "Remark",
  GMBallast: "GM & Ballast:",
  Bunker: "B/R + BDN file",
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;
function getFieldNames() {
  var list = [];
  var keys = Object.keys(fieldLabels);
  for (var i = 0; i < keys.length; i++) {
    list.push({
      FieldName: keys[i],
    });
  }
  return list;
}
const listFields = getFieldNames();
const buttonLabels = englishUI
  ? {
      btnLoadRandomSample: "Random sample",
      btnSave: "Save",
      btnSend: "Send",
      btnClose: "Close",
      btnRob: "Load R.O.B",
      btnDraft: "Load Draft",
      btnExport: "Export",
      btnEmail: "Email",
    }
  : {
      btnLoadRandomSample: "Dữ liệu ngẫu nhiên",
      btnSave: "Lưu",
      btnSend: "Gửi",
      btnClose: "Thoát",
      btnRob: "ROB",
      btnDraft: "Load Draft",
      btnExport: "Xuất file",
      btnEmail: "Email",
    };
const CodeTripList = [
  {
    label: "N",
    value: "N",
  },
  {
    label: "S",
    value: "S",
  },
];
function hasReceive(receives) {
  var has = false;
  receives.map((item) => {
    has =
      has ||
      (!myLib.isEmpty(item.ReceiveDate) &&
        (item.FO ||
          item.DO ||
          item.LOCyl ||
          item.LOSys ||
          item.LOAE ||
          item.LOHyd));
  });
  return has;
}
//ham filter su dung cho combobox
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data, atts, send) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateFields(data, global.DepartureReport);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
    if (data.ReceiveDOFO.length > 0 && hasReceive(data.ReceiveDOFO)) {
      if (atts.length <= 2) {
        ret.BunkerFileName = true;
      }
    } else {
      if (atts.length < 2) {
        ret.BunkerFileName = true;
      }
    }
  }
  if (Object.keys(ret).length == 0) {
    //kiem tra thoi gian
    if (new Date(data.TimeDC).getTime() <= new Date(data.TimeDD).getTime()) {
      ret.TimeDC = true;
    }
  }
  ret.error = Object.keys(ret).length > 0;

  return ret;
}

/*------------------------------------FORM--------------------------------------*/
class DepartureReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      DanhMuc: { OS_DailyReport: [] },
      validateStatus: { error: false }, //trang thai validate
      after: {
        DailyReportID: 0,
        Captain: localStorage.getItem("Report.Captain"),
        VoyageCode: null,
        CodeTrip: null,
        ReportTypeID: 1,
        ReportTime: null,
        TimeDD: null,
        PilotTime: null,
        PilotTime2: null,
        TimeDC: null,
        Position: null,
        Tugs: null,
        TugTime: null,
        TugPower: null,
        DistanceDC: null,
        PortName: null,
        PortName2: null,
        VoyTime: null,
        VoyRobFO: null,
        VoyRobDO: null,
        ForeDraft: null,
        AftDraft: null,
        CargoTon: null,
        CargoName: null,
        DisToGo: null,
        ETA: null,
        WeatherSea: null,
        WeatherWind: null,
        LacanWater: null,
        SlopWater: null,
        RobFODD: null,
        RobDODD: null,
        RobSumpDD: null,
        RobSysDD: null,
        RobCylDD: null,
        RobAEDD: null,
        RobHydDD: null,
        RobFWDD: null,
        RobFODC: null,
        RobDODC: null,
        RobSumpDC: null,
        RobSysDC: null,
        RobCylDC: null,
        RobAEDC: null,
        RobHydDC: null,
        RobFWDC: null,
        RpmCounterMEDD: null,
        RpmCounterMEDC: null,
        FlowMEDD: null,
        FlowAEDD: null,
        FlowBLRDD: null,
        Flow3DD: null,
        Flow2DD: null,
        FlowTempDD: null,
        METempDD: null,
        FlowMEDC: null,
        FlowAEDC: null,
        FlowBLRDC: null,
        Flow3DC: null,
        Flow2DC: null,
        FlowTempDC: null,
        METempDC: null,
        ConsFOBLRDD: null,
        ConsDOBLRDD: null,
        ConsFOAEDD: null,
        ConsDOAEDD: null,
        ConsCylDD: null,
        ConsSysDD: null,
        ConsAEDD: null,
        ConsHydDD: null,
        ConsFOMEDC: null,
        ConsDOMEDC: null,
        ConsFOBLRDC: null,
        ConsDOBLRDC: null,
        ConsFOAEDC: null,
        ConsDOAEDC: null,
        ConsCylDC: null,
        ConsSysDC: null,
        ConsAEDC: null,
        ConsHydDC: null,
        RunhAE1DD: null,
        RunhAE2DD: null,
        RunhAE3DD: null,
        RunhIGDD: null,
        RunhBLRDD: null,
        RunhMEDC: null,
        RunhAE1DC: null,
        RunhAE2DC: null,
        RunhAE3DC: null,
        RunhIGDC: null,
        RunhBLRDC: null,
        ReceiveDOFO: [this.newRecord()],
        RepairLog: null,
        MaterialLog: null,
        Remark: null,
        BunkerFileID: null,
        BunkerFileName: null,
        Container: global.ShipInfor.FMEnable.Container ? "Cont" : "", //cho biet co phai tau container khong, phuc vu cho validate
        NoneContainer: global.ShipInfor.FMEnable.Container ? "" : "NoneCont",
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
      reportStatus: props.reportStatus,
      Attachs:
        props.edittingRecord == undefined
          ? []
          : props.edittingRecord.Attachs
          ? props.edittingRecord.Attachs
          : [], // danh sach attach file
      FMEnable: global.ShipInfor.FMEnable, //thong tin cau hinh cua danh muc tau
      lastReportData: null,
      LastROB: {
        RobFO: 0,
        RobDO: 0,
        RobCyl: 0,
        RobSys: 0,
        RobAE: 0,
        RobHyd: 0,
      },
      HasLocalData: !myLib.isEmpty(localStorage.getItem("Report.DD")),
    };
  }

  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps) {
    return { open: nextProps.open };
  }

  componentDidMount() {
    this.loadRandomSample();
  }
  //load rob tu bao cao truoc
  loadROB(time) {
    if (true) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_OS_DailyReport_GetRobTruoc",
          ThamSo: {
            VoyageCode: this.props.defaultVoyageCode,
            ReportDataTime: time,
          },
        })
        .then((response) => {
          let rob = response.ROB.length ? response.ROB[0] : undefined;
          if (rob) {
            if (rob.RobVoyageCode !== this.props.defaultVoyageCode) {
              //lay tu voy code truoc
              //this.state.after.VoyCode = rob.RobVoyageCode;
              //this.state.after.VoyRobFO = rob.RobFO;
              //this.state.after.VoyRobDO = rob.RobDO;
            }
            this.state.LastROB = rob;
            this.calculateROB(); //tinh lai rob
            this.setState({
              LastROB: rob,
            });
          }
        });
    }
  }
  getConsValue(value) {
    return value == undefined || value == null ? 0 : value;
  }
  //load draft tu local storeage
  loadDraft() {
    try {
      let json = localStorage.getItem("Report.DD");
      if (json && json !== "") {
        this.state.after = JSON.parse(json);
        this.state.after.ReportID = uuid();
        this.state.after.DailyReportID = 0; //dam bao la report moi
        console.log(this.state.after);
        this.setState({ saving: false });
      }
    } catch (e) {
      showError(e);
    }
  }
  //load du lieu randomm
  loadRandomSample(demo) {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 1,
            Demo: demo,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 1; //departure report
          //report.ReceiveDOFO = [this.newRecord()];
          report.DailyReportID = 0; // 0 = create new, not 0: update
          report.VoyageCode = myLib.isEmpty(this.props.defaultVoyageCode)
            ? report.VoyageCode
            : this.props.defaultVoyageCode;
          if (demo) {
            //xu ly thogn tin FM
            let keys = Object.keys(report);
            let enables = Object.keys(this.state.FMEnable);
            keys.map((k) => {
              if (k.indexOf("Flow") == 0) {
                let found = enables.find((en) => k.indexOf(en) == 0);
                if (found && !this.state.FMEnable[found]) {
                  report[k] = null;
                }
              }
            });
          }
          this.setState(
            {
              after: { ...this.state.after, ...report },
              dataChanged: true,
            },
            () => this.loadROB()
          );
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  //tra ve luong nhap vao
  getReceive(name) {
    var num = 0;
    this.state.after.ReceiveDOFO.map(
      (item) => (num += item.ReceiveDate && item[name] ? item[name] : 0)
    );
    return isNaN(num) ? 0 : num;
  }
  //tinh lai ROB
  calculateROB() {
    var s = this.state; //new value
    let robFO = s.after.VoyRobFO ? s.after.VoyRobFO : s.LastROB.RobFO;
    if (!myLib.isEmpty(robFO)) {
      s.after.RobFODD =
        robFO -
        this.getConsValue(s.after.ConsFOAEDD) -
        this.getConsValue(s.after.ConsFOBLRDD) +
        this.getReceive("FO");
      s.after.RobFODC =
        s.after.RobFODD -
        this.getConsValue(s.after.ConsFOMEDC) -
        this.getConsValue(s.after.ConsFOAEDC) -
        this.getConsValue(s.after.ConsFOBLRDC);
    }
    //do
    let robDO = s.after.VoyRobDO ? s.after.VoyRobDO : s.LastROB.RobDO;
    if (!myLib.isEmpty(robDO)) {
      s.after.RobDODD =
        robDO -
        this.getConsValue(s.after.ConsDOAEDD) -
        this.getConsValue(s.after.ConsDOPPDD) -
        this.getConsValue(s.after.ConsDOIGDD) -
        this.getConsValue(s.after.ConsDOBLRDD) +
        this.getReceive("DO");
      s.after.RobDODC =
        s.after.RobDODD -
        this.getConsValue(s.after.ConsDOMEDC) -
        this.getConsValue(s.after.ConsDOAEDC) -
        this.getConsValue(s.after.ConsDOPPDC) -
        this.getConsValue(s.after.ConsDOIGDC) -
        this.getConsValue(s.after.ConsDOBLRDC);
    }
    //cyl
    if (!myLib.isEmpty(s.LastROB.RobCyl)) {
      s.after.RobCylDD =
        s.LastROB.RobCyl -
        this.getConsValue(s.after.ConsCylDD) +
        this.getReceive("LOCyl");
      s.after.RobCylDC =
        s.after.RobCylDD - this.getConsValue(s.after.ConsCylDC);
    }
    //sys
    if (!myLib.isEmpty(s.LastROB.RobSys)) {
      s.after.RobSysDD =
        s.LastROB.RobSys -
        this.getConsValue(s.after.ConsSysDD) +
        this.getReceive("LOSys");
      s.after.RobSysDC =
        s.after.RobSysDD - this.getConsValue(s.after.ConsSysDC);
    }
    //GE
    if (!myLib.isEmpty(s.LastROB.RobAE)) {
      s.after.RobAEDD =
        s.LastROB.RobAE -
        this.getConsValue(s.after.ConsAEDD) +
        this.getReceive("LOAE");
      s.after.RobAEDC = s.after.RobAEDD - this.getConsValue(s.after.ConsAEDC);
    }
    //hyd
    if (!myLib.isEmpty(s.LastROB.RobHyd)) {
      s.after.RobHydDD =
        s.LastROB.RobHyd -
        this.getConsValue(s.after.ConsHydDD) +
        this.getReceive("LOHyd");
      s.after.RobHydDC =
        s.after.RobHydDD - this.getConsValue(s.after.ConsHydDC);
    }
    //
  }
  saveField = (fieldName, value) => {
    var s = this.state; //new value

    s.after[fieldName] = value;

    switch (fieldName) {
      case "VoyRobFO":
      case "ConsFOAEDD":
      case "ConsFOBLRDD":
        let robFO = s.after.VoyRobFO ? s.after.VoyRobFO : s.LastROB.RobFO;
        if (!myLib.isEmpty(robFO)) {
          s.after.RobFODD =
            robFO -
            this.getConsValue(s.after.ConsFOAEDD) -
            this.getConsValue(s.after.ConsFOBLRDD) +
            this.getReceive("FO");
          s.after.RobFODC =
            s.after.RobFODD -
            this.getConsValue(s.after.ConsFOMEDC) -
            this.getConsValue(s.after.ConsFOAEDC) -
            this.getConsValue(s.after.ConsFOBLRDC);
        }
        break;
      case "VoyRobDO":
      case "ConsDOAEDD":
      case "ConsDOBLRDD":
      case "ConsDOPPDD":
      case "ConsDOIGDD":
        let robDO = s.after.VoyRobDO ? s.after.VoyRobDO : s.LastROB.RobDO;
        if (!myLib.isEmpty(robDO)) {
          s.after.RobDODD =
            robDO -
            this.getConsValue(s.after.ConsDOAEDD) -
            this.getConsValue(s.after.ConsDOPPDD) -
            this.getConsValue(s.after.ConsDOIGDD) -
            this.getConsValue(s.after.ConsDOBLRDD) +
            this.getReceive("DO");
          s.after.RobDODC =
            s.after.RobDODD -
            this.getConsValue(s.after.ConsDOMEDC) -
            this.getConsValue(s.after.ConsDOAEDC) -
            this.getConsValue(s.after.ConsDOPPDC) -
            this.getConsValue(s.after.ConsDOIGDC) -
            this.getConsValue(s.after.ConsDOBLRDC);
        }
        break;
      case "ConsCylDD":
        if (!myLib.isEmpty(s.LastROB.RobCyl)) {
          s.after.RobCylDD =
            s.LastROB.RobCyl -
            this.getConsValue(s.after.ConsCylDD) +
            this.getReceive("LOCyl");
          s.after.RobCylDC =
            s.after.RobCylDD - this.getConsValue(s.after.ConsCylDC);
        }
        break;
      case "ConsSysDD":
        if (!myLib.isEmpty(s.LastROB.RobSys)) {
          s.after.RobSysDD =
            s.LastROB.RobSys -
            this.getConsValue(s.after.ConsSysDD) +
            this.getReceive("LOSys");
          s.after.RobSysDC =
            s.after.RobSysDD - this.getConsValue(s.after.ConsSysDC);
        }
        break;
      case "ConsAEDD":
        if (!myLib.isEmpty(s.LastROB.RobAE)) {
          s.after.RobAEDD =
            s.LastROB.RobAE -
            this.getConsValue(s.after.ConsAEDD) +
            this.getReceive("LOAE");
          s.after.RobAEDC =
            s.after.RobAEDD - this.getConsValue(s.after.ConsAEDC);
        }
        break;
      case "ConsHydDD":
        if (!myLib.isEmpty(s.LastROB.RobHyd)) {
          s.after.RobHydDD =
            s.LastROB.RobHyd -
            this.getConsValue(s.after.ConsHydDD) +
            this.getReceive("LOHyd");
          s.after.RobHydDC =
            s.after.RobHydDD - this.getConsValue(s.after.ConsHydDC);
        }
        break;
    }
    switch (fieldName) {
      case "ConsFOMEDC":
      case "ConsFOAEDC":
      case "ConsFOBLRDC":
        if (!myLib.isEmpty(s.after.RobFODD)) {
          s.after.RobFODC =
            s.after.RobFODD -
            this.getConsValue(s.after.ConsFOMEDC) -
            this.getConsValue(s.after.ConsFOAEDC) -
            this.getConsValue(s.after.ConsFOBLRDC);
        }
        break;
      case "ConsDOMEDC":
      case "ConsDOAEDC":
      case "ConsDOBLRDC":
      case "ConsDOPPDC":
      case "ConsDOIGDC":
        if (!myLib.isEmpty(s.after.RobDODD)) {
          s.after.RobDODC =
            s.after.RobDODD -
            this.getConsValue(s.after.ConsDOMEDC) -
            this.getConsValue(s.after.ConsDOAEDC) -
            this.getConsValue(s.after.ConsDOPPDC) -
            this.getConsValue(s.after.ConsDOIGDC) -
            this.getConsValue(s.after.ConsDOBLRDC);
        }
        break;
      case "ConsCylDC":
        if (!myLib.isEmpty(s.after.RobCylDD)) {
          s.after.RobCylDC =
            s.after.RobCylDD - this.getConsValue(s.after.ConsCylDC);
        }
        break;
      case "ConsSysDC":
        if (!myLib.isEmpty(s.after.RobSysDD)) {
          s.after.RobSysDC =
            s.after.RobSysDD - this.getConsValue(s.after.ConsSysDC);
        }
        break;
      case "ConsAEDC":
        if (!myLib.isEmpty(s.after.RobAEDD)) {
          s.after.RobAEDC =
            s.after.RobAEDD - this.getConsValue(s.after.ConsAEDC);
        }
        break;
      case "ConsHydDC":
        if (!myLib.isEmpty(s.after.RobHydDD)) {
          s.after.RobHydDC =
            s.after.RobHydDD - this.getConsValue(s.after.ConsHydDC);
        }
        break;
    }
    if (fieldName.indexOf("Cont") == 0 && fieldName !== "ContTotal") {
      //tinh tong so cont va teu theo so luong
      var data = s.after;
      var Cont20 =
        (data.Cont20F ?? 0) + (data.Cont20RF ?? 0) + (data.Cont20E ?? 0);
      var Cont40 =
        (data.Cont40F ?? 0) + (data.Cont40RF ?? 0) + (data.Cont40E ?? 0);
      var Cont45 =
        (data.Cont45F ?? 0) + (data.Cont45RF ?? 0) + (data.Cont45E ?? 0);
      data.ContTotal = Cont20 + Cont40 + Cont45;
      data.TeuTotal = Cont20 + (Cont40 + Cont45) * 2;
    }
    s.dataChanged = true;
    this.setState({ saving: false });
  };
  saveData = (obj) => {
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  saveRecord = (record, index, field, value) => {
    record[field] = value;
    var s = { after: { ...this.state.after } }; //new value
    s.after.ReceiveDOFO[index] = record;
    s.dataChanged = true;
    if (field.indexOf("FO") >= 0 || field == "ReceiveDate") {
      this.saveField("ConsFOAEDD", s.after.ConsFOAEDD);
    } else if (field.indexOf("DO") >= 0 || field == "ReceiveDate") {
      this.saveField("ConsDOAEDD", s.after.ConsDOAEDD);
    } else if (field.indexOf("LOCyl") >= 0 || field == "ReceiveDate") {
      this.saveField("ConsCylDD", s.after.ConsCylDD);
    } else if (field.indexOf("LOSys") >= 0 || field == "ReceiveDate") {
      this.saveField("ConsSysDD", s.after.ConsSysDD);
    } else if (field.indexOf("LOAE") >= 0 || field == "ReceiveDate") {
      this.saveField("ConsAEDD", s.after.ConsAEDD);
    } else if (field.indexOf("LOHyd") >= 0 || field == "ReceiveDate") {
      this.saveField("ConsHydDD", s.after.ConsHydDD);
    } else this.setState(s);
  };

  newRecord() {
    return {
      ReceiveDate: null,
      FO: null,
      DO: null,
      LOCyl: null,
      LOSys: null,
      LOAE: null,
      LOHyd: null,
      FW: null,
    };
  }

  addRecord() {
    var s = { after: { ...this.state.after } };
    s.after.ReceiveDOFO.push(this.newRecord());
    s.dataChanged = true;
    this.setState(s);
  }

  removeRecord(index) {
    var s = { after: { ...this.state.after } };
    s.after.ReceiveDOFO.splice(index, 1);
    s.dataChanged = true;

    this.setState(s);
  }
  doUpload() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_Upload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
    if (this.props.close) {
      this.props.close(this.state.dataSaved);
    }
  }
  getAttachs() {
    //tra ve ten file attachs
    var st = "";
    if (this.state.Attachs) {
      this.state.Attachs.map((att) => {
        st += (st !== "" ? "," : "") + att.FileName;
      });
    }
    return st;
  }
  readOneFile(fileData) {
    //tao promies de doc 1 file
    return new Promise((resolve, reject) => {
      if (fileData.File) {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          fileData.Content = Buffer.from(text).toString("base64");
          fileData.File = undefined; //xoa file di
          resolve(fileData);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(fileData.File);
      } else {
        resolve(fileData);
      }
    });
  }
  readFile = (onDone) => {
    var atts = []; // danh sach file dinh kem

    this.state.Attachs.map((file) => {
      const fileData = {
        File: file.File,
        FileID: file.FileID,
        FileName: file.FileName,
        Content: null,
      };
      atts.push(this.readOneFile(fileData));
    });
    Promise.all(atts)
      .then((danhsach) => onDone(danhsach))
      .catch((e) => {
        showError(e);
      }); //doi doc tat ca cac file
  };

  doSave = (reportStatus) => {
    try {
      if (this.state.after.DailyReportID === 0) {
        localStorage.setItem("Report.DD", JSON.stringify(this.state.after));
      }
      var check = validate_Data(
        this.state.after,
        this.state.Attachs,
        reportStatus
      );
      console.log(check, this.state.after);
      if (!check.error) {
        localStorage.setItem("Report.Captain", this.state.after.Captain);
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        this.readFile((atts) => {
          let attachs = []; //du lieu ve file attach
          atts.map((f) =>
            attachs.push({ FileID: f.FileID, FileName: f.FileName })
          );
          this.state.after.Attachs = attachs;
          server
            .post("Data/DoRequest", {
              Function: "Proc_OS_DailyReport_Update",
              ThamSo: {
                ReportID: this.state.after.ReportID,
                DailyReportID: this.state.after.DailyReportID,
                IsEngine: 0, //Captain
                VoyageCode: this.state.after.VoyageCode,
                ReportTypeID: this.state.after.ReportTypeID,
                ReportTime: this.state.after.ReportTime,
                ReportDataTime: this.state.after.TimeDD,
                ReportDataTime2: this.state.after.TimeDC,
                ReportStatus: reportStatus,
                ReportContent: JSON.stringify(this.state.after),
                AttachedReportID: null, // TODO: select bunker port
                ParentReportID:
                  this.props.edittingRecord !== undefined
                    ? this.props.edittingRecord.ParentReportID
                    : null, // when replace an existing report
                Attachs: atts,
              },
            })
            .then((response) => {
              if (
                response.OS_DailyReport == undefined ||
                response.OS_DailyReport.length === 0
              ) {
                this.props.close(); //de phong truong hop co loi bat thuong xay ra
                return;
              }
              this.state.after.DailyReportID =
                response.OS_DailyReport[0].DailyReportID;
              this.setState(
                {
                  dataSaved: true,
                  dataChanged: false,
                  canSaved: false,
                  saving: false,
                  errorMessage: "",
                  errorLog: "",
                  reportStatus: reportStatus,
                },
                () => {
                  //upload
                  if (reportStatus == 1) {
                    this.doUpload();
                  } else {
                    alert("Cập nhật thành công!");
                  }
                }
              );
              //request ve server
              //server.doRequestProcess();
            })
            .catch((error) => {
              handleServerError(error);
              this.setState({ saving: false });
            });
        });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };
  doExport() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_GetUpload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then((response) => {
        //xuat ra file
        var data = JSON.parse(response.ReportContent[0].NoiDungReport);
        var report_string = data.ReportContent;
        var report = JSON.parse(report_string);
        report.Attachs = []; //bo di phan attachs
        report_string = JSON.stringify(report);
        data.ReportContent = report_string;
        console.log(data);
        var json = JSON.stringify(data);
        const blob = new Blob([json], {
          type: "application/json;charset=utf-8;",
        });
        var fileName = this.state.after.VoyageCode;
        fileName = fileName.replace("/", "-") + "-DD";
        fileName =
          global.ShipInfor.ShipCode +
          "-" +
          moment(this.state.after.ReportTime).format("HHmm-DDMMYY");
        myLib.downloadBlob(blob, fileName);
      })
      .catch((error) => {
        handleServerError(error);
      });
  }
  render() {
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}

              {myLib.isLocalhost ? (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadRandomSample(true)}
                >
                  {buttonLabels.btnLoadRandomSample}
                </Button>
              ) : null}
              {this.state.after.DailyReportID === 0 ? (
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    !this.state.HasLocalData ||
                    this.state.after.DailyReportID > 0
                  }
                  onClick={() => this.loadDraft()}
                >
                  {buttonLabels.btnDraft}
                </Button>
              ) : null}
              {this.state.loading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadROB(this.state.after.TimeDD)}
                >
                  {buttonLabels.btnRob}
                </Button>
              )}
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave(0)}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doSave(1)}
              >
                {buttonLabels.btnSend}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doExport()}
              >
                {buttonLabels.btnExport}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => {
                  myLib.sendEmail(this.state.after);
                  this.state.dataSaved = true;
                }}
              >
                {buttonLabels.btnEmail}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 2,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "VoyageCode",
                    })
                  }
                >
                  {fieldLabels.VoyageCode}
                </FormLabel>
              </Grid>
              <Grid item xs={this.state.FMEnable.Container ? 2 : 4}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  readOnly
                  placeholder=""
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              {this.state.FMEnable.Container ? (
                <Grid item xs={2}>
                  <MySelect
                    id="CodeTrip"
                    key="CodeTrip"
                    fullWidth
                    options={CodeTripList}
                    optionValue="value"
                    optionLabel="label"
                    value={this.state.after.CodeTrip}
                    onChange={(event) => {
                      this.saveField("CodeTrip", event.target.value);
                    }}
                    error={this.state.validateStatus.CodeTrip !== undefined}
                  ></MySelect>
                </Grid>
              ) : null}
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ReportTime",
                    })
                  }
                >
                  {fieldLabels.ReportTime}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel
                  className={classes.quantrong}
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "TimeDD",
                    })
                  }
                >
                  {fieldLabels.TimeDD}
                </FormLabel>
              </Grid>

              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="TimeDD"
                  key="TimeDD"
                  autoFocus
                  fullWidth
                  value={this.state.after.TimeDD}
                  onChange={(value) => {
                    this.saveField("TimeDD", value);
                  }}
                  error={this.state.validateStatus.TimeDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "TimeDC",
                    })
                  }
                >
                  {fieldLabels.TimeDC}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="TimeDC"
                  key="TimeDC"
                  fullWidth
                  value={this.state.after.TimeDC}
                  onChange={(value) => {
                    this.saveField("TimeDC", value);
                  }}
                  error={this.state.validateStatus.TimeDC !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "PilotTime",
                    })
                  }
                >
                  {fieldLabels.PilotTime}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="PilotTime"
                  key="PilotTime"
                  fullWidth
                  placeholder="On"
                  value={this.state.after.PilotTime}
                  onChange={(value) => {
                    this.saveField("PilotTime", value);
                  }}
                  error={this.state.validateStatus.PilotTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "PilotTime",
                    })
                  }
                >
                  {fieldLabels.PilotTime2}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="PilotTime2"
                  key="PilotTime2"
                  fullWidth
                  value={this.state.after.PilotTime2}
                  onChange={(value) => {
                    this.saveField("PilotTime2", value);
                  }}
                  error={this.state.validateStatus.PilotTime2 !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "PortName",
                    })
                  }
                >
                  {fieldLabels.PortName}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="PortName"
                  key="PortName"
                  fullWidth
                  placeholder="from"
                  inputProps={{
                    textTransform: "uppercase",
                  }}
                  value={this.state.after.PortName}
                  onChange={(event) => {
                    this.saveField(
                      "PortName",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.PortName !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "PortName2",
                    })
                  }
                >
                  {fieldLabels.PortName2}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="PortName2"
                  key="PortName2"
                  fullWidth
                  placeholder="to"
                  inputProps={{
                    textTransform: "uppercase",
                  }}
                  value={this.state.after.PortName2}
                  onChange={(event) => {
                    this.saveField(
                      "PortName2",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.PortName2 !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "Position",
                    })
                  }
                >
                  {fieldLabels.Position}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Position"
                  key="Position"
                  fullWidth
                  value={this.state.after.Position}
                  onChange={(event) => {
                    this.saveField("Position", event.target.value);
                  }}
                  error={this.state.validateStatus.Position !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "Tugs",
                    })
                  }
                >
                  {fieldLabels.Tugs}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Tugs"
                  key="Tugs"
                  fullWidth
                  placeholder="number of tugs"
                  value={this.state.after.Tugs}
                  onChange={(event) => {
                    this.saveField("Tugs", event.target.value);
                  }}
                  error={this.state.validateStatus.Tugs !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "TugTime",
                    })
                  }
                >
                  {fieldLabels.TugTime}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="TugTime"
                  key="TugTime"
                  fullWidth
                  placeholder="hours"
                  value={this.state.after.TugTime}
                  onChange={(value) => {
                    this.saveField("TugTime", value);
                  }}
                  error={this.state.validateStatus.TugTime !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <OutlinedInput
                  id="TugPower"
                  key="TugPower"
                  fullWidth
                  placeholder="power of tugs"
                  value={this.state.after.TugPower}
                  onChange={(event) => {
                    this.saveField("TugPower", event.target.value);
                  }}
                  error={this.state.validateStatus.TugPower !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.DistanceDC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="DistanceDC"
                  key="DistanceDC"
                  fullWidth
                  placeholder="Distance DD-DC"
                  value={this.state.after.DistanceDC}
                  onChange={(value) => {
                    this.saveField("DistanceDC", value);
                  }}
                  error={this.state.validateStatus.DistanceDC !== undefined}
                />
              </Grid>
            </Grid>

            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={24}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "Voy",
                    })
                  }
                >
                  {fieldLabels.VoyCode}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="VoyCode"
                  key="VoyCode"
                  fullWidth
                  placeholder=""
                  inputProps={{
                    textTransform: "uppercase",
                  }}
                  value={this.state.after.VoyCode}
                  onChange={(event) => {
                    this.saveField(
                      "VoyCode",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.VoyCode !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "VoyTime",
                    })
                  }
                >
                  {fieldLabels.VoyTime}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="VoyTime"
                  key="VoyTime"
                  fullWidth
                  value={this.state.after.VoyTime}
                  onChange={(value) => {
                    this.saveField("VoyTime", value);
                  }}
                  error={this.state.validateStatus.VoyTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "VoyRobFO",
                    })
                  }
                >
                  {fieldLabels.VoyRobFO}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="VoyRobFO"
                  key="VoyRobFO"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.VoyRobFO}
                  onChange={(value) => {
                    this.saveField("VoyRobFO", value);
                  }}
                  error={this.state.validateStatus.VoyRobFO !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="VoyRobDO"
                  key="VoyRobDO"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.VoyRobDO}
                  onChange={(value) => {
                    this.saveField("VoyRobDO", value);
                  }}
                  error={this.state.validateStatus.VoyRobDO !== undefined}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              {this.state.after.ReceiveDOFO.map((item, index) => (
                <>
                  {index == 0 ? (
                    <>
                      <Grid item xs={2}>
                        <FormLabel
                          className={classes.quantrong}
                          onClick={() =>
                            this.setState({
                              show_thongtin_field: true,
                              Show_FieldName: "ReceiveDOFO",
                            })
                          }
                        >
                          {fieldLabels.ReceiveDOFO}
                        </FormLabel>
                      </Grid>
                    </>
                  ) : null}

                  <Grid item xs={4}>
                    <MyDateEditor
                      id="ReceiveDOFODate"
                      key="ReceiveDOFODate"
                      fullWidth
                      value={item.ReceiveDate}
                      onChange={(value) => {
                        this.saveRecord(item, index, "ReceiveDate", value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="ReceiveFO"
                      key="ReceiveFO"
                      fullWidth
                      placeholder="FO"
                      value={item.FO}
                      onChange={(value) => {
                        this.saveRecord(item, index, "FO", value);
                      }}
                      error={this.state.validateStatus.ReceiveFO !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="ReceiveDO"
                      key="ReceiveDO"
                      fullWidth
                      placeholder="DO"
                      value={item.DO}
                      onChange={(value) => {
                        this.saveRecord(item, index, "DO", value);
                      }}
                      error={this.state.validateStatus.ReceiveDO !== undefined}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="ReceiveLOCyl"
                      key="ReceiveLOCyl"
                      fullWidth
                      placeholder="LO-Cyl"
                      decimalScale={0}
                      value={item.LOCyl}
                      onChange={(value) => {
                        this.saveRecord(item, index, "LOCyl", value);
                      }}
                      error={
                        this.state.validateStatus.ReceiveLOCyl !== undefined
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="ReceiveLOSys"
                      key="ReceiveLOSys"
                      fullWidth
                      placeholder="LO-Sys"
                      decimalScale={0}
                      value={item.LOSys}
                      onChange={(value) => {
                        this.saveRecord(item, index, "LOSys", value);
                      }}
                      error={
                        this.state.validateStatus.ReceiveLOSys !== undefined
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="ReceiveLOAE"
                      key="ReceiveLOAE"
                      fullWidth
                      placeholder="LO-AE"
                      decimalScale={0}
                      value={item.LOAE}
                      onChange={(value) => {
                        this.saveRecord(item, index, "LOAE", value);
                      }}
                      error={this.state.validateStatus.LOAE !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="ReceiveLOHyd"
                      key="ReceiveLOHyd"
                      fullWidth
                      placeholder="LO-Hyd"
                      decimalScale={0}
                      value={item.LOHyd}
                      onChange={(value) => {
                        this.saveRecord(item, index, "LOHyd", value);
                      }}
                      error={this.state.validateStatus.LOHyd !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="ReceiveFW"
                      key="ReceiveFW"
                      fullWidth
                      placeholder="FW"
                      decimalScale={0}
                      value={item.FW}
                      onChange={(value) => {
                        this.saveRecord(item, index, "FW", value);
                      }}
                      error={this.state.validateStatus.ReceiveFW !== undefined}
                    />
                  </Grid>

                  {index == 0 ? (
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => {
                          this.addRecord();
                        }}
                      >
                        Add
                      </Button>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={2}>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            this.removeRecord(index);
                          }}
                        >
                          Remove
                        </Button>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={2}></Grid>
                </>
              ))}
            </Grid>
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={24}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ForeDraft",
                    })
                  }
                >
                  {fieldLabels.ForeDraft}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ForeDraft"
                  key="ForeDraft"
                  fullWidth
                  placeholder="Fore draft"
                  value={this.state.after.ForeDraft}
                  onChange={(value) => {
                    this.saveField("ForeDraft", value);
                  }}
                  error={this.state.validateStatus.ForeDraft !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="AftDraft"
                  key="AftDraft"
                  fullWidth
                  placeholder="Aft. draft"
                  value={this.state.after.AftDraft}
                  onChange={(value) => {
                    this.saveField("AftDraft", value);
                  }}
                  error={this.state.validateStatus.AftDraft !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "CargoMT",
                    })
                  }
                >
                  {fieldLabels.CargoMT}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="CargoTon"
                  key="CargoTon"
                  fullWidth
                  placeholder="MTs"
                  value={this.state.after.CargoTon}
                  onChange={(value) => {
                    this.saveField("CargoTon", value);
                  }}
                  error={this.state.validateStatus.CargoTon !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="CargoName"
                  key="CargoName"
                  fullWidth
                  placeholder="name"
                  value={this.state.after.CargoName}
                  onChange={(event) => {
                    this.saveField("CargoName", event.target.value);
                  }}
                  error={this.state.validateStatus.CargoName !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "DisToGo",
                    })
                  }
                >
                  {fieldLabels.DisToGo}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="DisToGo"
                  key="DisToGo"
                  fullWidth
                  value={this.state.after.DisToGo}
                  onChange={(value) => {
                    this.saveField("DisToGo", value);
                  }}
                  error={this.state.validateStatus.DisToGo !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ETA",
                    })
                  }
                >
                  {fieldLabels.ETA}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ETA"
                  key="ETA"
                  fullWidth
                  value={this.state.after.ETA}
                  onChange={(value) => {
                    this.saveField("ETA", value);
                  }}
                  error={this.state.validateStatus.ETA !== undefined}
                />
              </Grid>

              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "WeatherSea",
                    })
                  }
                >
                  {fieldLabels.WeatherSea}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="WeatherSea"
                  key="WeatherSea"
                  fullWidth
                  placeholder="Sea"
                  value={this.state.after.WeatherSea}
                  onChange={(event) => {
                    this.saveField("WeatherSea", event.target.value);
                  }}
                  error={this.state.validateStatus.WeatherSea !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="WeatherWind"
                  key="WeatherWind"
                  fullWidth
                  placeholder="Wind"
                  value={this.state.after.WeatherWind}
                  onChange={(event) => {
                    this.saveField("WeatherWind", event.target.value);
                  }}
                  error={this.state.validateStatus.WeatherWind !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "LacanWater",
                    })
                  }
                >
                  {fieldLabels.LacanWater}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="LacanWater"
                  key="LacanWater"
                  readOnly={this.state.FMEnable.Slop}
                  fullWidth
                  value={this.state.after.LacanWater}
                  onChange={(value) => {
                    this.saveField("LacanWater", value);
                  }}
                  error={this.state.validateStatus.LacanWater !== undefined}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "SlopWater",
                    })
                  }
                >
                  {fieldLabels.SlopWater}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="SlopWater"
                  key="SlopWater"
                  readOnly={!this.state.FMEnable.Slop}
                  fullWidth
                  value={this.state.after.SlopWater}
                  onChange={(value) => {
                    this.saveField("SlopWater", value);
                  }}
                  error={this.state.validateStatus.SlopWater !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ARForeDraft}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ARForeDraft"
                  key="ARForeDraft"
                  fullWidth
                  placeholder="Fore draft"
                  value={this.state.after.ARForeDraft}
                  onChange={(value) => {
                    this.saveField("ARForeDraft", value);
                  }}
                  error={this.state.validateStatus.ARForeDraft !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ARAftDraft"
                  key="ARAftDraft"
                  fullWidth
                  placeholder="Aft. draft"
                  value={this.state.after.ARAftDraft}
                  onChange={(value) => {
                    this.saveField("ARAftDraft", value);
                  }}
                  error={this.state.validateStatus.ARAftDraft !== undefined}
                />
              </Grid>
            </Grid>
            {this.state.FMEnable.Container ? (
              <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
                <Grid item xs={11}>
                  <hr />
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.groupHeader} align="center">
                    CONT
                  </Typography>
                </Grid>

                <Grid item xs={11}>
                  <hr />
                </Grid>
                <Grid item xs={2}>
                  <FormLabel>{fieldLabels.Cont20F}</FormLabel>
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="Cont20F"
                    key="Cont20F"
                    fullWidth
                    decimalScale={0}
                    placeholder="20F"
                    value={this.state.after.Cont20F}
                    onChange={(value) => {
                      this.saveField("Cont20F", value);
                    }}
                    error={this.state.validateStatus.Cont20F !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="Cont20RF"
                    key="Cont20RF"
                    fullWidth
                    decimalScale={0}
                    placeholder="20RF"
                    value={this.state.after.Cont20RF}
                    onChange={(value) => {
                      this.saveField("Cont20RF", value);
                    }}
                    error={this.state.validateStatus.Cont20RF !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="Cont20E"
                    key="Cont20E"
                    fullWidth
                    decimalScale={0}
                    placeholder="20E"
                    value={this.state.after.Cont20E}
                    onChange={(value) => {
                      this.saveField("Cont20E", value);
                    }}
                    error={this.state.validateStatus.Cont20E !== undefined}
                  />
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={2}>
                  <FormLabel>{fieldLabels.Cont40F}</FormLabel>
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="Cont40F"
                    key="Cont40F"
                    fullWidth
                    decimalScale={0}
                    placeholder="40F"
                    value={this.state.after.Cont40F}
                    onChange={(value) => {
                      this.saveField("Cont40F", value);
                    }}
                    error={this.state.validateStatus.Cont40F !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="Cont40RF"
                    key="Cont40RF"
                    fullWidth
                    decimalScale={0}
                    placeholder="40RF"
                    value={this.state.after.Cont40RF}
                    onChange={(value) => {
                      this.saveField("Cont40RF", value);
                    }}
                    error={this.state.validateStatus.Cont40RF !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="Cont40E"
                    key="Cont40E"
                    fullWidth
                    decimalScale={0}
                    placeholder="40E"
                    value={this.state.after.Cont40E}
                    onChange={(value) => {
                      this.saveField("Cont40E", value);
                    }}
                    error={this.state.validateStatus.Cont40E !== undefined}
                  />
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={2}>
                  <FormLabel>{fieldLabels.Cont45F}</FormLabel>
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="Cont45F"
                    key="Cont45F"
                    fullWidth
                    decimalScale={0}
                    placeholder="45F"
                    value={this.state.after.Cont45F}
                    onChange={(value) => {
                      this.saveField("Cont45F", value);
                    }}
                    error={this.state.validateStatus.Cont45F !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="Cont45RF"
                    key="Cont45RF"
                    fullWidth
                    decimalScale={0}
                    placeholder="45RF"
                    value={this.state.after.Cont45RF}
                    onChange={(value) => {
                      this.saveField("Cont45RF", value);
                    }}
                    error={this.state.validateStatus.Cont45RF !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="Cont45E"
                    key="Cont45E"
                    fullWidth
                    decimalScale={0}
                    placeholder="45E"
                    value={this.state.after.Cont45E}
                    onChange={(value) => {
                      this.saveField("Cont45E", value);
                    }}
                    error={this.state.validateStatus.Cont45E !== undefined}
                  />
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={2}>
                  <FormLabel>{fieldLabels.ContTotal}</FormLabel>
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ContTotal"
                    key="ContTotal"
                    fullWidth
                    decimalScale={0}
                    placeholder="TTL"
                    value={this.state.after.ContTotal}
                    onChange={(value) => {
                      this.saveField("ContTotal", value);
                    }}
                    error={this.state.validateStatus.ContTotal !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="TeuTotal"
                    key="TeuTotal"
                    fullWidth
                    decimalScale={0}
                    placeholder="TEU"
                    value={this.state.after.TeuTotal}
                    onChange={(value) => {
                      this.saveField("TeuTotal", value);
                    }}
                    error={this.state.validateStatus.TeuTotal !== undefined}
                  />
                </Grid>
              </Grid>
            ) : null}
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  RPM & FM
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  className={classes.quantrong}
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RpmCounterMEDD",
                    })
                  }
                >
                  {fieldLabels.RpmCounterMEDD}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>RPM CTR:</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmCounterMEDD"
                  key="RpmCounterMEDD"
                  fullWidth
                  value={this.state.after.RpmCounterMEDD}
                  onChange={(value) => {
                    this.saveField("RpmCounterMEDD", value);
                  }}
                  error={this.state.validateStatus.RpmCounterMEDD !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "FlowMEDD",
                    })
                  }
                >
                  {fieldLabels.FlowMEDD}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMEDD"
                  key="FlowMEDD"
                  fullWidth
                  readOnly={!this.state.FMEnable.FlowME}
                  placeholder="ME"
                  value={this.state.after.FlowMEDD}
                  onChange={(value) => {
                    this.saveField("FlowMEDD", value);
                  }}
                  error={this.state.validateStatus.FlowMEDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAEDD"
                  key="FlowAEDD"
                  fullWidth
                  readOnly={!this.state.FMEnable.FlowAE}
                  placeholder="AE"
                  value={this.state.after.FlowAEDD}
                  onChange={(value) => {
                    this.saveField("FlowAEDD", value);
                  }}
                  error={this.state.validateStatus.FlowAEDD !== undefined}
                />
              </Grid>

              {this.state.FMEnable.Flow2 ? (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow2DD"
                      key="Flow2DD"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow2}
                      placeholder="ME+GE"
                      value={this.state.after.Flow2DD}
                      onChange={(value) => {
                        this.saveField("Flow2DD", value);
                      }}
                      error={this.state.validateStatus.Flow2DD !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRDD"
                      key="FlowBLRDD"
                      fullWidth
                      readOnly={!this.state.FMEnable.FlowBLR}
                      placeholder="BLR"
                      value={this.state.after.FlowBLRDD}
                      onChange={(value) => {
                        this.saveField("FlowBLRDD", value);
                      }}
                      error={this.state.validateStatus.FlowBLRDD !== undefined}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRDD"
                      key="FlowBLRDD"
                      fullWidth
                      readOnly={!this.state.FMEnable.FlowBLR}
                      placeholder="BLR"
                      value={this.state.after.FlowBLRDD}
                      onChange={(value) => {
                        this.saveField("FlowBLRDD", value);
                      }}
                      error={this.state.validateStatus.FlowBLRDD !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow3DD"
                      key="Flow3DD"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow3}
                      placeholder="ALL"
                      value={this.state.after.Flow3DD}
                      onChange={(value) => {
                        this.saveField("Flow3DD", value);
                      }}
                      error={this.state.validateStatus.Flow3DD !== undefined}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "FlowTempDD",
                    })
                  }
                >
                  {fieldLabels.FlowTempDD}
                </FormLabel>
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempDD"
                  key="FlowTempDD"
                  fullWidth
                  placeholder="F.M"
                  value={this.state.after.FlowTempDD}
                  onChange={(value) => {
                    this.saveField("FlowTempDD", value);
                  }}
                  error={this.state.validateStatus.FlowTempDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "METempDD",
                    })
                  }
                >
                  {fieldLabels.METempDD}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="METempDD"
                  key="METempDD"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.METempDD}
                  onChange={(value) => {
                    this.saveField("METempDD", value);
                  }}
                  error={this.state.validateStatus.METempDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RpmCounterMEDC",
                    })
                  }
                >
                  {fieldLabels.RpmCounterMEDC}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>RPM CTR:</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmCounterMEDC"
                  key="RpmCounterMEDC"
                  fullWidth
                  value={this.state.after.RpmCounterMEDC}
                  onChange={(value) => {
                    this.saveField("RpmCounterMEDC", value);
                  }}
                  error={this.state.validateStatus.RpmCounterMEDC !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "FlowMEDC",
                    })
                  }
                >
                  {fieldLabels.FlowMEDC}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMEDC"
                  key="FlowMEDC"
                  fullWidth
                  readOnly={!this.state.FMEnable.FlowME}
                  placeholder="ME"
                  value={this.state.after.FlowMEDC}
                  onChange={(value) => {
                    this.saveField("FlowMEDC", value);
                  }}
                  error={this.state.validateStatus.FlowMEDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAEDC"
                  key="FlowAEDC"
                  fullWidth
                  readOnly={!this.state.FMEnable.FlowAE}
                  placeholder="AE"
                  value={this.state.after.FlowAEDC}
                  onChange={(value) => {
                    this.saveField("FlowAEDC", value);
                  }}
                  error={this.state.validateStatus.FlowAEDC !== undefined}
                />
              </Grid>

              {this.state.FMEnable.Flow2 ? (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow2DC"
                      key="Flow2DC"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow2}
                      placeholder="ME+GE"
                      value={this.state.after.Flow2DC}
                      onChange={(value) => {
                        this.saveField("Flow2DC", value);
                      }}
                      error={this.state.validateStatus.Flow2DC !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRDC"
                      key="FlowBLRDC"
                      fullWidth
                      readOnly={!this.state.FMEnable.FlowBLR}
                      placeholder="BLR"
                      value={this.state.after.FlowBLRDC}
                      onChange={(value) => {
                        this.saveField("FlowBLRDC", value);
                      }}
                      error={this.state.validateStatus.FlowBLRDC !== undefined}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRDC"
                      key="FlowBLRDC"
                      fullWidth
                      readOnly={!this.state.FMEnable.FlowBLR}
                      placeholder="BLR"
                      value={this.state.after.FlowBLRDC}
                      onChange={(value) => {
                        this.saveField("FlowBLRDC", value);
                      }}
                      error={this.state.validateStatus.FlowBLRDC !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow3DC"
                      key="Flow3DC"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow3}
                      placeholder="ALL"
                      value={this.state.after.Flow3DC}
                      onChange={(value) => {
                        this.saveField("Flow3DC", value);
                      }}
                      error={this.state.validateStatus.Flow3DC !== undefined}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "FlowTempDC",
                    })
                  }
                >
                  {fieldLabels.FlowTempDC}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempDC"
                  key="FlowTempDC"
                  fullWidth
                  placeholder="F.M"
                  value={this.state.after.FlowTempDC}
                  onChange={(value) => {
                    this.saveField("FlowTempDC", value);
                  }}
                  error={this.state.validateStatus.FlowTempDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "METempDC",
                    })
                  }
                >
                  {fieldLabels.METempDC}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="METempDC"
                  key="METempDC"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.METempDC}
                  onChange={(value) => {
                    this.saveField("METempDC", value);
                  }}
                  error={this.state.validateStatus.METempDC !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  RNH.HR
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RunhAE1DD",
                    })
                  }
                >
                  {fieldLabels.RunhAE1DD}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhMEDD"
                  key="RunhMEDD"
                  fullWidth
                  placeholder="ME"
                  readOnly
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE1DD"
                  key="RunhAE1DD"
                  fullWidth
                  placeholder="AE1"
                  value={this.state.after.RunhAE1DD}
                  onChange={(value) => {
                    this.saveField("RunhAE1DD", value);
                  }}
                  error={this.state.validateStatus.RunhAE1DD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE2DD"
                  key="RunhAE2DD"
                  fullWidth
                  placeholder="AE2"
                  value={this.state.after.RunhAE2DD}
                  onChange={(value) => {
                    this.saveField("RunhAE2DD", value);
                  }}
                  error={this.state.validateStatus.RunhAE2DD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE3DD"
                  key="RunhAE3DD"
                  fullWidth
                  placeholder="AE3"
                  value={this.state.after.RunhAE3DD}
                  onChange={(value) => {
                    this.saveField("RunhAE3DD", value);
                  }}
                  error={this.state.validateStatus.RunhAE3DD !== undefined}
                />
              </Grid>

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP1DD"
                    key="RunhPP1DD"
                    fullWidth
                    placeholder="PP1"
                    value={this.state.after.RunhPP1DD}
                    onChange={(value) => {
                      this.saveField("RunhPP1DD", value);
                    }}
                    error={this.state.validateStatus.RunhPP1DD !== undefined}
                  />
                </Grid>
              ) : null}

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP2DD"
                    key="RunhPP2DD"
                    fullWidth
                    placeholder="PP2"
                    value={this.state.after.RunhPP2DD}
                    onChange={(value) => {
                      this.saveField("RunhPP2DD", value);
                    }}
                    error={this.state.validateStatus.RunhPP2DD !== undefined}
                  />
                </Grid>
              ) : null}

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhIGDD"
                    key="RunhIGDD"
                    fullWidth
                    readOnly={!this.state.FMEnable.IG}
                    placeholder="IGG"
                    value={this.state.after.RunhIGDD}
                    onChange={(value) => {
                      this.saveField("RunhIGDD", value);
                    }}
                    error={this.state.validateStatus.RunhIGDD !== undefined}
                  />
                </Grid>
              ) : null}
              {this.state.FMEnable.RunhBLR ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhBLRDD"
                    key="RunhBLRDD"
                    fullWidth
                    readOnly={!this.state.FMEnable.RunhBLR}
                    placeholder="BLR"
                    value={this.state.after.RunhBLRDD}
                    onChange={(value) => {
                      this.saveField("RunhBLRDD", value);
                    }}
                    error={this.state.validateStatus.RunhBLRDD !== undefined}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RunhMEDC",
                    })
                  }
                >
                  {fieldLabels.RunhMEDC}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhMEDC"
                  key="RunhMEDC"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.RunhMEDC}
                  onChange={(value) => {
                    this.saveField("RunhMEDC", value);
                  }}
                  error={this.state.validateStatus.RunhMEDC !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE1DC"
                  key="RunhAE1DC"
                  fullWidth
                  placeholder="AE1"
                  value={this.state.after.RunhAE1DC}
                  onChange={(value) => {
                    this.saveField("RunhAE1DC", value);
                  }}
                  error={this.state.validateStatus.RunhAE1DC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE2DC"
                  key="RunhAE2DC"
                  fullWidth
                  placeholder="AE2"
                  value={this.state.after.RunhAE2DC}
                  onChange={(value) => {
                    this.saveField("RunhAE2DC", value);
                  }}
                  error={this.state.validateStatus.RunhAE2DC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE3DC"
                  key="RunhAE3DC"
                  fullWidth
                  placeholder="AE3"
                  value={this.state.after.RunhAE3DC}
                  onChange={(value) => {
                    this.saveField("RunhAE3DC", value);
                  }}
                  error={this.state.validateStatus.RunhAE3DC !== undefined}
                />
              </Grid>

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP1DC"
                    key="RunhPP1DC"
                    fullWidth
                    placeholder="PP1"
                    value={this.state.after.RunhPP1DC}
                    onChange={(value) => {
                      this.saveField("RunhPP1DC", value);
                    }}
                    error={this.state.validateStatus.RunhPP1DC !== undefined}
                  />
                </Grid>
              ) : null}

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP2DC"
                    key="RunhPP2DC"
                    fullWidth
                    placeholder="PP2"
                    value={this.state.after.RunhPP2DC}
                    onChange={(value) => {
                      this.saveField("RunhPP2DC", value);
                    }}
                    error={this.state.validateStatus.RunhPP2DC !== undefined}
                  />
                </Grid>
              ) : null}

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhIGDC"
                    key="RunhIGDC"
                    fullWidth
                    readOnly={!this.state.FMEnable.IG}
                    placeholder="IGG"
                    value={this.state.after.RunhIGDC}
                    onChange={(value) => {
                      this.saveField("RunhIGDC", value);
                    }}
                    error={this.state.validateStatus.RunhIGDC !== undefined}
                  />
                </Grid>
              ) : null}
              {this.state.FMEnable.RunhBLR ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhBLRDC"
                    key="RunhBLRDC"
                    fullWidth
                    readOnly={!this.state.FMEnable.RunhBLR}
                    placeholder="BLR"
                    value={this.state.after.RunhBLRDC}
                    onChange={(value) => {
                      this.saveField("RunhBLRDC", value);
                    }}
                    error={this.state.validateStatus.RunhBLRDC !== undefined}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  CONS
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>

              <Grid item xs={2}>
                <FormLabel
                  className={classes.quantrong}
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ConsFOAEDD",
                    })
                  }
                >
                  {fieldLabels.ConsFOAEDD}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOMEDD"
                  key="ConsFOMEDD"
                  fullWidth
                  placeholder="ME/FO"
                  readOnly
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOMEDD"
                  key="ConsDOMEDD"
                  fullWidth
                  placeholder="ME/DO"
                  readOnly
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAEDD"
                  key="ConsFOAEDD"
                  fullWidth
                  placeholder="AE/FO"
                  value={this.state.after.ConsFOAEDD}
                  onChange={(value) => {
                    this.saveField("ConsFOAEDD", value);
                  }}
                  error={this.state.validateStatus.ConsFOAEDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAEDD"
                  key="ConsDOAEDD"
                  fullWidth
                  placeholder="AE/DO"
                  value={this.state.after.ConsDOAEDD}
                  onChange={(value) => {
                    this.saveField("ConsDOAEDD", value);
                  }}
                  error={this.state.validateStatus.ConsDOAEDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRDD"
                  key="ConsFOBLRDD"
                  fullWidth
                  placeholder="BLR/FO"
                  value={this.state.after.ConsFOBLRDD}
                  onChange={(value) => {
                    this.saveField("ConsFOBLRDD", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRDD"
                  key="ConsDOBLRDD"
                  fullWidth
                  placeholder="BLR/DO"
                  value={this.state.after.ConsDOBLRDD}
                  onChange={(value) => {
                    this.saveField("ConsDOBLRDD", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                {this.state.FMEnable.IG ? (
                  <MyNumberEditor
                    id="ConsDOPPDD"
                    key="ConsDOPPDD"
                    fullWidth
                    placeholder="PP/DO"
                    value={this.state.after.ConsDOPPDD}
                    onChange={(value) => {
                      this.saveField("ConsDOPPDD", value);
                    }}
                    error={this.state.validateStatus.ConsDOPPDD !== undefined}
                  />
                ) : null}
              </Grid>
              <Grid item xs={2}>
                {this.state.FMEnable.IG ? (
                  <MyNumberEditor
                    id="ConsDOIGDD"
                    key="ConsDOIGDD"
                    fullWidth
                    placeholder="IGG/DO"
                    value={this.state.after.ConsDOIGDD}
                    onChange={(value) => {
                      this.saveField("ConsDOIGDD", value);
                    }}
                    error={this.state.validateStatus.ConsDOIGDD !== undefined}
                  />
                ) : null}
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ConsCylDD",
                    })
                  }
                >
                  {fieldLabels.ConsCylDD}
                </FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsCylDD"
                  key="ConsCylDD"
                  fullWidth
                  placeholder="Cyl"
                  decimalScale={0}
                  value={this.state.after.ConsCylDD}
                  onChange={(value) => {
                    this.saveField("ConsCylDD", value);
                  }}
                  error={this.state.validateStatus.ConsCylDD !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsSysDD"
                  key="ConsSysDD"
                  fullWidth
                  placeholder="Sys"
                  decimalScale={0}
                  value={this.state.after.ConsSysDD}
                  onChange={(value) => {
                    this.saveField("ConsSysDD", value);
                  }}
                  error={this.state.validateStatus.ConsSysDD !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsAEDD"
                  key="ConsAEDD"
                  fullWidth
                  placeholder="AE"
                  decimalScale={0}
                  value={this.state.after.ConsAEDD}
                  onChange={(value) => {
                    this.saveField("ConsAEDD", value);
                  }}
                  error={this.state.validateStatus.ConsAEDD !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsHydDD"
                  key="ConsHydDD"
                  fullWidth
                  placeholder="Hyd"
                  decimalScale={0}
                  value={this.state.after.ConsHydDD}
                  onChange={(value) => {
                    this.saveField("ConsHydDD", value);
                  }}
                  error={this.state.validateStatus.ConsHydDD !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ConsFOMEDC",
                    })
                  }
                >
                  {fieldLabels.ConsFOMEDC}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOMEDC"
                  key="ConsFOMEDC"
                  fullWidth
                  placeholder="ME/FO"
                  value={this.state.after.ConsFOMEDC}
                  onChange={(value) => {
                    this.saveField("ConsFOMEDC", value);
                  }}
                  error={this.state.validateStatus.ConsFOMEDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOMEDC"
                  key="ConsDOMEDC"
                  fullWidth
                  placeholder="ME/DO"
                  value={this.state.after.ConsDOMEDC}
                  onChange={(value) => {
                    this.saveField("ConsDOMEDC", value);
                  }}
                  error={this.state.validateStatus.ConsDOMEDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAEDC"
                  key="ConsFOAEDC"
                  fullWidth
                  placeholder="AE/FO"
                  value={this.state.after.ConsFOAEDC}
                  onChange={(value) => {
                    this.saveField("ConsFOAEDC", value);
                  }}
                  error={this.state.validateStatus.ConsFOAEDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAEDC"
                  key="ConsDOAEDC"
                  fullWidth
                  placeholder="AE/DO"
                  value={this.state.after.ConsDOAEDC}
                  onChange={(value) => {
                    this.saveField("ConsDOAEDC", value);
                  }}
                  error={this.state.validateStatus.ConsDOAEDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRDC"
                  key="ConsFOBLRDC"
                  fullWidth
                  placeholder="BLR/FO"
                  value={this.state.after.ConsFOBLRDC}
                  onChange={(value) => {
                    this.saveField("ConsFOBLRDC", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRDC"
                  key="ConsDOBLRDC"
                  fullWidth
                  placeholder="BLR/DO"
                  value={this.state.after.ConsDOBLRDC}
                  onChange={(value) => {
                    this.saveField("ConsDOBLRDC", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOPPDC"
                  key="ConsDOPPDC"
                  fullWidth
                  placeholder="PP/DO"
                  value={this.state.after.ConsDOPPDC}
                  onChange={(value) => {
                    this.saveField("ConsDOPPDC", value);
                  }}
                  error={this.state.validateStatus.ConsDOPPDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOIGDC"
                  key="ConsDOIGDC"
                  fullWidth
                  placeholder="IGG/DO"
                  value={this.state.after.ConsDOIGDC}
                  onChange={(value) => {
                    this.saveField("ConsDOIGDC", value);
                  }}
                  error={this.state.validateStatus.ConsDOIGDC !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ConsCylDC",
                    })
                  }
                >
                  {fieldLabels.ConsCylDC}
                </FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsCylDC"
                  key="ConsCylDC"
                  fullWidth
                  placeholder="Cyl"
                  decimalScale={0}
                  value={this.state.after.ConsCylDC}
                  onChange={(value) => {
                    this.saveField("ConsCylDC", value);
                  }}
                  error={this.state.validateStatus.ConsCylDC !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsSysDC"
                  key="ConsSysDC"
                  fullWidth
                  placeholder="Sys"
                  decimalScale={0}
                  value={this.state.after.ConsSysDC}
                  onChange={(value) => {
                    this.saveField("ConsSysDC", value);
                  }}
                  error={this.state.validateStatus.ConsSysDC !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsAEDC"
                  key="ConsAEDC"
                  fullWidth
                  placeholder="AE"
                  decimalScale={0}
                  value={this.state.after.ConsAEDC}
                  onChange={(value) => {
                    this.saveField("ConsAEDC", value);
                  }}
                  error={this.state.validateStatus.ConsAEDC !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsHydDC"
                  key="ConsHydDC"
                  fullWidth
                  placeholder="Hyd"
                  decimalScale={0}
                  value={this.state.after.ConsHydDC}
                  onChange={(value) => {
                    this.saveField("ConsHydDC", value);
                  }}
                  error={this.state.validateStatus.ConsHydDC !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  R.O.B
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  className={classes.quantrong}
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RobFODD",
                    })
                  }
                >
                  {fieldLabels.RobFODD}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFODD"
                  key="RobFODD"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.RobFODD}
                  onChange={(value) => {
                    this.saveField("RobFODD", value);
                  }}
                  error={this.state.validateStatus.RobFODD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDODD"
                  key="RobDODD"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.RobDODD}
                  onChange={(value) => {
                    this.saveField("RobDODD", value);
                  }}
                  error={this.state.validateStatus.RobDODD !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RobSumpDD",
                    })
                  }
                >
                  {fieldLabels.RobSumpDD}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSumpDD"
                  key="RobSumpDD"
                  fullWidth
                  placeholder="Sump"
                  decimalScale={0}
                  value={this.state.after.RobSumpDD}
                  onChange={(value) => {
                    this.saveField("RobSumpDD", value);
                  }}
                  error={this.state.validateStatus.RobSumpDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSysDD"
                  key="RobSysDD"
                  fullWidth
                  placeholder="Sys"
                  decimalScale={0}
                  value={this.state.after.RobSysDD}
                  onChange={(value) => {
                    this.saveField("RobSysDD", value);
                  }}
                  error={this.state.validateStatus.RobSysDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobCylDD"
                  key="RobCylDD"
                  fullWidth
                  placeholder="Cyl"
                  decimalScale={0}
                  value={this.state.after.RobCylDD}
                  onChange={(value) => {
                    this.saveField("RobCylDD", value);
                  }}
                  error={this.state.validateStatus.RobCylDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobAEDD"
                  key="RobAEDD"
                  fullWidth
                  placeholder="AE"
                  decimalScale={0}
                  value={this.state.after.RobAEDD}
                  onChange={(value) => {
                    this.saveField("RobAEDD", value);
                  }}
                  error={this.state.validateStatus.RobAEDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobHydDD"
                  key="RobHydDD"
                  fullWidth
                  placeholder="Hyd"
                  decimalScale={0}
                  value={this.state.after.RobHydDD}
                  onChange={(value) => {
                    this.saveField("RobHydDD", value);
                  }}
                  error={this.state.validateStatus.RobHydDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RobFWDD",
                    })
                  }
                >
                  {fieldLabels.RobFWDD}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWDD"
                  key="RobFWDD"
                  fullWidth
                  decimalScale={0}
                  value={this.state.after.RobFWDD}
                  onChange={(value) => {
                    this.saveField("RobFWDD", value);
                  }}
                  error={this.state.validateStatus.RobFWDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RobFODC",
                    })
                  }
                >
                  {fieldLabels.RobFODC}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFODC"
                  key="RobFODC"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.RobFODC}
                  onChange={(value) => {
                    this.saveField("RobFODC", value);
                  }}
                  error={this.state.validateStatus.RobFODC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDODC"
                  key="RobDODC"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.RobDODC}
                  onChange={(value) => {
                    this.saveField("RobDODC", value);
                  }}
                  error={this.state.validateStatus.RobDODC !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RobSumpDC",
                    })
                  }
                >
                  {fieldLabels.RobSumpDC}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSumpDC"
                  key="RobSumpDC"
                  fullWidth
                  placeholder="Sump"
                  decimalScale={0}
                  value={this.state.after.RobSumpDC}
                  onChange={(value) => {
                    this.saveField("RobSumpDC", value);
                  }}
                  error={this.state.validateStatus.RobSumpDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSysDC"
                  key="RobSysDC"
                  fullWidth
                  placeholder="Sys"
                  decimalScale={0}
                  value={this.state.after.RobSysDC}
                  onChange={(value) => {
                    this.saveField("RobSysDC", value);
                  }}
                  error={this.state.validateStatus.RobSysDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobCylDC"
                  key="RobCylDC"
                  fullWidth
                  placeholder="Cyl"
                  decimalScale={0}
                  value={this.state.after.RobCylDC}
                  onChange={(value) => {
                    this.saveField("RobCylDC", value);
                  }}
                  error={this.state.validateStatus.RobCylDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobAEDC"
                  key="RobAEDC"
                  fullWidth
                  placeholder="AE"
                  decimalScale={0}
                  value={this.state.after.RobAEDC}
                  onChange={(value) => {
                    this.saveField("RobAEDC", value);
                  }}
                  error={this.state.validateStatus.RobAEDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobHydDC"
                  key="RobHydDC"
                  fullWidth
                  placeholder="Hyd"
                  decimalScale={0}
                  value={this.state.after.RobHydDC}
                  onChange={(value) => {
                    this.saveField("RobHydDC", value);
                  }}
                  error={this.state.validateStatus.RobHydDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RobFWDC",
                    })
                  }
                >
                  {fieldLabels.RobFWDC}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWDC"
                  key="RobFWDC"
                  fullWidth
                  decimalScale={0}
                  value={this.state.after.RobFWDC}
                  onChange={(value) => {
                    this.saveField("RobFWDC", value);
                  }}
                  error={this.state.validateStatus.RobFWDC !== undefined}
                />
              </Grid>
            </Grid>

            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  OTHERS
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RepairLog",
                    })
                  }
                >
                  {fieldLabels.RepairLog}
                </FormLabel>
              </Grid>
              <Grid item xs={10}>
                <OutlinedInput
                  id="RepairLog"
                  key="RepairLog"
                  fullWidth
                  placeholder=""
                  multiline
                  value={this.state.after.RepairLog}
                  onChange={(event) => {
                    this.saveField("RepairLog", event.target.value);
                  }}
                  error={this.state.validateStatus.RepairLog !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "MaterialLog",
                    })
                  }
                >
                  {fieldLabels.MaterialLog}
                </FormLabel>
              </Grid>
              <Grid item xs={10}>
                <OutlinedInput
                  id="MaterialLog"
                  key="MaterialLog"
                  fullWidth
                  multiline
                  placeholder=""
                  value={this.state.after.MaterialLog}
                  onChange={(event) => {
                    this.saveField("MaterialLog", event.target.value);
                  }}
                  error={this.state.validateStatus.MaterialLog !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "MaterialLog",
                    })
                  }
                >
                  {fieldLabels.OperationMode}
                </FormLabel>
              </Grid>
              <Grid item xs={10}>
                <OutlinedInput
                  id="OperationMode"
                  key="OperationMode"
                  fullWidth
                  multiline
                  placeholder=""
                  value={this.state.after.OperationMode}
                  onChange={(event) => {
                    this.saveField("OperationMode", event.target.value);
                  }}
                  error={this.state.validateStatus.OperationMode !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "Remark",
                    })
                  }
                >
                  {fieldLabels.Remark}
                </FormLabel>
              </Grid>
              <Grid item xs={10}>
                <OutlinedInput
                  id="Remark"
                  key="Remark"
                  fullWidth
                  multiline
                  placeholder=""
                  value={this.state.after.Remark}
                  onChange={(event) => {
                    this.saveField("Remark", event.target.value);
                  }}
                  error={this.state.validateStatus.Remark !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "GMBallast",
                    })
                  }
                >
                  {fieldLabels.GMBallast}
                </FormLabel>
              </Grid>
              <Grid item xs={10}>
                <OutlinedInput
                  id="GMBallast"
                  key="GMBallast"
                  fullWidth
                  multiline
                  placeholder=""
                  value={this.state.after.GMBallast}
                  onChange={(event) => {
                    this.saveField("GMBallast", event.target.value);
                  }}
                  error={this.state.validateStatus.GMBallast !== undefined}
                />
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Bunker}</FormLabel>
              </Grid>
              <Grid item xs={10}>
                <OutlinedInput
                  id="Attachs"
                  key="Attachs"
                  fullWidth
                  value={this.getAttachs()}
                  placeholder="BKR/R, BDN, Passage plan"
                  onChange={() => {
                    this.state.Attachs = [];
                    this.setState({ dataChanged: true });
                  }}
                  error={this.state.validateStatus.BunkerFileName !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <Dropzone
                  maxFiles={5}
                  minSize={1}
                  maxSize={500 * 1024}
                  multiple={true}
                  validator={(file) => {
                    if (file.size > 0 && file.size < 500 * 1024) {
                      return null;
                    }
                    alert("File too large");
                    return true;
                  }}
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      this.state.Attachs = [];
                      acceptedFiles.map((file) => {
                        this.state.Attachs.push({
                          FileID: uuid(),
                          FileName: file.name,
                          File: file,
                        });
                      });
                      this.setState({ dataChanged: true });
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button fullWidth variant="contained">
                          Upload
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{"(Max size: 500KB)"}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Captain}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Captain"
                  key="Captain"
                  fullWidth
                  placeholder=""
                  value={this.state.after.Captain}
                  onChange={(event) => {
                    this.saveField("Captain", event.target.value);
                  }}
                  error={this.state.validateStatus.Captain !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
        {this.state.show_thongtin_field ? (
          <FieldNameDialog
            open={true}
            FormName="DepartureReportEditDialog"
            ListFields={listFields}
            FieldName={this.state.Show_FieldName}
            close={() => {
              this.setState({ show_thongtin_field: false });
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  DepartureReportEditDialog
);
