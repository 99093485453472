/**Nhap danh muc chu tau */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  OutlinedInput,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showError, handleServerError } from "../components/common";

const server = require("../lib/server");

const styles = () => ({
  red: {
    color: "white",
    backgroundColor: "#fc9003",
  },
  blue: {
    color: "white",
    backgroundColor: "#0384fc",
  },
  uppercaseText: {
    textTransform: "uppercase",
  },
  quantrong: {
    fontWeight: "bold !important",
  },
  colorBlue: {
    color: "blue",
  },
});

function isEmpty(v) {
  return v === undefined || v === null || v + "" === "";
}
function validate_Empty(data, fields) {
  var ret = {};
  fields.map((name) => {
    if (isEmpty(data[name])) {
      ret[name] = true;
    }
  });
  return ret;
}
function validateConfigs(data) {
  var ret = validate_Empty(data, ["Email", "Password"]);
  if (Object.keys(ret).length == 0) {
    // if (data.PWD2 !== data.PWD) {
    //   ret.PWD2 = true;
    // }
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}

class EmailConfigDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      after: {
        ShipCode: props.ShipCode,
        Email: "",
        Password: "",
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
    };
  }

  componentDidMount() {}
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    var check = validateConfigs(this.state.after);
    if (!check.error) {
      this.setState({ validateStatus: check, saving: true, errorLog: "" });
      server
        .post("Data/EncryptPwd", this.state.after.Password)
        .then((res) => {
          server
            .post("Data/DoRequest", {
              Function: "Proc_Update_OfficeEmail",
              ThamSo: {
                ShipCode: this.state.after.ShipCode,
                Email: this.state.after.Email,
                Password: res,
              },
            })
            .then(() => {
              var before = {};
              var after = {
                ...this.state.after,
              };
              Object.assign(before, after);
              this.setState({
                before: before,
                after: after,
                dataChanged: false,
                canSaved: false,
                saving: false,
                errorMessage: "",
                errorLog: "",
              });
              alert("Cập nhật thành công!");
            })
            .catch((error) => handleServerError(error));
        })
        .catch((error) => {
          handleServerError(error);
          this.setState({ saving: false });
        });
    } else {
      this.setState({ validateStatus: check });
      toast.error("Vui lòng kiểm tra các trường đánh dấu đỏ!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  };
  //test server gui mail
  doTest = () => {
    this.setState({ saving: true, errorLog: "" });
    server
      .post("Data/DoRequest", {
        Function: "Proc_Test_OfficeEmail",
        ThamSo: {
          ShipCode: this.state.after.ShipCode,
        },
      })
      .then((res) => {
        //test
        alert("Một email đã được gửi đến chính hòm thư của tàu, hãy kiểm tra Inbox!");
      })
      .catch((error) => {
        handleServerError(error);
        this.setState({ saving: false });
      });
  };
  
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"sm"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Update Email</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={6}>
                <FormLabel>Ship code(*):</FormLabel>
              </Grid>
              <Grid item xs={18}>
                <OutlinedInput
                  fullWidth
                  inputProps={{
                    className: classes.uppercaseText,
                    maxLength: 20,
                  }}
                  readOnly
                  value={this.state.after.ShipCode}
                  error={this.state.validateStatus.ShipCode !== undefined}
                />
              </Grid>

              <Grid item xs={6}>
                <FormLabel>Email(*):</FormLabel>
              </Grid>
              <Grid item xs={18}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.Email}
                  autoFocus
                  onChange={(event) => {
                    this.saveField("Email", event.target.value);
                  }}
                  error={this.state.validateStatus.Email !== undefined}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel>Password(*):</FormLabel>
              </Grid>
              <Grid item xs={18}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.Password}
                  type="password"
                  onChange={(event) => {
                    this.saveField("Password", event.target.value);
                  }}
                  error={this.state.validateStatus.Password !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            Save
          </Button>
          <Button
            onClick={() => {
              this.doTest();
            }}
            variant="contained"
          >
            Test send
          </Button>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {this.props.confirm ? "CANCEL" : "Thoát"}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
export default withStyles(styles, { withTheme: true })(EmailConfigDialog);
