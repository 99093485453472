import * as React from "react";
import "./App.css";

import { createTheme, ThemeProvider, ThemeOptions } from "@mui/material/styles";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import frLocale from "date-fns/locale/vi";

import HomePage from "./statics/HomePage";
import ShipSelectDialog from "./danhmuc/ShipSelectDialog";

import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/blue-light.css";
import moment from "moment-timezone";

//su dung mac dinh timezone
//moment.tz.setDefault("Asia/Ho_Chi_Minh");

const server = require("./lib/server");
const themeOptions: ThemeOptions = {
  palette: {
    type: "dark",
    primary: {
      main: "#2366c4",
    },
    secondary: {
      main: "#6C757D",
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
  },
  typography: {
    body1: {
      lineHeight: 0.75,
      letterSpacing: "0.02em",
      fontSize: "0.8rem",
      fontWeight: 400,
    },
    caption: {
      lineHeight: 0.9,
    },
    body2: {
      fontSize: "0.7rem",
    },
    h6: {
      fontSize: "1.2rem",
    },
    h4: {
      letterSpacing: "0.05em",
    },
    subtitle1: {
      lineHeight: 1.09,
    },
    overline: {
      lineHeight: 0.92,
    },
  },
  spacing: 4,
};

const componentOptions = {
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "black",
          fontsize: 13,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 4,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: 2,
          "&-MuiAccordion-root.Mui-expanded": {
            marginBottom: 2,
            backgroundColor: "red",
          },
          backgroundImage: `url('/images/bgPageSub.jpg')`,
          color: "black !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: 1,
          fontsize: 13,
          backgroundColor: "info",
        },
        startIcon: {
          "& > *:first-of-type": {
            fontSize: 14,
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontsize: 13,
          padding: 0,
          borderRadius: 3,
        },
        input: {
          padding: 4,
          fontsize: 13,
          "&.Mui-disabled": {
            backgroundColor: "#9D9DA1",
          },
          backgroundColor: "#fff",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 36,
        },
        scroller: {
          backgroundImage: `url('/images/tab.jpg')`,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 4,
          minHeight: 32,
          "&.Mui-selected": {
            color: "white",
          },
        },
        labelIcon: {
          flexDirection: "row",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: 132,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {},
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundImage: `url('/images/bgPageSub.jpg')`,
          padding: 6,
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          backgroundImage: `url('/images/bgPageSub.jpg')`,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundImage: `url('/images/caption.jpg')`,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        region: {
          backgroundImage: `url('/images/bgPageSub.jpg')`,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: "#5781de",
          color: "white",
          minHeight: 24,
          "&.Mui-expanded": {
            minHeight: 0,
          },
          "&-MuiAccordionSummary-root.Mui-expanded": {
            minHeight: 24,
          },
          backgroundImage: `url('/images/caption.gif')`,
          backgroundPosition: `0px -31px`,
        },
        content: {
          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          lineHeight: 1.2,
        },
      },
    },
  },
};
//.css-o4b71y-MuiAccordionSummary-content.Mui-expanded
const theme = createTheme({ ...themeOptions, ...componentOptions });
var versionChecked = false;
var isOfficeVersion = window.location.href.indexOf("office") > 0;
function App() {
  const [showVersion, setShowVersion] = React.useState(false);
  const [showSelectShip, setShowSelectShip] = React.useState(window.IsMultiShip);
  //console.log(moment.tz.names());
  React.useEffect(() => {
    //kiem tra thong tin user
    if (!versionChecked) {
      console.log("checking version...");
      server
        .getUrl("/version.txt")
        //.getUrl("http://localhost:3000/" + "version.txt")
        .then((response) => {
          versionChecked = true;
          console.log("server version:" + response);
          if (response !== window.myconfig.SPAVersion) {
            window.myconfig.ServerVersion = response;
            setShowVersion(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
        {showVersion ? (
          <div
            style={{
              backgroundColor: "yellow",
              color: "white",
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 10000,
            }}
          >
            <a href="#" onClick={() => window.location.reload(true)}>
              Click here to update version {window.myconfig.ServerVersion}
            </a>
          </div>
        ) : null}

        {showSelectShip ? (
          <ShipSelectDialog
            open={true}
            close={() => {
              setShowSelectShip(false);
            }}
          />
        ) : (
          <HomePage />
        )}
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
