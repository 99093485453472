import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  Menu,
  MenuItem,
  getDrawerUtilityClass,
  RadioGroup,
  Radio,
  ButtonBase,
  IconButton,
  Typography,
  CircularProgress,
  Stack,
  AppBar,
  Switch,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
import SelectFilter from "react-select";
import Dropzone from "react-dropzone";
import uuid from "react-uuid";
import SyncIcon from "@mui/icons-material/Sync";
/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";
import MySelect from "../components/MySelect";
import moment from "moment";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Arrival Report" : "Báo cáo tàu đến cảng";
const fieldLabels = englishUI
  ? {
      Captain: "CAPT:",
      VoyageCode: "Voyage:",
      ReportTypeID: "ReportTypeID",
      ReportTime: "Time",
      TimeAC: "Time AC",
      PilotTime: "Pilot On:",
      PilotTime2: "Pilot Off:",
      TimeAA: "Time AA",
      Position: "Position",
      Distance: "Distance (BF-AC):",
      DistanceAA: "Distance(AC-AA):",
      PortName: "Port",
      ArrivalTime: "Arrival:",
      NorTendered: "NOR:",
      DroppedAnchor: "Drop Anchor:",
      ForeDraft: "Draft:",
      AftDraft: "",
      ETB: "ETB/ETD:",
      WeatherSea: "Weather:",
      WeatherWind: "",
      LacanWater: "Lacan:",
      SlopWater: "Slop:",
      RpmMEAC: "RPM ME:",
      BCAAC: "Pr:",
      LoadAC: "Hand load:",
      TminAC: "Tmin:",
      TmaxAC: "Tmax:",
      PaMEAC: "Pa:",
      TaAC: "Ta:",
      AirMEAC: "Air drop:",
      TerAC: "Ter:",
      SlipAC: "Slip:",
      RobFOAC: "ROB - AC:",
      RobDOAC: "",
      RobSumpAC: "LO:",
      RobSysAC: "",
      RobCylAC: "",
      RobAEAC: "",
      RobHydAC: "",
      RobFWAC: "FW:",
      RobFOAA: "ROB - AA:",
      RobDOAA: "",
      RobSumpAA: "LO:",
      RobSysAA: "",
      RobCylAA: "",
      RobAEAA: "",
      RobHydAA: "",
      RobFWAA: "FW:",
      RpmCounterMEAC: "AC:",
      FlowMEAC: "FM:",
      FlowAEAC: "",
      FlowBLRAC: "",
      Flow3AC: "",

      FlowTempAC: "T.FM/ME:",
      METempAC: "T.ME:",
      RpmCounterMEAA: "AA:",
      FlowMEAA: "FM:",
      FlowAEAA: "",
      FlowBLRAA: "",
      FlowTempAA: "T.FM/ME:",
      METempAA: "T.ME:",
      ConsFOMEAC: "BF - AC:",
      ConsDOMEAC: "",
      ConsFOAEAC: "AE",
      ConsDOAEAC: "",
      ConsFOBLRAC: "BLR",
      ConsDOBLRAC: "",
      ConsCylAC: "LO:",
      ConsSysAC: "",
      ConsAEAC: "",
      ConsHydAC: "",
      ConsFOMEAA: "AC - AA:",
      ConsDOMEAA: "",
      ConsFOAEAA: "AE",
      ConsDOAEAA: "",
      ConsFOBLRAA: "BLR",
      ConsDOBLRAA: "",
      ConsCylAA: "LO:",
      ConsSysAA: "",
      ConsAEAA: "",
      ConsHydAA: "",
      FWPureAC: "FW Pur",
      RunhMEAC: "BF - AC",
      RunhAE1AC: "AE1/AE2/AE3",
      RunhAE2AC: "",
      RunhAE3AC: "",
      RunhIGAC: "IG",
      RunhBLRAC: "BLR",
      RunhMEAA: "AC - AA:",
      RunhAE1AA: "AE1/AE2/AE3",
      RunhAE2AA: "",
      RunhAE3AA: "",
      RunhIGAA: "IG",
      RunhBLRAA: "BLR",
      ConsFODCAC: "Cons-FO:",
      BHPDCAC: "BHP:",
      LoadDCAC: "Load:",
      RpmMEDCAC: "RPM:",
      SlipDCAC: "Slip:",
      AvgSpeedDCAC: "Speed:",
      SeaMapLog: "BAC+NP corected:",
      Remark: "Remark:",
      Bunker: "B/R file:",
    }
  : {
      VoyageCode: "REF",
      ReportTypeID: "ReportTypeID",
      ReportTime: "Time",
      TimeAC: "Time AC",
      PilotTime: "Pilot",
      TimeAA: "Time AA",
      Position: "Position",
      PortName: "Port",
      ForeDraft: "Draft F/A",
      AftDraft: "",
      ETB: "ETB/ETD",
      WeatherSea: "Weather (Sea/Wind)",
      WeatherWind: "",
      LacanWater: "Lacan Water",
      SlopWater: "Slop Water",
      RpmMEAC: "RPM ME",
      BCAAC: "Pr",
      LoadAC: "Hand load",
      TminAC: "Tmin",
      TmaxAC: "Tmax",
      PaMEAC: "Pa",
      AirMEAC: "Air drop",
      TerAC: "Ter",
      SlipAC: "Slip",
      RobFOAC: "ROB-AC:",
      RobDOAC: "",
      RobSumpAC: "LO",
      RobSysAC: "",
      RobCylAC: "",
      RobAEAC: "",
      RobHydAC: "",
      RobFWAC: "FW",
      RobFOAA: "ROB-AA:",
      RobDOAA: "",
      RobSumpAA: "LO",
      RobSysAA: "",
      RobCylAA: "",
      RobAEAA: "",
      RobHydAA: "",
      RobFWAA: "FW",
      RpmCounterMEAC: "M/E RPM CTR AC",
      FlowMEAC: "F.M ME/AE/BLR/ALL",
      FlowAEAC: "",
      FlowBLRAC: "",
      Flow3AC: "",
      FlowTempAC: "Temp F.M/ME",
      METempAC: "",
      RpmCounterMEAA: "M/E RPM CTR AA",
      FlowMEAA: "F.M ME/AE/BLR",
      FlowAEAA: "",
      FlowBLRAA: "",
      FlowTempAA: "Temp F.M/ME",
      METempAA: "",
      ConsFOMEAC: "Cons(BF-AC): ME",
      ConsDOMEAC: "",
      ConsFOAEAC: "AE",
      ConsDOAEAC: "",
      ConsFOBLRAC: "BLR",
      ConsDOBLRAC: "",
      ConsCylAC: "LO(Cyl/Sys/AE/Hyd)",
      ConsSysAC: "",
      ConsAEAC: "",
      ConsHydAC: "",
      ConsFOMEAA: "Cons(AC-AA): ME",
      ConsDOMEAA: "",
      ConsFOAEAA: "AE",
      ConsDOAEAA: "",
      ConsFOBLRAA: "BLR",
      ConsDOBLRAA: "",
      ConsCylAA: "LO(Cyl/Sys/AE/Hyd)",
      ConsSysAA: "",
      ConsAEAA: "",
      ConsHydAA: "",
      FWPureAC: "FW Pur",
      RunhMEAC: "Running.Hr (BF-AC): ME",
      RunhAE1AC: "AE1/AE2/AE3",
      RunhAE2AC: "",
      RunhAE3AC: "",
      RunhIGAC: "IG",
      RunhBLRAC: "BLR",
      RunhMEAA: "Running.Hr (AC-AA): ME",
      RunhAE1AA: "AE1/AE2/AE3",
      RunhAE2AA: "",
      RunhAE3AA: "",
      RunhIGAA: "IG",
      RunhBLRAA: "BLR",
      ConsFODCAC: "FO",
      BHPDCAC: "BHP",
      LoadDCAC: "Load",
      RpmMEDCAC: "RPM",
      SlipDCAC: "Slip",
      AvgSpeedDCAC: "Speed",
      SeaMapLog: "BAC+NP corected:",
      Remark: "Remark",
      Bunker: "Bunker report",
    };

const buttonLabels = englishUI
  ? {
      btnLoadRandomSample: "Random sample",
      btnSave: "Save",
      btnSend: "Send",
      btnClose: "Close",
      btnRob: "Load ROB",
      btnDraft: "Load Draft",
      btnExport: "Export",
      btnEmail: "Email",
    }
  : {
      btnLoadRandomSample: "Random sample",
      btnSave: "Lưu",
      btnSend: "Gửi",
      btnClose: "Thoát",
      btnRob: "Load ROB",
      btnDraft: "Load Draft",
      btnExport: "Xuất file",
      btnEmail: "Email",
    };

//ham filter su dung cho combobox
const filterDanhSach = (danhsach, fieldName, inputValue, callback) => {
  inputValue = inputValue ? inputValue.toUpperCase() : "";
  var i = 0;
  var count = 0;
  var list = [];
  for (; i < danhsach.length && count < 10; i++) {
    var item = danhsach[i];
    if (
      inputValue === "" ||
      item[fieldName].toUpperCase().indexOf(inputValue) >= 0
    ) {
      list.push(item);
      count++;
    }
  }
  callback(list);
};
const CodeTripList = [
  {
    label: "N",
    value: "N",
  },
  {
    label: "S",
    value: "S",
  },
];
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data, atts, send) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateFields(data, global.ArrivalReport);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }
  if (Object.keys(ret).length == 0) {
    //kiem tra thoi gian
    if (new Date(data.TimeAA).getTime() <= new Date(data.TimeAC).getTime()) {
      ret.TimeAA = true;
    }
  }
  ret.error = Object.keys(ret).length > 0;

  return ret;
}
/*------------------------------------FORM--------------------------------------*/
class ArrivalReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu duoc save hay chua
      DanhMuc: { OS_DailyReport: [] },
      validateStatus: { error: false }, //trang thai validate
      after: {
        DailyReportID: 0,
        Captain: localStorage.getItem("Report.Captain"),
        VoyageCode: null,
        CodeTrip: null,
        ReportTypeID: 3,
        ReportTime: null,
        TimeAC: null,
        Position: null,
        PilotTime: null,
        PilotTime2: null,
        PortName: null,
        TimeAA: null,
        ForeDraft: null,
        AftDraft: null,
        ETB: null,
        WeatherSea: null,
        WeatherWind: null,
        LacanWater: null,
        SlopWater: null,
        RpmMEAC: null,
        BCAAC: null,
        LoadAC: null,
        TminAC: null,
        TmaxAC: null,
        PaMEAC: null,
        AirMEAC: null,
        TerAC: null,
        SlipAC: null,
        RobFOAC: null,
        RobDOAC: null,
        RobSumpAC: null,
        RobSysAC: null,
        RobCylAC: null,
        RobAEAC: null,
        RobHydAC: null,
        RobFWAC: null,
        RobFOAA: null,
        RobDOAA: null,
        RobSumpAA: null,
        RobSysAA: null,
        RobCylAA: null,
        RobAEAA: null,
        RobHydAA: null,
        RobFWAA: null,
        RpmCounterMEAC: null,
        FlowMEAC: null,
        FlowAEAC: null,
        FlowBLRAC: null,
        Flow3AC: null,
        Flow2AC: null,
        FlowTempAC: null,
        METempAC: null,
        RpmCounterMEAA: null,
        FlowMEAA: null,
        FlowAEAA: null,
        FlowBLRAA: null,
        Flow3AA: null,
        Flow2AA: null,
        FlowTempAA: null,
        METempAA: null,
        ConsFOMEAC: null,
        ConsDOMEAC: null,
        ConsFOAEAC: null,
        ConsDOAEAC: null,
        ConsFOBLRAC: null,
        ConsDOBLRAC: null,
        ConsCylAC: null,
        ConsSysAC: null,
        ConsAEAC: null,
        ConsHydAC: null,
        ConsFOMEAA: null,
        ConsDOMEAA: null,
        ConsFOAEAA: null,
        ConsDOAEAA: null,
        ConsFOBLRAA: null,
        ConsDOBLRAA: null,
        ConsCylAA: null,
        ConsSysAA: null,
        ConsAEAA: null,
        ConsHydAA: null,
        FWPureAC: null,
        RunhMEAC: null,
        RunhAE1AC: null,
        RunhAE2AC: null,
        RunhAE3AC: null,
        RunhIGAC: null,
        RunhLRAC: null,
        RunhMEAA: null,
        RunhAE1AA: null,
        RunhAE2AA: null,
        RunhAE3AA: null,
        RunhIGAA: null,
        RunhBLRAA: null,
        ConsFODCAC: null,
        BHPDCAC: null,
        LoadDCAC: null,
        RpmMEDCAC: null,
        SlipDCAC: null,
        AvgSpeedDCAC: null,
        SeaMapLog: null,
        Remark: null,
        Container: global.ShipInfor.FMEnable.Container ? "Cont" : "", //cho biet co phai tau container khong, phuc vu cho validate
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
      reportStatus: props.reportStatus,
      Attachs:
        props.edittingRecord == undefined || !props.edittingRecord.Attachs
          ? []
          : props.edittingRecord.Attachs, // danh sach attach file
      FMEnable: global.ShipInfor.FMEnable, //thong tin cau hinh cua danh muc tau
      LastROB: {
        RobFO: 0,
        RobDO: 0,
        RobCyl: 0,
        RobSys: 0,
        RobAE: 0,
        RobHyd: 0,
      },
      HasLocalData: !myLib.isEmpty(localStorage.getItem("Report.AA")),
    };
  }

  componentDidMount() {
    this.loadRandomSample();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  //load rob tu bao cao truoc
  loadROB(time) {
    if (true) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_OS_DailyReport_GetRobTruoc",
          ThamSo: {
            VoyageCode: this.props.defaultVoyageCode,
            ReportDataTime: time,
          },
        })
        .then((response) => {
          let rob = response.ROB.length ? response.ROB[0] : undefined;
          if (rob) {
            this.state.LastROB = rob;
            this.calculateROB();
            this.setState({
              LastROB: rob,
            });
          }
        });
    }
  }
  //tinh lai ROB
  calculateROB() {
    var s = this.state; //new value
    if (!myLib.isEmpty(s.LastROB.RobFO)) {
      s.after.RobFOAC =
        s.LastROB.RobFO -
        this.getConsValue(s.after.ConsFOMEAC) -
        this.getConsValue(s.after.ConsFOAEAC) -
        this.getConsValue(s.after.ConsFOBLRAC);

      s.after.RobFOAA =
        s.after.RobFOAC -
        this.getConsValue(s.after.ConsFOMEAA) -
        this.getConsValue(s.after.ConsFOAEAA) -
        this.getConsValue(s.after.ConsFOBLRAA);
    }
    if (!myLib.isEmpty(s.LastROB.RobDO)) {
      s.after.RobDOAC =
        s.LastROB.RobDO -
        this.getConsValue(s.after.ConsDOMEAC) -
        this.getConsValue(s.after.ConsDOAEAC) -
        this.getConsValue(s.after.ConsDOPPAC) -
        this.getConsValue(s.after.ConsDOIGAC) -
        this.getConsValue(s.after.ConsDOBLRAC);

      s.after.RobDOAA =
        s.after.RobDOAC -
        this.getConsValue(s.after.ConsDOMEAA) -
        this.getConsValue(s.after.ConsDOAEAA) -
        this.getConsValue(s.after.ConsDOPPAA) -
        this.getConsValue(s.after.ConsDOIGAA) -
        this.getConsValue(s.after.ConsDOBLRAA);
    }
    if (!myLib.isEmpty(s.LastROB.RobCyl)) {
      s.after.RobCylAC =
        s.LastROB.RobCyl - this.getConsValue(s.after.ConsCylAC);
      s.after.RobCylAA =
        s.after.RobCylAC - this.getConsValue(s.after.ConsCylAA);
    }
    if (!myLib.isEmpty(s.LastROB.RobSys)) {
      s.after.RobSysAC =
        s.LastROB.RobSys - this.getConsValue(s.after.ConsSysAC);
      s.after.RobSysAA =
        s.after.RobSysAC - this.getConsValue(s.after.ConsSysAA);
    }
    if (!myLib.isEmpty(s.LastROB.RobAE)) {
      s.after.RobAEAC = s.LastROB.RobAE - this.getConsValue(s.after.ConsAEAC);
      s.after.RobAEAA = s.after.RobAEAC - this.getConsValue(s.after.ConsAEAA);
    }
    if (!myLib.isEmpty(s.LastROB.RobHyd)) {
      s.after.RobHydAC =
        s.LastROB.RobHyd - this.getConsValue(s.after.ConsHydAC);
      s.after.RobHydAA =
        s.after.RobHydAC - this.getConsValue(s.after.ConsHydAA);
    }
  }
  getConsValue(value) {
    return value == undefined || value == null ? 0 : value;
  }
  //load draft tu local storeage
  loadDraft() {
    try {
      let json = localStorage.getItem("Report.AA");
      if (json && json !== "") {
        this.state.after = JSON.parse(json);
        this.state.after.ReportID = uuid();
        this.state.after.DailyReportID = 0; //dam bao la report moi
        console.log(this.state.after);
        this.setState({ saving: false });
      }
    } catch (e) {
      showError(e);
    }
  }
  //load du lieu randomm
  loadRandomSample(demo) {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 3,
            Demo: demo,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 3; //arrival report
          report.DailyReportID = 0; // 0 = create new, not 0: update
          report.VoyageCode = myLib.isEmpty(this.props.defaultVoyageCode)
            ? report.VoyageCode
            : this.props.defaultVoyageCode;
          if (demo) {
            //xu ly thogn tin FM
            let keys = Object.keys(report);
            let enables = Object.keys(this.state.FMEnable);
            keys.map((k) => {
              if (k.indexOf("Flow") == 0) {
                let found = enables.find((en) => k.indexOf(en) == 0);
                if (found && !this.state.FMEnable[found]) {
                  report[k] = null;
                }
              }
            });
          }
          this.setState(
            {
              after: { ...this.state.after, ...report },
              dataChanged: true,
            },
            () => this.loadROB()
          );
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = this.state; //new value
    s.after[fieldName] = value;
    switch (fieldName) {
      case "ConsFOMEAC":
      case "ConsFOAEAC":
      case "ConsFOBLRAC":
        if (!myLib.isEmpty(s.LastROB.RobFO)) {
          s.after.RobFOAC =
            s.LastROB.RobFO -
            this.getConsValue(s.after.ConsFOMEAC) -
            this.getConsValue(s.after.ConsFOAEAC) -
            this.getConsValue(s.after.ConsFOBLRAC);

          s.after.RobFOAA =
            s.after.RobFOAC -
            this.getConsValue(s.after.ConsFOMEAA) -
            this.getConsValue(s.after.ConsFOAEAA) -
            this.getConsValue(s.after.ConsFOBLRAA);
        }
        break;
      case "ConsDOMEAC":
      case "ConsDOAEAC":
      case "ConsDOBLRAC":
      case "ConsDOPPAC":
      case "ConsDOIGAC":
        if (!myLib.isEmpty(s.LastROB.RobDO)) {
          s.after.RobDOAC =
            s.LastROB.RobDO -
            this.getConsValue(s.after.ConsDOMEAC) -
            this.getConsValue(s.after.ConsDOAEAC) -
            this.getConsValue(s.after.ConsDOPPAC) -
            this.getConsValue(s.after.ConsDOIGAC) -
            this.getConsValue(s.after.ConsDOBLRAC);

          s.after.RobDOAA =
            s.after.RobDOAC -
            this.getConsValue(s.after.ConsDOMEAA) -
            this.getConsValue(s.after.ConsDOAEAA) -
            this.getConsValue(s.after.ConsDOPPAA) -
            this.getConsValue(s.after.ConsDOIGAA) -
            this.getConsValue(s.after.ConsDOBLRAA);
        }
        break;
      case "ConsCylAC":
        if (!myLib.isEmpty(s.LastROB.RobCyl)) {
          s.after.RobCylAC =
            s.LastROB.RobCyl - this.getConsValue(s.after.ConsCylAC);
          s.after.RobCylAA =
            s.after.RobCylAC - this.getConsValue(s.after.ConsCylAA);
        }
        break;
      case "ConsSysAC":
        if (!myLib.isEmpty(s.LastROB.RobSys)) {
          s.after.RobSysAC =
            s.LastROB.RobSys - this.getConsValue(s.after.ConsSysAC);
          s.after.RobSysAA =
            s.after.RobSysAC - this.getConsValue(s.after.ConsSysAA);
        }
        break;
      case "ConsAEAC":
        if (!myLib.isEmpty(s.LastROB.RobAE)) {
          s.after.RobAEAC =
            s.LastROB.RobAE - this.getConsValue(s.after.ConsAEAC);
          s.after.RobAEAA =
            s.after.RobAEAC - this.getConsValue(s.after.ConsAEAA);
        }
        break;
      case "ConsHydAC":
        if (!myLib.isEmpty(s.LastROB.RobHyd)) {
          s.after.RobHydAC =
            s.LastROB.RobHyd - this.getConsValue(s.after.ConsHydAC);
          s.after.RobHydAA =
            s.after.RobHydAC - this.getConsValue(s.after.ConsHydAA);
        }
        break;
    }
    switch (fieldName) {
      case "ConsFOMEAA":
      case "ConsFOAEAA":
      case "ConsFOBLRAA":
        if (!myLib.isEmpty(s.after.RobFOAC)) {
          s.after.RobFOAA =
            s.after.RobFOAC -
            this.getConsValue(s.after.ConsFOMEAA) -
            this.getConsValue(s.after.ConsFOAEAA) -
            this.getConsValue(s.after.ConsFOBLRAA);
        }
        break;
      case "ConsDOMEAA":
      case "ConsDOAEAA":
      case "ConsDOBLRAA":
      case "ConsDOPPAA":
      case "ConsDOIGAA":
        if (!myLib.isEmpty(s.after.RobDOAC)) {
          s.after.RobDOAA =
            s.after.RobDOAC -
            this.getConsValue(s.after.ConsDOMEAA) -
            this.getConsValue(s.after.ConsDOAEAA) -
            this.getConsValue(s.after.ConsDOPPAA) -
            this.getConsValue(s.after.ConsDOIGAA) -
            this.getConsValue(s.after.ConsDOBLRAA);
        }
        break;
      case "ConsCylAA":
        if (!myLib.isEmpty(s.after.RobCylAC)) {
          s.after.RobCylAA =
            s.after.RobCylAC - this.getConsValue(s.after.ConsCylAA);
        }
        break;
      case "ConsSysAA":
        if (!myLib.isEmpty(s.after.RobSysAC)) {
          s.after.RobSysAA =
            s.after.RobSysAC - this.getConsValue(s.after.ConsSysAA);
        }
        break;
      case "ConsAEAA":
        if (!myLib.isEmpty(s.after.RobAEAC)) {
          s.after.RobAEAA =
            s.after.RobAEAC - this.getConsValue(s.after.ConsAEAA);
        }
        break;
      case "ConsHydAA":
        if (!myLib.isEmpty(s.after.RobHydAC)) {
          s.after.RobHydAA =
            s.after.RobHydAC - this.getConsValue(s.after.ConsHydAA);
        }
        break;
      case "RpmCounterMEAC":
      case "RunhMEAC":
      case "Distance":
        //tinh slip, rpm
        if (s.LastROB.RpmCounterME) {
          let counter1 = s.LastROB.RpmCounterME;
          let counter2 = s.after.RpmCounterMEAC;
          if (counter2) {
            let runh = s.after.RunhMEAC;
            if (runh) {
              s.after.RpmMEAC =
                Math.round(((counter2 - counter1) * 10) / (runh * 60)) / 10;
            }

            if (this.state.FMEnable.SlipH && s.after.Distance) {
              let dis = s.after.Distance;
              if (!isNaN(dis) && dis && counter1 !== counter2) {
                //quang duong ly thuyet
                let range =
                  ((counter2 - counter1) * this.state.FMEnable.SlipH) / 1852.0;
                s.after.SlipAC = myLib.round(((range - dis) / range) * 100, 2);
              }
            }
          }
        }
        break;
    }
    s.dataChanged = true;
    this.setState({ saving: false });
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doUpload() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_Upload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
    if (this.props.close) {
      this.props.close(this.state.dataSaved);
    }
  }
  getAttachs() {
    //tra ve ten file attachs
    var st = "";
    if (this.state.Attachs) {
      this.state.Attachs.map((att) => {
        st += (st !== "" ? "," : "") + att.FileName;
      });
    }
    return st;
  }
  readOneFile(fileData) {
    //tao promies de doc 1 file
    return new Promise((resolve, reject) => {
      if (fileData.File) {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          fileData.Content = Buffer.from(text).toString("base64");
          fileData.File = undefined; //xoa file di
          resolve(fileData);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(fileData.File);
      } else {
        resolve(fileData);
      }
    });
  }
  readFile = (onDone) => {
    var atts = []; // danh sach file dinh kem

    this.state.Attachs.map((file) => {
      const fileData = {
        File: file.File,
        FileID: file.FileID,
        FileName: file.FileName,
        Content: null,
      };
      atts.push(this.readOneFile(fileData));
    });
    Promise.all(atts)
      .then((danhsach) => onDone(danhsach))
      .catch((e) => {
        showError(e);
      }); //doi doc tat ca cac file
  };
  doSave = (reportStatus) => {
    try {
      if (this.state.after.DailyReportID === 0) {
        localStorage.setItem("Report.AA", JSON.stringify(this.state.after));
      }
      var check = validate_Data(
        this.state.after,
        this.state.Attachs,
        reportStatus
      );
      console.log(this.state.after, check);
      var action = this.state.after.undefined > 0 ? "update" : "insert";
      if (!check.error) {
        localStorage.setItem("Report.Captain", this.state.after.Captain);
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        this.readFile((atts) => {
          let attachs = []; //du lieu ve file attach
          atts.map((f) =>
            attachs.push({ FileID: f.FileID, FileName: f.FileName })
          );
          this.state.after.Attachs = attachs;
          server
            .post("Data/DoRequest", {
              Function: "Proc_OS_DailyReport_Update",
              ThamSo: {
                ReportID: this.state.after.ReportID,
                DailyReportID: this.state.after.DailyReportID,
                IsEngine: 0, //Captain
                VoyageCode: this.state.after.VoyageCode,
                ReportTypeID: this.state.after.ReportTypeID,
                ReportTime: this.state.after.ReportTime,
                ReportDataTime: this.state.after.TimeAC,
                ReportDataTime2: this.state.after.TimeAA,
                ReportStatus: reportStatus,
                ReportContent: JSON.stringify(this.state.after),
                AttachedReportID: null, // TODO: select bunker port
                ParentReportID:
                  this.props.edittingRecord !== undefined
                    ? this.props.edittingRecord.ParentReportID
                    : null, // when replace an existing report
                Attachs: atts,
              },
            })
            .then((response) => {
              if (
                response.OS_DailyReport == undefined ||
                response.OS_DailyReport.length === 0
              ) {
                this.props.close(); //de phong truong hop co loi bat thuong xay ra
                return;
              }
              this.state.after.DailyReportID =
                response.OS_DailyReport[0].DailyReportID;
              this.setState(
                {
                  dataSaved: true,
                  dataChanged: false,
                  canSaved: false,
                  saving: false,
                  errorMessage: "",
                  errorLog: "",
                  reportStatus: reportStatus,
                },
                () => {
                  //upload
                  if (reportStatus == 1) {
                    this.doUpload();
                  } else {
                    alert("Cập nhật thành công!");
                  }
                }
              );
              //request ve server
              //server.doRequestProcess();
            })
            .catch((error) => {
              handleServerError(error);
              this.setState({ saving: false });
            });
        });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };
  doExport() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_GetUpload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then((response) => {
        //xuat ra file
        var data = JSON.parse(response.ReportContent[0].NoiDungReport);
        var report_string = data.ReportContent;
        var report = JSON.parse(report_string);
        report.Attachs = []; //bo di phan attachs
        report_string = JSON.stringify(report);
        data.ReportContent = report_string;
        console.log(data);
        var json = JSON.stringify(data);
        const blob = new Blob([json], {
          type: "application/json;charset=utf-8;",
        });
        var fileName = this.state.after.VoyageCode;
        fileName = fileName.replace("/", "-") + "-AA";
        fileName =
          global.ShipInfor.ShipCode +
          "-" +
          moment(this.state.after.ReportTime).format("HHmm-DDMMYY");
        myLib.downloadBlob(blob, fileName);
      })
      .catch((error) => {
        handleServerError(error);
      });
  }
  render() {
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
              {myLib.isLocalhost ? (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadRandomSample(true)}
                >
                  {buttonLabels.btnLoadRandomSample}
                </Button>
              ) : null}
              {this.state.after.DailyReportID === 0 ? (
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    !this.state.HasLocalData ||
                    this.state.after.DailyReportID > 0
                  }
                  onClick={() => this.loadDraft()}
                >
                  {buttonLabels.btnDraft}
                </Button>
              ) : null}
              {this.state.loading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadROB(this.state.after.TimeAC)}
                >
                  {buttonLabels.btnRob}
                </Button>
              )}
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave(0)}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doSave(1)}
              >
                {buttonLabels.btnSend}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doExport()}
              >
                {buttonLabels.btnExport}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => {
                  myLib.sendEmail(this.state.after);
                  this.state.dataSaved = true;
                }}
              >
                {buttonLabels.btnEmail}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
              </Grid>
              <Grid item xs={this.state.FMEnable.Container ? 2 : 4}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  readOnly
                  placeholder=""
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              {this.state.FMEnable.Container ? (
                <Grid item xs={2}>
                  <MySelect
                    id="CodeTrip"
                    key="CodeTrip"
                    fullWidth
                    options={CodeTripList}
                    optionValue="value"
                    optionLabel="label"
                    value={this.state.after.CodeTrip}
                    onChange={(event) => {
                      this.saveField("CodeTrip", event.target.value);
                    }}
                    error={this.state.validateStatus.CodeTrip !== undefined}
                  ></MySelect>
                </Grid>
              ) : null}

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ReportTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TimeAC}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="TimeAC"
                  key="TimeAC"
                  fullWidth
                  autoFocus
                  value={this.state.after.TimeAC}
                  onChange={(value) => {
                    this.saveField("TimeAC", value);
                  }}
                  error={this.state.validateStatus.TimeAC !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TimeAA}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="TimeAA"
                  key="TimeAA"
                  fullWidth
                  value={this.state.after.TimeAA}
                  onChange={(value) => {
                    this.saveField("TimeAA", value);
                  }}
                  error={this.state.validateStatus.TimeAA !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PilotTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="PilotTime"
                  key="PilotTime"
                  fullWidth
                  value={this.state.after.PilotTime}
                  onChange={(value) => {
                    this.saveField("PilotTime", value);
                  }}
                  error={this.state.validateStatus.PilotTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PilotTime2}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="PilotTime2"
                  key="PilotTime2"
                  fullWidth
                  value={this.state.after.PilotTime2}
                  onChange={(value) => {
                    this.saveField("PilotTime2", value);
                  }}
                  error={this.state.validateStatus.PilotTime2 !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PortName}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="PortName"
                  key="PortName"
                  fullWidth
                  inputProps={{
                    textTransform: "uppercase",
                  }}
                  value={this.state.after.PortName}
                  onChange={(event) => {
                    this.saveField(
                      "PortName",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.PortName !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Position}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Position"
                  key="Position"
                  fullWidth
                  value={this.state.after.Position}
                  onChange={(event) => {
                    this.saveField("Position", event.target.value);
                  }}
                  error={this.state.validateStatus.Position !== undefined}
                />
              </Grid>
              {this.state.FMEnable.Container ? null : (
                <>
                  <Grid item xs={2}>
                    <FormLabel>{fieldLabels.ArrivalTime}</FormLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <MyDateTimeEditor
                      id="ArrivalTime"
                      key="ArrivalTime"
                      fullWidth
                      autoFocus
                      value={this.state.after.ArrivalTime}
                      onChange={(value) => {
                        this.saveField("ArrivalTime", value);
                      }}
                      error={
                        this.state.validateStatus.ArrivalTime !== undefined
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormLabel>{fieldLabels.NorTendered}</FormLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <MyDateTimeEditor
                      id="NorTendered"
                      key="NorTendered"
                      fullWidth
                      autoFocus
                      value={this.state.after.NorTendered}
                      onChange={(value) => {
                        this.saveField("NorTendered", value);
                      }}
                      error={
                        this.state.validateStatus.NorTendered !== undefined
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormLabel>{fieldLabels.DroppedAnchor}</FormLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <MyDateTimeEditor
                      id="DroppedAnchor"
                      key="DroppedAnchor"
                      fullWidth
                      autoFocus
                      value={this.state.after.DroppedAnchor}
                      onChange={(value) => {
                        this.saveField("DroppedAnchor", value);
                      }}
                      error={
                        this.state.validateStatus.DroppedAnchor !== undefined
                      }
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Distance}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="Distance"
                  key="Distance"
                  fullWidth
                  placeholder="BF-AC"
                  value={this.state.after.Distance}
                  onChange={(value) => {
                    this.saveField("Distance", value);
                  }}
                  error={this.state.validateStatus.Distance !== undefined}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.DistanceAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="DistanceAA"
                  key="DistanceAA"
                  fullWidth
                  placeholder="AC-AA"
                  value={this.state.after.DistanceAA}
                  onChange={(value) => {
                    this.saveField("DistanceAA", value);
                  }}
                  error={this.state.validateStatus.DistanceAA !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={26}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ForeDraft}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ForeDraft"
                  key="ForeDraft"
                  fullWidth
                  placeholder="Fore draft"
                  value={this.state.after.ForeDraft}
                  onChange={(value) => {
                    this.saveField("ForeDraft", value);
                  }}
                  error={this.state.validateStatus.ForeDraft !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="AftDraft"
                  key="AftDraft"
                  fullWidth
                  placeholder="Aft. draft"
                  value={this.state.after.AftDraft}
                  onChange={(value) => {
                    this.saveField("AftDraft", value);
                  }}
                  error={this.state.validateStatus.AftDraft !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ETB}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ETB"
                  key="ETB"
                  fullWidth
                  value={this.state.after.ETB}
                  onChange={(value) => {
                    this.saveField("ETB", value);
                  }}
                  error={this.state.validateStatus.ETB !== undefined}
                />
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.WeatherSea}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="WeatherSea"
                  key="WeatherSea"
                  fullWidth
                  placeholder="Sea"
                  autoFocus={false}
                  value={this.state.after.WeatherSea}
                  onChange={(event) => {
                    this.saveField("WeatherSea", event.target.value);
                  }}
                  error={this.state.validateStatus.WeatherSea !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="WeatherWind"
                  key="WeatherWind"
                  fullWidth
                  placeholder="Wind"
                  autoFocus={false}
                  value={this.state.after.WeatherWind}
                  onChange={(event) => {
                    this.saveField("WeatherWind", event.target.value);
                  }}
                  error={this.state.validateStatus.WeatherWind !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.LacanWater}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="LacanWater"
                  key="LacanWater"
                  fullWidth
                  decimalScale={4}
                  readOnly={this.state.FMEnable.Slop}
                  value={this.state.after.LacanWater}
                  onChange={(value) => {
                    this.saveField("LacanWater", value);
                  }}
                  error={this.state.validateStatus.LacanWater !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.SlopWater}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="SlopWater"
                  key="SlopWater"
                  fullWidth
                  readOnly={!this.state.FMEnable.Slop}
                  value={this.state.after.SlopWater}
                  onChange={(value) => {
                    this.saveField("SlopWater", value);
                  }}
                  error={this.state.validateStatus.SlopWater !== undefined}
                />
              </Grid>
            </Grid>

            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  RPM & FM
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RpmCounterMEAC}</FormLabel>
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmCounterMEAC"
                  key="RpmCounterMEAC"
                  fullWidth
                  placeholder="RPM CTR"
                  value={this.state.after.RpmCounterMEAC}
                  onChange={(value) => {
                    this.saveField("RpmCounterMEAC", value);
                  }}
                  error={this.state.validateStatus.RpmCounterMEAC !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowMEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMEAC"
                  key="FlowMEAC"
                  fullWidth
                  readOnly={!this.state.FMEnable.FlowME}
                  placeholder="ME"
                  value={this.state.after.FlowMEAC}
                  onChange={(value) => {
                    this.saveField("FlowMEAC", value);
                  }}
                  error={this.state.validateStatus.FlowMEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAEAC"
                  key="FlowAEAC"
                  fullWidth
                  readOnly={!this.state.FMEnable.FlowAE}
                  placeholder="AE"
                  value={this.state.after.FlowAEAC}
                  onChange={(value) => {
                    this.saveField("FlowAEAC", value);
                  }}
                  error={this.state.validateStatus.FlowAEAC !== undefined}
                />
              </Grid>

              {this.state.FMEnable.Flow2 ? (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow2AC"
                      key="Flow2AC"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow2}
                      placeholder="ME+GE"
                      value={this.state.after.Flow2AC}
                      onChange={(value) => {
                        this.saveField("Flow2AC", value);
                      }}
                      error={this.state.validateStatus.Flow2AC !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRAC"
                      key="FlowBLRAC"
                      fullWidth
                      readOnly={!this.state.FMEnable.FlowBLR}
                      placeholder="BLR"
                      value={this.state.after.FlowBLRAC}
                      onChange={(value) => {
                        this.saveField("FlowBLRAC", value);
                      }}
                      error={this.state.validateStatus.FlowBLRAC !== undefined}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRAC"
                      key="FlowBLRAC"
                      fullWidth
                      readOnly={!this.state.FMEnable.FlowBLR}
                      placeholder="BLR"
                      value={this.state.after.FlowBLRAC}
                      onChange={(value) => {
                        this.saveField("FlowBLRAC", value);
                      }}
                      error={this.state.validateStatus.FlowBLRAC !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow3AC"
                      key="Flow3AC"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow3}
                      placeholder="ALL"
                      value={this.state.after.Flow3AC}
                      onChange={(value) => {
                        this.saveField("Flow3AC", value);
                      }}
                      error={this.state.validateStatus.Flow3AC !== undefined}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowTempAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempAC"
                  key="FlowTempAC"
                  fullWidth
                  placeholder="F.M"
                  value={this.state.after.FlowTempAC}
                  onChange={(value) => {
                    this.saveField("FlowTempAC", value);
                  }}
                  error={this.state.validateStatus.FlowTempAC !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="METempAC"
                  key="METempAC"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.METempAC}
                  onChange={(value) => {
                    this.saveField("METempAC", value);
                  }}
                  error={this.state.validateStatus.METempAC !== undefined}
                />
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RpmCounterMEAA}</FormLabel>
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmCounterMEAA"
                  key="RpmCounterMEAA"
                  fullWidth
                  placeholder="RPM CTR"
                  value={this.state.after.RpmCounterMEAA}
                  onChange={(value) => {
                    this.saveField("RpmCounterMEAA", value);
                  }}
                  error={this.state.validateStatus.RpmCounterMEAA !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowMEAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMEAA"
                  key="FlowMEAA"
                  fullWidth
                  readOnly={!this.state.FMEnable.FlowME}
                  placeholder="ME"
                  value={this.state.after.FlowMEAA}
                  onChange={(value) => {
                    this.saveField("FlowMEAA", value);
                  }}
                  error={this.state.validateStatus.FlowMEAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAEAA"
                  key="FlowAEAA"
                  fullWidth
                  readOnly={!this.state.FMEnable.FlowAE}
                  placeholder="AE"
                  value={this.state.after.FlowAEAA}
                  onChange={(value) => {
                    this.saveField("FlowAEAA", value);
                  }}
                  error={this.state.validateStatus.FlowAEAA !== undefined}
                />
              </Grid>

              {this.state.FMEnable.Flow2 ? (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow2AA"
                      key="Flow2AA"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow2}
                      placeholder="ME+GE"
                      value={this.state.after.Flow2AA}
                      onChange={(value) => {
                        this.saveField("Flow2AA", value);
                      }}
                      error={this.state.validateStatus.Flow2AA !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRAA"
                      key="FlowBLRAA"
                      fullWidth
                      readOnly={!this.state.FMEnable.FlowBLR}
                      placeholder="BLR"
                      value={this.state.after.FlowBLRAA}
                      onChange={(value) => {
                        this.saveField("FlowBLRAA", value);
                      }}
                      error={this.state.validateStatus.FlowBLRAA !== undefined}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRAA"
                      key="FlowBLRAA"
                      fullWidth
                      readOnly={!this.state.FMEnable.FlowBLR}
                      placeholder="BLR"
                      value={this.state.after.FlowBLRAA}
                      onChange={(value) => {
                        this.saveField("FlowBLRAA", value);
                      }}
                      error={this.state.validateStatus.FlowBLRAA !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow3AA"
                      key="Flow3AA"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow3}
                      placeholder="ALL"
                      value={this.state.after.Flow3AA}
                      onChange={(value) => {
                        this.saveField("Flow3AA", value);
                      }}
                      error={this.state.validateStatus.Flow3AA !== undefined}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowTempAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempAA"
                  key="FlowTempAA"
                  fullWidth
                  placeholder="F.M"
                  value={this.state.after.FlowTempAA}
                  onChange={(value) => {
                    this.saveField("FlowTempAA", value);
                  }}
                  error={this.state.validateStatus.FlowTempAA !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="METempAA"
                  key="METempAA"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.METempAA}
                  onChange={(value) => {
                    this.saveField("METempAA", value);
                  }}
                  error={this.state.validateStatus.METempAA !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  RNH.HR
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhMEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhMEAC"
                  key="RunhMEAC"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.RunhMEAC}
                  onChange={(value) => {
                    this.saveField("RunhMEAC", value);
                  }}
                  error={this.state.validateStatus.RunhMEAC !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE1AC"
                  key="RunhAE1AC"
                  fullWidth
                  placeholder="AE1"
                  value={this.state.after.RunhAE1AC}
                  onChange={(value) => {
                    this.saveField("RunhAE1AC", value);
                  }}
                  error={this.state.validateStatus.RunhAE1AC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE2AC"
                  key="RunhAE2AC"
                  fullWidth
                  placeholder="AE2"
                  value={this.state.after.RunhAE2AC}
                  onChange={(value) => {
                    this.saveField("RunhAE2AC", value);
                  }}
                  error={this.state.validateStatus.RunhAE2AC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE3AC"
                  key="RunhAE3AC"
                  fullWidth
                  placeholder="AE3"
                  value={this.state.after.RunhAE3AC}
                  onChange={(value) => {
                    this.saveField("RunhAE3AC", value);
                  }}
                  error={this.state.validateStatus.RunhAE3AC !== undefined}
                />
              </Grid>

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP1AC"
                    key="RunhPP1AC"
                    fullWidth
                    placeholder="PP1"
                    value={this.state.after.RunhPP1AC}
                    onChange={(value) => {
                      this.saveField("RunhPP1AC", value);
                    }}
                    error={this.state.validateStatus.RunhPP1AC !== undefined}
                  />
                </Grid>
              ) : null}

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP2AC"
                    key="RunhPP2AC"
                    fullWidth
                    placeholder="PP2"
                    value={this.state.after.RunhPP2AC}
                    onChange={(value) => {
                      this.saveField("RunhPP2AC", value);
                    }}
                    error={this.state.validateStatus.RunhPP2AC !== undefined}
                  />
                </Grid>
              ) : null}

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhIGAC"
                    key="RunhIGAC"
                    fullWidth
                    placeholder="IGG"
                    readOnly={!this.state.FMEnable.IG}
                    value={this.state.after.RunhIGAC}
                    onChange={(value) => {
                      this.saveField("RunhIGAC", value);
                    }}
                    error={this.state.validateStatus.RunhIGAC !== undefined}
                  />
                </Grid>
              ) : null}
              {this.state.FMEnable.RunhBLR ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhBLRAC"
                    key="RunhBLRAC"
                    fullWidth
                    placeholder="BLR"
                    readOnly={!this.state.FMEnable.RunhBLR}
                    value={this.state.after.RunhBLRAC}
                    onChange={(value) => {
                      this.saveField("RunhBLRAC", value);
                    }}
                    error={this.state.validateStatus.RunhBLRAC !== undefined}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhMEAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhMEAA"
                  key="RunhMEAA"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.RunhMEAA}
                  onChange={(value) => {
                    this.saveField("RunhMEAA", value);
                  }}
                  error={this.state.validateStatus.RunhMEAA !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE1AA"
                  key="RunhAE1AA"
                  fullWidth
                  placeholder="AE1"
                  value={this.state.after.RunhAE1AA}
                  onChange={(value) => {
                    this.saveField("RunhAE1AA", value);
                  }}
                  error={this.state.validateStatus.RunhAE1AA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE2AA"
                  key="RunhAE2AA"
                  fullWidth
                  placeholder="AE2"
                  value={this.state.after.RunhAE2AA}
                  onChange={(value) => {
                    this.saveField("RunhAE2AA", value);
                  }}
                  error={this.state.validateStatus.RunhAE2AA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE3AA"
                  key="RunhAE3AA"
                  fullWidth
                  placeholder="AE3"
                  value={this.state.after.RunhAE3AA}
                  onChange={(value) => {
                    this.saveField("RunhAE3AA", value);
                  }}
                  error={this.state.validateStatus.RunhAE3AA !== undefined}
                />
              </Grid>

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP1AA"
                    key="RunhPP1AA"
                    fullWidth
                    placeholder="PP1"
                    value={this.state.after.RunhPP1AA}
                    onChange={(value) => {
                      this.saveField("RunhPP1AA", value);
                    }}
                    error={this.state.validateStatus.RunhPP1AA !== undefined}
                  />
                </Grid>
              ) : null}

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP2AA"
                    key="RunhPP2AA"
                    fullWidth
                    placeholder="PP2"
                    value={this.state.after.RunhPP2AA}
                    onChange={(value) => {
                      this.saveField("RunhPP2AA", value);
                    }}
                    error={this.state.validateStatus.RunhPP2AA !== undefined}
                  />
                </Grid>
              ) : null}

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhIGAA"
                    key="RunhIGAA"
                    fullWidth
                    placeholder="IGG"
                    readOnly={!this.state.FMEnable.IG}
                    value={this.state.after.RunhIGAA}
                    onChange={(value) => {
                      this.saveField("RunhIGAA", value);
                    }}
                    error={this.state.validateStatus.RunhIGAA !== undefined}
                  />
                </Grid>
              ) : null}
              {this.state.FMEnable.RunhBLR ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhBLRAA"
                    key="RunhBLRAA"
                    fullWidth
                    placeholder="BLR"
                    readOnly={!this.state.FMEnable.RunhBLR}
                    value={this.state.after.RunhBLRAA}
                    onChange={(value) => {
                      this.saveField("RunhBLRAA", value);
                    }}
                    error={this.state.validateStatus.RunhBLRAA !== undefined}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  ENGINE
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RpmMEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmMEAC"
                  key="RpmMEAC"
                  fullWidth
                  value={this.state.after.RpmMEAC}
                  onChange={(value) => {
                    this.saveField("RpmMEAC", value);
                  }}
                  error={this.state.validateStatus.RpmMEAC !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.BCAAC}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="BCAAC"
                  key="BCAAC"
                  fullWidth
                  value={this.state.after.BCAAC}
                  onChange={(value) => {
                    this.saveField("BCAAC", value);
                  }}
                  error={this.state.validateStatus.BCAAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.LoadAC}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="LoadAC"
                  key="LoadAC"
                  fullWidth
                  value={this.state.after.LoadAC}
                  onChange={(value) => {
                    this.saveField("LoadAC", value);
                  }}
                  error={this.state.validateStatus.LoadAC !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.TmaxAC}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="TmaxAC"
                  key="TmaxAC"
                  fullWidth
                  value={this.state.after.TmaxAC}
                  onChange={(value) => {
                    this.saveField("TmaxAC", value);
                  }}
                  error={this.state.validateStatus.TmaxAC !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.TminAC}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="TminAC"
                  key="TminAC"
                  fullWidth
                  value={this.state.after.TminAC}
                  onChange={(value) => {
                    this.saveField("TminAC", value);
                  }}
                  error={this.state.validateStatus.TminAC !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.PaMEAC}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="PaMEAC"
                  key="PaMEAC"
                  fullWidth
                  value={this.state.after.PaMEAC}
                  onChange={(value) => {
                    this.saveField("PaMEAC", value);
                  }}
                  error={this.state.validateStatus.PaMEAC !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.TaAC}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="TaAC"
                  key="TaAC"
                  fullWidth
                  value={this.state.after.TaAC}
                  onChange={(value) => {
                    this.saveField("TaAC", value);
                  }}
                  error={this.state.validateStatus.TaAC !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.AirMEAC}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="AirMEAC"
                  key="AirMEAC"
                  fullWidth
                  value={this.state.after.AirMEAC}
                  onChange={(value) => {
                    this.saveField("AirMEAC", value);
                  }}
                  error={this.state.validateStatus.AirMEAC !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.TerAC}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="TerAC"
                  key="TerAC"
                  fullWidth
                  value={this.state.after.TerAC}
                  onChange={(value) => {
                    this.saveField("TerAC", value);
                  }}
                  error={this.state.validateStatus.TerAC !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.SlipAC}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="SlipAC"
                  key="SlipAC"
                  fullWidth
                  value={this.state.after.SlipAC}
                  onChange={(value) => {
                    this.saveField("SlipAC", value);
                  }}
                  error={this.state.validateStatus.SlipAC !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  CONS
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOMEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOMEAC"
                  key="ConsFOMEAC"
                  fullWidth
                  placeholder="ME/FO"
                  value={this.state.after.ConsFOMEAC}
                  onChange={(value) => {
                    this.saveField("ConsFOMEAC", value);
                  }}
                  error={this.state.validateStatus.ConsFOMEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOMEAC"
                  key="ConsDOMEAC"
                  fullWidth
                  placeholder="ME/DO"
                  value={this.state.after.ConsDOMEAC}
                  onChange={(value) => {
                    this.saveField("ConsDOMEAC", value);
                  }}
                  error={this.state.validateStatus.ConsDOMEAC !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAEAC"
                  key="ConsFOAEAC"
                  fullWidth
                  placeholder="AE/FO"
                  value={this.state.after.ConsFOAEAC}
                  onChange={(value) => {
                    this.saveField("ConsFOAEAC", value);
                  }}
                  error={this.state.validateStatus.ConsFOAEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAEAC"
                  key="ConsDOAEAC"
                  fullWidth
                  placeholder="AE/DO"
                  value={this.state.after.ConsDOAEAC}
                  onChange={(value) => {
                    this.saveField("ConsDOAEAC", value);
                  }}
                  error={this.state.validateStatus.ConsDOAEAC !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRAC"
                  key="ConsFOBLRAC"
                  fullWidth
                  placeholder="BLR/FO"
                  value={this.state.after.ConsFOBLRAC}
                  onChange={(value) => {
                    this.saveField("ConsFOBLRAC", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRAC"
                  key="ConsDOBLRAC"
                  fullWidth
                  placeholder="BLR/DO"
                  value={this.state.after.ConsDOBLRAC}
                  onChange={(value) => {
                    this.saveField("ConsDOBLRAC", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRAC !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                {this.state.FMEnable.IG ? (
                  <MyNumberEditor
                    id="ConsDOPPAC"
                    key="ConsDOPPAC"
                    fullWidth
                    placeholder="PP/DO"
                    value={this.state.after.ConsDOPPAC}
                    onChange={(value) => {
                      this.saveField("ConsDOPPAC", value);
                    }}
                    error={this.state.validateStatus.ConsDOPPAC !== undefined}
                  />
                ) : null}
              </Grid>
              <Grid item xs={2}>
                {this.state.FMEnable.IG ? (
                  <MyNumberEditor
                    id="ConsDOIGAC"
                    key="ConsDOIGAC"
                    fullWidth
                    placeholder="IGG/DO"
                    value={this.state.after.ConsDOIGAC}
                    onChange={(value) => {
                      this.saveField("ConsDOIGAC", value);
                    }}
                    error={this.state.validateStatus.ConsDOIGAC !== undefined}
                  />
                ) : null}
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsCylAC}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsCylAC"
                  key="ConsCylAC"
                  fullWidth
                  placeholder="Cyl"
                  decimalScale={0}
                  value={this.state.after.ConsCylAC}
                  onChange={(value) => {
                    this.saveField("ConsCylAC", value);
                  }}
                  error={this.state.validateStatus.ConsCylAC !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsSysAC"
                  key="ConsSysAC"
                  fullWidth
                  placeholder="Sys"
                  decimalScale={0}
                  value={this.state.after.ConsSysAC}
                  onChange={(value) => {
                    this.saveField("ConsSysAC", value);
                  }}
                  error={this.state.validateStatus.ConsSysAC !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsAEAC"
                  key="ConsAEAC"
                  fullWidth
                  placeholder="AE"
                  decimalScale={0}
                  value={this.state.after.ConsAEAC}
                  onChange={(value) => {
                    this.saveField("ConsAEAC", value);
                  }}
                  error={this.state.validateStatus.ConsAEAC !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsHydAC"
                  key="ConsHydAC"
                  fullWidth
                  placeholder="Hyd"
                  decimalScale={0}
                  value={this.state.after.ConsHydAC}
                  onChange={(value) => {
                    this.saveField("ConsHydAC", value);
                  }}
                  error={this.state.validateStatus.ConsHydAC !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOMEAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOMEAA"
                  key="ConsFOMEAA"
                  fullWidth
                  placeholder="ME/FO"
                  value={this.state.after.ConsFOMEAA}
                  onChange={(value) => {
                    this.saveField("ConsFOMEAA", value);
                  }}
                  error={this.state.validateStatus.ConsFOMEAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOMEAA"
                  key="ConsDOMEAA"
                  fullWidth
                  placeholder="ME/DO"
                  value={this.state.after.ConsDOMEAA}
                  onChange={(value) => {
                    this.saveField("ConsDOMEAA", value);
                  }}
                  error={this.state.validateStatus.ConsDOMEAA !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAEAA"
                  key="ConsFOAEAA"
                  fullWidth
                  placeholder="AE/FO"
                  value={this.state.after.ConsFOAEAA}
                  onChange={(value) => {
                    this.saveField("ConsFOAEAA", value);
                  }}
                  error={this.state.validateStatus.ConsFOAEAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAEAA"
                  key="ConsDOAEAA"
                  fullWidth
                  placeholder="AE/DO"
                  value={this.state.after.ConsDOAEAA}
                  onChange={(value) => {
                    this.saveField("ConsDOAEAA", value);
                  }}
                  error={this.state.validateStatus.ConsDOAEAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRAA"
                  key="ConsFOBLRAA"
                  fullWidth
                  placeholder="BLR/FO"
                  value={this.state.after.ConsFOBLRAA}
                  onChange={(value) => {
                    this.saveField("ConsFOBLRAA", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRAA"
                  key="ConsDOBLRAA"
                  fullWidth
                  placeholder="BLR/DO"
                  value={this.state.after.ConsDOBLRAA}
                  onChange={(value) => {
                    this.saveField("ConsDOBLRAA", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                {this.state.FMEnable.IG ? (
                  <MyNumberEditor
                    id="ConsDOPPAA"
                    key="ConsDOPPAA"
                    fullWidth
                    placeholder="PP/DO"
                    value={this.state.after.ConsDOPPAA}
                    onChange={(value) => {
                      this.saveField("ConsDOPPAA", value);
                    }}
                    error={this.state.validateStatus.ConsDOPPAA !== undefined}
                  />
                ) : null}
              </Grid>
              <Grid item xs={2}>
                {this.state.FMEnable.IG ? (
                  <MyNumberEditor
                    id="ConsDOIGAA"
                    key="ConsDOIGAA"
                    fullWidth
                    placeholder="IGG/DO"
                    value={this.state.after.ConsDOIGAA}
                    onChange={(value) => {
                      this.saveField("ConsDOIGAA", value);
                    }}
                    error={this.state.validateStatus.ConsDOIGAA !== undefined}
                  />
                ) : null}
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsCylAA}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsCylAA"
                  key="ConsCylAA"
                  fullWidth
                  placeholder="Cyl"
                  decimalScale={0}
                  value={this.state.after.ConsCylAA}
                  onChange={(value) => {
                    this.saveField("ConsCylAA", value);
                  }}
                  error={this.state.validateStatus.ConsCylAA !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsSysAA"
                  key="ConsSysAA"
                  fullWidth
                  placeholder="Sys"
                  decimalScale={0}
                  value={this.state.after.ConsSysAA}
                  onChange={(value) => {
                    this.saveField("ConsSysAA", value);
                  }}
                  error={this.state.validateStatus.ConsSysAA !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsAEAA"
                  key="ConsAEAA"
                  fullWidth
                  placeholder="AE"
                  decimalScale={0}
                  value={this.state.after.ConsAEAA}
                  onChange={(value) => {
                    this.saveField("ConsAEAA", value);
                  }}
                  error={this.state.validateStatus.ConsAEAA !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsHydAA"
                  key="ConsHydAA"
                  fullWidth
                  placeholder="Hyd"
                  decimalScale={0}
                  value={this.state.after.ConsHydAA}
                  onChange={(value) => {
                    this.saveField("ConsHydAA", value);
                  }}
                  error={this.state.validateStatus.ConsHydAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  R.O.B
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFOAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFOAC"
                  key="RobFOAC"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.RobFOAC}
                  onChange={(value) => {
                    this.saveField("RobFOAC", value);
                  }}
                  error={this.state.validateStatus.RobFOAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDOAC"
                  key="RobDOAC"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.RobDOAC}
                  onChange={(value) => {
                    this.saveField("RobDOAC", value);
                  }}
                  error={this.state.validateStatus.RobDOAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobSumpAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSumpAC"
                  key="RobSumpAC"
                  fullWidth
                  placeholder="Sump"
                  decimalScale={0}
                  value={this.state.after.RobSumpAC}
                  onChange={(value) => {
                    this.saveField("RobSumpAC", value);
                  }}
                  error={this.state.validateStatus.RobSumpAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSysAC"
                  key="RobSysAC"
                  fullWidth
                  placeholder="Sys"
                  decimalScale={0}
                  value={this.state.after.RobSysAC}
                  onChange={(value) => {
                    this.saveField("RobSysAC", value);
                  }}
                  error={this.state.validateStatus.RobSysAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobCylAC"
                  key="RobCylAC"
                  fullWidth
                  placeholder="Cyl"
                  decimalScale={0}
                  value={this.state.after.RobCylAC}
                  onChange={(value) => {
                    this.saveField("RobCylAC", value);
                  }}
                  error={this.state.validateStatus.RobCylAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobAEAC"
                  key="RobAEAC"
                  fullWidth
                  placeholder="AE"
                  decimalScale={0}
                  value={this.state.after.RobAEAC}
                  onChange={(value) => {
                    this.saveField("RobAEAC", value);
                  }}
                  error={this.state.validateStatus.RobAEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobHydAC"
                  key="RobHydAC"
                  fullWidth
                  placeholder="Hyd"
                  decimalScale={0}
                  value={this.state.after.RobHydAC}
                  onChange={(value) => {
                    this.saveField("RobHydAC", value);
                  }}
                  error={this.state.validateStatus.RobHydAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFWAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWAC"
                  key="RobFWAC"
                  fullWidth
                  decimalScale={0}
                  value={this.state.after.RobFWAC}
                  onChange={(value) => {
                    this.saveField("RobFWAC", value);
                  }}
                  error={this.state.validateStatus.RobFWAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFOAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFOAA"
                  key="RobFOAA"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.RobFOAA}
                  onChange={(value) => {
                    this.saveField("RobFOAA", value);
                  }}
                  error={this.state.validateStatus.RobFOAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDOAA"
                  key="RobDOAA"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.RobDOAA}
                  onChange={(value) => {
                    this.saveField("RobDOAA", value);
                  }}
                  error={this.state.validateStatus.RobDOAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobSumpAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSumpAA"
                  key="RobSumpAA"
                  fullWidth
                  placeholder="Sump"
                  decimalScale={0}
                  value={this.state.after.RobSumpAA}
                  onChange={(value) => {
                    this.saveField("RobSumpAA", value);
                  }}
                  error={this.state.validateStatus.RobSumpAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSysAA"
                  key="RobSysAA"
                  fullWidth
                  placeholder="Sys"
                  decimalScale={0}
                  value={this.state.after.RobSysAA}
                  onChange={(value) => {
                    this.saveField("RobSysAA", value);
                  }}
                  error={this.state.validateStatus.RobSysAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobCylAA"
                  key="RobCylAA"
                  fullWidth
                  placeholder="Cyl"
                  decimalScale={0}
                  value={this.state.after.RobCylAA}
                  onChange={(value) => {
                    this.saveField("RobCylAA", value);
                  }}
                  error={this.state.validateStatus.RobCylAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobAEAA"
                  key="RobAEAA"
                  fullWidth
                  placeholder="AE"
                  decimalScale={0}
                  value={this.state.after.RobAEAA}
                  onChange={(value) => {
                    this.saveField("RobAEAA", value);
                  }}
                  error={this.state.validateStatus.RobAEAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobHydAA"
                  key="RobHydAA"
                  fullWidth
                  placeholder="Hyd"
                  decimalScale={0}
                  value={this.state.after.RobHydAA}
                  onChange={(value) => {
                    this.saveField("RobHydAA", value);
                  }}
                  error={this.state.validateStatus.RobHydAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFWAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWAA"
                  key="RobFWAA"
                  fullWidth
                  decimalScale={0}
                  value={this.state.after.RobFWAA}
                  onChange={(value) => {
                    this.saveField("RobFWAA", value);
                  }}
                  error={this.state.validateStatus.RobFWAA !== undefined}
                />
              </Grid>
            </Grid>

            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  OTHERS
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FWPureAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FWPureAC"
                  key="FWPureAC"
                  fullWidth
                  decimalScale={1}
                  value={this.state.after.FWPureAC}
                  onChange={(value) => {
                    this.saveField("FWPureAC", value);
                  }}
                  error={this.state.validateStatus.FWPureAC !== undefined}
                />
              </Grid>
              <Grid item xs={20}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.SeaMapLog}</FormLabel>
              </Grid>
              <Grid item xs={10}>
                <OutlinedInput
                  id="SeaMapLog"
                  key="SeaMapLog"
                  fullWidth
                  multiline
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.SeaMapLog}
                  onChange={(event) => {
                    this.saveField("SeaMapLog", event.target.value);
                  }}
                  error={this.state.validateStatus.SeaMapLog !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Remark}</FormLabel>
              </Grid>
              <Grid item xs={10}>
                <OutlinedInput
                  id="Remark"
                  key="Remark"
                  fullWidth
                  multiline
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.Remark}
                  onChange={(event) => {
                    this.saveField("Remark", event.target.value);
                  }}
                  error={this.state.validateStatus.Remark !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Bunker}</FormLabel>
              </Grid>
              <Grid item xs={10}>
                <OutlinedInput
                  id="Attachs"
                  key="Attachs"
                  fullWidth
                  value={this.getAttachs()}
                  onChange={(event) => {
                    this.state.Attachs = [];
                    this.setState({ dataChanged: true });
                  }}
                  placeholder=""
                  error={this.state.validateStatus.BunkerFileName !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <Dropzone
                  maxFiles={5}
                  minSize={1}
                  maxSize={500 * 1024}
                  multiple={true}
                  validator={(file) => {
                    if (file.size > 0 && file.size < 500 * 1024) {
                      return null;
                    }
                    alert("File too large");
                    return true;
                  }}
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      this.state.Attachs = [];
                      acceptedFiles.map((file) => {
                        this.state.Attachs.push({
                          FileID: uuid(),
                          FileName: file.name,
                          File: file,
                        });
                      });
                      this.setState({ dataChanged: true });
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button fullWidth variant="contained">
                          Upload
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{"(Max size: 500KB)"}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Captain}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Captain"
                  key="Captain"
                  fullWidth
                  placeholder=""
                  value={this.state.after.Captain}
                  onChange={(event) => {
                    this.saveField("Captain", event.target.value);
                  }}
                  error={this.state.validateStatus.Captain !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  ArrivalReportEditDialog
);
