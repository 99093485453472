import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  Stack,
  Grid,
  Paper,
  InputLabel,
  FormLabel,
  Tabs,
  Tab,
  useRadioGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  OutlinedInput,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import SyncIcon from "@mui/icons-material/Sync";
import { AttachFile } from "@mui/icons-material";
import localStorage from "local-storage";
import moment from "moment";
import SelectFilter from "react-select";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";

import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import PagingSearchCodeDialog from "../tools/PagingSearchCodeDialog";
import SearchFormColumnsDialog from "../tools/SearchFormColumnsDialog";
import GenerateCodeDialog from "../tools/GenerateCodeDialog";
import ButtonTimKiem from "../components/ButtonTimKiem";
import DataEditDialog from "../templates/DataEditDialog";
import MenuButtons from "../components/MenuButtons";
import MySelectFilter from "../components/MySelectFilter";

import DepartureReportEditDialog from "./DepartureReportEditDialog";
import CEDepartureReportEditDialog from "./CEDepartureReportEditDialog";
import NoonAtSeaReportEditDialog from "./NoonAtSeaReportEditDialog";
import CENoonAtSeaReportEditDialog from "./CENoonAtSeaReportEditDialog";
import ArrivalReportEditDialog from "./ArrivalReportEditDialog";
import CEArrivalReportEditDialog from "./CEArrivalReportEditDialog";
import AllfastReportEditDialog from "./AllfastReportEditDialog";
import CEAllfastReportEditDialog from "./CEAllfastReportEditDialog";
import NoonAtPortReportEditDialog from "./NoonAtPortReportEditDialog";
import CENoonAtPortReportEditDialog from "./CENoonAtPortReportEditDialog";
import ShiftingReportEditDialog from "./ShiftingReportEditDialog";
import ShiftingStartReportEditDialog from "./ShiftingStartReportEditDialog";
import ShiftingNoonReportEditDialog from "./ShiftingNoonReportEditDialog";
import ShiftingFinishReportEditDialog from "./ShiftingFinishReportEditDialog";
import CEShiftingReportEditDialog from "./CEShiftingReportEditDialog";
import BunkerReportEditDialog from "./BunkerReportEditDialog";
import CargoReportEditDialog from "./CargoReportEditDialog";
import SimpleDialog from "../components/SimpleDialog";
import GenerateCodeReactDialog from "../tools/GenerateCodeReactDialog";

import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import WaterIcon from "@mui/icons-material/Water";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import RvHookupIcon from "@mui/icons-material/RvHookup";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import LocalConvenienceStoreIcon from "@mui/icons-material/LocalConvenienceStore";
import uuid from "react-uuid";
import EditRoadIcon from "@mui/icons-material/EditRoad";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

import MenuVoyage from "../components/MenuVoyage";
import AddVoyageDialog from "./AddVoyageDialog";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/

const fieldLabels = englishUI
  ? {
      VoyageCode: "Voyage number:",
      ReportTypeID: "Report type:",
      ReportTime: "Report time:",
    }
  : {
      VoyageCode: "Số chuyến đi:",
      ReportTypeID: "Loại báo cáo:",
      ReportTime: "Thời gian:",
    };
const buttonReports = [
  {
    ID: "DR",
    Label: "Departure Report",
    LabelEN: "Departure Report",
    Icon: <PlayCircleFilledIcon fontSize="small" />,
  },
  {
    ID: "NS",
    Label: "Noon At Sea Report",
    LabelEN: "Noon At Sea Report",
    Icon: <WaterIcon fontSize="small" />,
  },
  {
    ID: "AR",
    Label: "Arrival Report",
    LabelEN: "Arrival Report",
    Icon: <AssistantDirectionIcon fontSize="small" />,
  },
  {
    ID: "AF",
    Label: "All Fast Report",
    LabelEN: "All Fast Report",
    Icon: <PrecisionManufacturingIcon fontSize="small" />,
  },
  {
    ID: "NP",
    Label: "Noon In Port Report",
    LabelEN: "Noon In Port Report",
    Icon: <RvHookupIcon fontSize="small" />,
  },
  {
    ID: "SR",
    Label: "Shifting Report",
    LabelEN: "Shifting Report",
    Icon: <MultipleStopIcon fontSize="small" />,
  },
  {
    ID: "SS",
    Label: "Shifting - Start Report",
    LabelEN: "Shifting - Start Report",
    Icon: <EditRoadIcon fontSize="small" />,
  },
  {
    ID: "SN",
    Label: "Shifting - Noon Report",
    LabelEN: "Shifting - Noon Report",
    Icon: <WbSunnyIcon fontSize="small" />,
  },
  {
    ID: "FS",
    Label: "Shifting - Finish Report",
    LabelEN: "Shifting - Finish Report",
    Icon: <EventAvailableIcon fontSize="small" />,
  },
  {
    ID: "CR",
    Label: "Cargo Report",
    LabelEN: "Cargo Report",
    Icon: <LocalConvenienceStoreIcon fontSize="small" />,
  },
];
const buttonLabels = englishUI
  ? {
      btnTimKiem: "Search",
      btnThem: "Add",
      btnEdit: "Edit",
      btnDelete: "Delete",
      btnReplace: "Replace",
      btnSend: "Send",
      btnMark: "Cancel",
      btnMarkSend: "Done",
      btnEmail: "Email",
    }
  : {
      btnTimKiem: "Tìm kiếm",
      btnThem: "Thêm",
      btnEdit: "Sửa",
      btnDelete: "Xóa",
      btnReplace: "Thay thế",
      btnSend: "Gửi",
      btnMark: "Cancel",
      btnEmail: "Email",
    };
/*-----------------------Dinh nghia cac column trong grid -----------------*/
const default_gridColumns = [
  {
    name: "ReportTypeName",
    type: "string",
    headerAlign: "center",
    defaultWidth: 150,
    header: englishUI ? "Report Type" : "Loại báo cáo",
    render: ({ data }) =>
      data.ReportStatus < 3 ? (
        <FormLabel style={{ color: "#fc6603" }}>
          {data.ReportTypeName}
        </FormLabel>
      ) : data.ReportStatus < 4 ? (
        <FormLabel style={{ color: "#0000ff" }}>
          {data.ReportTypeName}
        </FormLabel>
      ) : data.ReportStatus < 5 ? (
        <FormLabel style={{ color: "#990033", textDecoration: "line-through" }}>
          {data.ReportTypeName}
        </FormLabel>
      ) : (
        <FormLabel style={{ color: "#0000ff", textDecoration: "line-through" }}>
          {data.ReportTypeName}
        </FormLabel>
      ),
  },
  {
    name: "ReportDataTime",
    type: "date",
    headerAlign: "center",
    defaultWidth: 120,
    header: englishUI ? "Voy. Time" : "Thời điểm",
    render: (props) =>
      props.data.ReportTime
        ? moment(props.data.ReportDataTime).format("HH:mm DD/MM/YYYY")
        : null,
  },
  {
    name: "ReportTime",
    type: "date",
    headerAlign: "center",
    defaultWidth: 120,
    header: englishUI ? "Report time" : "Thời điểm lập báo cáo",
    render: (props) =>
      props.data.ReportTime
        ? moment(props.data.ReportTime).format("HH:mm DD/MM/YYYY")
        : null,
  },

  {
    name: "VoyageCode",
    type: "string",
    headerAlign: "center",
    defaultWidth: 150,
    header: englishUI ? "Voyage Code" : "Số chuyến đi",
    render: undefined,
  },
  {
    name: "ReportStatusName",
    type: "string",
    headerAlign: "center",
    defaultWidth: 120,
    header: englishUI ? "Status" : "Trạng thái",
    render: undefined,
  },

  {
    name: "DailyReportID",
    type: "number",
    headerAlign: "center",
    defaultWidth: 100,
    header: englishUI ? "ID" : "ID",
    render: ({ data }) =>
      data.ParentReportID
        ? data.DailyReportID + "/" + data.ParentReportID
        : data.DailyReportID,
  },
  {
    name: "NgayCN",
    type: "date",
    headerAlign: "center",
    defaultWidth: 150,
    format: "HH:mm DD/MM/YYYY",
    header: englishUI ? "Updated time" : "Ngày CN",
    render: (props) =>
      props.data.NgayCN
        ? moment(props.data.NgayCN).format("HH:mm DD/MM/YYYY")
        : null,
  },
];
/*-----------------------Cac bien su dung trong form ----------------------*/
const defaultSortInfo = { name: "ReportDataTime", dir: 0, type: "date" };
//tra ve index column tuy theo sort infor
const getSortColumnIndex = (columns, sortInfor) => {
  var find_index = 0;
  for (var i = 0; i < columns.length; i++) {
    var item = columns[i];
    if (item.name == sortInfor.name) {
      find_index = i;
      break;
    }
  }
  return find_index;
};

//ham filter su dung cho combobox
const filterDanhSach = (danhsach, fieldName, inputValue, callback) => {
  inputValue = inputValue ? inputValue.toUpperCase() : "";
  var i = 0;
  var count = 0;
  var list = [];
  for (; i < danhsach.length && count < 10; i++) {
    var item = danhsach[i];
    if (
      inputValue === "" ||
      item[fieldName].toUpperCase().indexOf(inputValue) >= 0
    ) {
      list.push(item);
      count++;
    }
  }
  callback(list);
};

class TempReportPage extends React.Component {
  selectionCounter = 0; //dem so lan click chon row trong grid
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);
    this.columns_grid = default_gridColumns;
    let default_voyage2 = localStorage.get(
      "TempReportPage.VoyageCode" + props.ShipCode
    );
    let default_voyage = localStorage.get("TempReportPage.VoyageCode");
    if (!myLib.isEmpty(default_voyage2)) {
      default_voyage = default_voyage2;
    }
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      DanhMuc: { DM_Voyage: [], DM_ReportType: [] }, //luu cac du lieu danh muc su dung trong form
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
      sortInfo: defaultSortInfo, //thong tin sap xep cua grid
      //chua cac tieu chi tim kiem
      after:
        props.value === undefined //gia tri truyen vao tu form cha
          ? {
              PageIndex: 1,
              PageSize: 20,
              Ascending: true,
              SortBy: getSortColumnIndex(this.columns_grid, defaultSortInfo), //index column duoc sap xep, anh xa giua ten cot duoc chon va index cua colmn trong grid
              //danh sach cac tieu chi tim kiem
              VoyageCode: default_voyage,
              ReportTime1: null,
              ReportTime2: null,
              ReportTypeID: null, //danh sach loai report
              IsEngine: 0,
            }
          : props.value, //thong tin tieu chi truyen vao tu form cha

      //ban ghi duoc lua chon trong grid
      rowSelected: undefined, //record trong danh sach chuyen duoc chon
      showConfirmXoa: false,
      showPreview: true, // co hien thi preview khong
      showLog: false, //hien thi loi hay khong
      LogContent: "",
      logHasError: false, //log co loi hay khong
      Attachs: [], //danh sach file dinh kem neu co
    };
  }
  //ghi nhan 1 tieu chi tim kiem
  saveField = (fieldName, value) => {
    var s = this.state;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy"
    ) {
      this.setMyState(this.state);
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => {
        this.doSearch();
      });
    }
  };
  //ghi nhan nhieu gia tri tieu chi cung 1 luc
  saveData = (obj) => {
    var s = this.state;
    s.after = { ...s.after, ...obj };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (obj.Ascending || obj.SortBy || obj.VoyageCode || obj.ReportTypeID) {
      this.setMyState(s, () => this.doSearch());
    } else {
      this.setMyState(s);
    }
  };
  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  //su kien form load
  componentDidMount() {
    this.loadDanhMuc("DM_Voyage,DM_ReportType", () => this.doSearch());
    this.timer = setInterval(() => {
      this.loadLogs();
    }, 10000);
  }
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(props, state) {}
  //load du lieu danh muc theo danh sach table
  loadDanhMuc(danhsach, nextFunc) {
    //load danh muc
    if (danhsach) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: danhsach,
          },
        })
        .then((response) => {
          this.setState(
            {
              DanhMuc: {
                ...this.state.DanhMuc,
                ...response,
              },
            },
            () => nextFunc()
          );
        })
        .catch((error) => {
          showError(error);
          nextFunc();
        });
    }
  }
  //khi thay doi sap xep cot
  onSortInfoChange = (value) => {
    const newSort = value
      ? { type: value.type, name: value.name, dir: value.dir }
      : value;
    //ghi nhan vao sort by
    this.setState(
      {
        lastSortInfo: this.state.sortInfo
          ? this.state.sortInfo
          : this.state.lastSortInfo,
        sortInfo: newSort,
      },
      () => this.doSearch()
    );
  };
  loadData = ({ skip, limit, sortInfo }) => {
    var serverSortInfo = this.state.sortInfo
      ? this.state.sortInfo
      : this.state.lastSortInfo;

    var loader = new Promise((resolve, eject) => {
      server
        .post("Data/DoRequest", {
          Function: "Proc_OS_DailyReport_Search",
          ThamSo: {
            ...this.state.after,
            PageIndex: skip / limit + 1,
            PageSize: limit,
            SortBy: getSortColumnIndex(this.columns_grid, serverSortInfo),
            Ascending: serverSortInfo.dir == 1,
          },
        })
        .then((response) => {
          //ghi nho de lan sau
          localStorage.set(
            "TempReportPage.VoyageCode",
            this.state.after.VoyageCode
          );
          localStorage.set(
            "TempReportPage.VoyageCode" + this.props.ShipCode,
            this.state.after.VoyageCode
          );
          this.setState({
            search_loading: false,
            search_error: false,
            search_result: {
              data: response.DataList,
              count: response.DataCount[0].Total,
            },
            rowSelected: undefined,
            rowDoubleClicked: undefined,
            ReportContent: "",
            Attachs: [],
          });
          resolve({
            data: response.DataList,
            count: response.DataCount[0].Total,
          });
        })
        .catch((error) => {
          var message = "";
          var log = "";
          try {
            var obj = JSON.parse(error);
            message = obj.message;
            log = obj.traceLog;
          } catch (e) {
            message = error + "";
          }
          this.setState({
            search_loading: false,
            search_error: true,
            errorMessage: message,
            errorLog: log,
            search_result: { data: [], count: 0 },
            rowSelected: undefined,
          });
          resolve({ data: [], count: 0 });
        });
    });
    return loader;
  };
  //Thuc hien search
  doSearch = () => {
    var loader = this.loadData({
      skip: (this.state.after.PageIndex - 1) * this.state.after.PageSize,
      limit: this.state.after.PageSize,
      sortInfo: this.state.sortInfo,
    });
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      selectdReecord: {},
    });
  };
  loadPreview() {
    //load de hien thi preview report
    server
      .post("Data/DoRequest", {
        Function: "Proc_DailyReport_Select_Preview",
        ThamSo: {
          ReportID: this.state.rowSelected.ReportID,
        },
      })
      .then((response) => {
        this.setState({
          ReportContent: response.ReportContents[0].PreviewText,
          Attachs: response.Attachs,
        });
      })
      .catch((error) => {
        showError(error);
      });
  }

  edit() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_GetOne",
        ThamSo: {
          DailyReportID: this.state.rowSelected.DailyReportID,
        },
      })
      .then((response) => {
        let reportType =
          response.OS_DailyReport.length > 0
            ? response.OS_DailyReport[0].ReportTypeID
            : 0;
        let data = {
          edittingRecord: {
            ...JSON.parse(response.OS_DailyReport[0].ReportContent),
            DailyReportID: this.state.rowSelected.DailyReportID,
          },
          reportStatus: response.OS_DailyReport[0].ReportStatus,
          open_edit_departure: reportType == 1,
          open_edit_noonatsea: reportType == 2,
          open_edit_arrival: reportType == 3,
          open_edit_allfast: reportType == 4,
          open_edit_noonport: reportType == 5,
          open_edit_shifting: reportType == 6,
          open_edit_cargo: reportType == 8,
          open_edit_shifting_start: reportType == 9,
          open_edit_shifting_noon: reportType == 10,
          open_edit_shifting_finished: reportType == 11,
        };
        this.setMyState(data);
      })
      .catch((error) => {
        showError(error);
      });
  }

  replaceReport() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_GetOne",
        ThamSo: {
          DailyReportID: this.state.rowSelected.DailyReportID,
        },
      })
      .then((response) => {
        let reportType = response.OS_DailyReport[0].ReportTypeID;
        let data = {
          edittingRecord: {
            ...JSON.parse(response.OS_DailyReport[0].ReportContent),
            ReportID: uuid(),
            DailyReportID: 0, //tao report diue chinh
            ParentReportID: this.state.rowSelected.DailyReportID, //report cha
          },
          reportStatus: 0,
          open_edit_departure: reportType == 1,
          open_edit_noonatsea: reportType == 2,
          open_edit_arrival: reportType == 3,
          open_edit_allfast: reportType == 4,
          open_edit_noonport: reportType == 5,
          open_edit_shifting: reportType == 6,
          open_edit_cargo: reportType == 8,
          open_edit_shifting_start: reportType == 9,
          open_edit_shifting_noon: reportType == 10,
          open_edit_shifting_finished: reportType == 11,
        };
        this.setMyState(data);
      })
      .catch((error) => {
        showError(error);
      });
  }
  syncVoyageCodes() {
    this.setState({ loading: true });
    server
      .post("Data/DoRequest", {
        Function: "Proc_DongBo_Voyage",
      })
      .then(() => {
        this.setState({ loading: false });
        this.loadDanhMuc("DM_Voyage", () => {});
      })
      .catch((error) => {
        showError(error);
        this.setState({ loading: false });
      });
  }
  //load du lieu log upload

  loadLogs() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_Logs_GetLastest",
        ThamSo: {},
      })
      .then((response) => {
        let list = response.OS_Logs;
        var text = "";
        var isFirst = true;
        var hasError = false;
        list.map((item) => {
          if (isFirst) {
            hasError = item.LogType == "Error";
            isFirst = false;
          }
          text +=
            moment(item.LogTime).format("HH:mm:ss DD/MM/YYYY") +
            " - " +
            item.LogType +
            ": " +
            item.StatusLog +
            "\n";
        });
        //danh sach report moi nhat kem theo trang thai
        let reports = response.OS_DailyReport;
        var changed = false;
        reports.map((rpt) => {
          var find = this.state.search_result.data.find(
            (item) => item.DailyReportID === rpt.DailyReportID
          );
          if (find && find.ReportStatus !== rpt.ReportStatus) {
            changed = true;
          }
        });
        if (changed) {
          //co su thay doi trang thai
          this.doSearch();
        } else {
          this.setState({
            LogContent: text,
            logHasError: hasError,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        //showError(error);
      });
  }
  
  //call khi user thay doi trang
  render() {
    let isCEVersion = false;
    //lay mot so thuoc tinh truyen vao tu form cha
    const { classes, theme, parentHeight } = this.props;
    return (
      <div>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            <Grid item xs={3}>
              <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
            </Grid>
            <Grid item xs={5}>
              <MySelectFilter
                id={"VoyageCode"}
                key={"VoyageCode"}
                fullWidth
                autoFocus={false}
                options={this.state.DanhMuc.DM_Voyage}
                optionLabel="VoyageCode"
                optionValue="VoyageCode"
                placeholder=""
                value={this.state.after}
                onChange={(item) => {
                  this.saveData(item);
                }}
              ></MySelectFilter>
            </Grid>
            <Grid item xs={1}>
              {this.state.loading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                <MenuVoyage
                  onClick={(item) => {
                    if (item.name == "sync") {
                      this.syncVoyageCodes();
                    }
                    if (item.name == "add") {
                      this.setState({ open_add_voyage: true });
                    }
                    if (item.name == "delete") {
                      this.setState({ open_delete_voyage: true });
                    }
                  }}
                />
              )}
            </Grid>
            <Grid item xs={3}>
              <FormLabel>{fieldLabels.ReportTime}</FormLabel>
            </Grid>
            <Grid item xs={4}>
              <MyDateEditor
                id="ReportTime1"
                key="ReportTime1"
                fullWidth
                value={this.state.after.ReportTime1}
                onChange={(value) => {
                  this.saveField("ReportTime1", value);
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <FormLabel>{">>"}</FormLabel>
            </Grid>
            <Grid item xs={4}>
              <MyDateEditor
                id="ReportTime2"
                key="ReportTime2"
                fullWidth
                value={this.state.after.ReportTime2}
                onChange={(value) => {
                  this.saveField("ReportTime2", value);
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <ButtonTimKiem
                variant="contained"
                title={buttonLabels.btnTimKiem}
                disabled={this.state.search_loading}
                onClick={this.doSearch}
                onRightClickMenu={(item) => {
                  if (item.menuName == "CodeSearch") {
                    this.setState({ open_code_seach: true });
                  }
                  if (item.menuName == "Columns") {
                    this.setState({ open_columns_define: true });
                  }
                  if (item.menuName == "Generate") {
                    this.setState({ open_generate_code: true });
                  }
                  if (item.menuName == "Reload") {
                    //load f5orm
                    this.loadForm(true);
                  }
                  if (item.menuName == "GetCode") {
                    //lay codejs
                    this.generateCodeReactJs();
                  }
                  if (item.menuName == "GenerateJs") {
                    this.setState({ open_generate_react: true });
                  }
                }}
              ></ButtonTimKiem>
            </Grid>
            <Grid item xs={3}>
              <FormLabel>{fieldLabels.ReportTypeID}</FormLabel>
            </Grid>
            <Grid item xs={13}>
              <MySelectFilter
                id={"ReportTypeID"}
                key={"ReportTypeID"}
                fullWidth
                autoFocus={false}
                options={this.state.DanhMuc.DM_ReportType}
                optionLabel="ReportTypeName"
                optionValue="ReportTypeID"
                isMulti={true}
                placeholder=""
                value={this.state.after.ReportTypeID}
                onChange={(item) => {
                  console.log(item);
                  this.saveField("ReportTypeID", item);
                }}
              ></MySelectFilter>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ height: 2 }}></div>
        <Grid container spacing={1} columns={24}>
          <Grid item xs={24}>
            <ReactDataGrid
              style={{
                height:
                  this.state.showPreview || this.state.showLog
                    ? (parentHeight / 3) * 2 - 70 - 35
                    : parentHeight - 58 - 35,
                fontSize: 12,
              }}
              showZebraRows={true}
              i18n={englishUI ? undefined : ReactDataGrid_i18n}
              columns={this.columns_grid}
              pagination={true}
              multiSelect={false}
              showHoverRows={false}
              checkboxColumn={{
                render: (props) => (
                  <input
                    type="checkbox"
                    readOnly
                    checked={props.rowSelected}
                  ></input>
                ),
              }}
              defaultLimit={20}
              limit={this.state.after.PageSize}
              skip={
                (this.state.after.PageIndex - 1) * this.state.after.PageSize
              }
              onSkipChange={(skip) => {
                this.saveField(
                  "PageIndex",
                  skip / this.state.after.PageSize + 1
                );
              }}
              dataSource={this.state.search_dataSource}
              idProperty="DailyReportID"
              headerHeight={30}
              rowHeight={30}
              allowUnsort={false}
              defaultSortInfo={defaultSortInfo}
              sortInfo={this.state.sortInfo}
              onSortInfoChange={this.onSortInfoChange}
              onLimitChange={(v) => this.saveField("PageSize", v)}
              emptyText={loadDataError(
                this.state.search_error,
                this.state.search_result.data.length === 0,
                this.state.errorMessage,
                this.state.errorLog
              )}
              skipLoadOnMount={true}
              enableSelection={true}
              onSelectionChange={({ data, selected, unselected }) => {
                var now = new Date().getTime();
                var diff = now - this.lastClick;
                if (diff > 300) {
                  this.setState({ rowSelected: data, ReportContent: "" }, () =>
                    this.loadPreview()
                  );
                } else {
                  //double click
                  this.setState({
                    rowSelected: data,
                    rowDoubleClicked: data,
                  });
                }
                this.lastClick = now;
              }}
            ></ReactDataGrid>
          </Grid>
          {this.state.showPreview || this.state.showLog ? (
            <>
              <Grid item xs={18}>
                <OutlinedInput
                  id="Preview"
                  key="Preview"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={
                    this.state.showPreview
                      ? this.state.ReportContent
                      : this.state.LogContent
                  }
                  multiline
                  style={{
                    height: "100%",
                  }}
                  inputProps={{
                    style: {
                      height: parentHeight / 3,
                      overflow: "scroll",
                      backgroundColor: this.state.showPreview
                        ? "whitesmoke"
                        : "lightgray",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <List fullWidth>
                  {this.state.Attachs.map((att) => (
                    <ListItemButton
                      onClick={() => {
                        window.open(
                          server.getRequest(
                            "Data/GetFile?fileID=" + att.FileID
                          ),
                          "_blank"
                        );
                      }}
                    >
                      <ListItemText
                        style={{
                          color: "blue",
                        }}
                      >
                        {att.FileName}
                      </ListItemText>
                      <ListItemIcon>
                        <AttachFile color="primary" />
                      </ListItemIcon>
                    </ListItemButton>
                  ))}
                </List>
              </Grid>
            </>
          ) : null}
          <Grid item xs={24}>
            <Grid container spacing={2} columns={24}>
              <Grid item xs={2}>
                <MenuButtons
                  fullWidth
                  englishUI={englishUI}
                  title={buttonLabels.btnThem}
                  SubActions={buttonReports}
                  disabled={myLib.isEmpty(this.state.after.VoyageCode)}
                  onClick={(subButton) => {
                    var data = {
                      edittingRecord: undefined,
                      open_edit_departure: subButton.ID == "DR",
                      open_edit_noonatsea: subButton.ID == "NS",
                      open_edit_arrival: subButton.ID == "AR",
                      open_edit_allfast: subButton.ID == "AF",
                      open_edit_noonport: subButton.ID == "NP",
                      open_edit_shifting: subButton.ID == "SR",
                      open_edit_bunker: subButton.ID == "BR",
                      open_edit_cargo: subButton.ID == "CR",
                      open_edit_shifting_start: subButton.ID == "SS",
                      open_edit_shifting_noon: subButton.ID == "SN",
                      open_edit_shifting_finished: subButton.ID == "FS",
                    };
                    this.setMyState(data);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!this.state.rowSelected}
                  onClick={() => {
                    this.edit();
                  }}
                >
                  {buttonLabels.btnEdit}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={
                    !this.state.rowSelected ||
                    (this.state.rowSelected?.ReportStatus != 0 &&
                      this.state.rowSelected?.ReportStatus != 1)
                  }
                  onClick={() => {
                    this.setState({ showConfirmXoa: true });
                  }}
                >
                  {buttonLabels.btnDelete}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={
                    !this.state.rowSelected ||
                    this.state.rowSelected?.ReportStatus != 3
                  }
                  onClick={() => {
                    this.replaceReport();
                  }}
                >
                  {buttonLabels.btnReplace}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={
                    !this.state.rowSelected ||
                    (this.state.rowSelected?.ReportStatus != 3 &&
                      this.state.rowSelected?.ReportStatus != 5)
                  }
                  onClick={() => {
                    this.setState({
                      show_confirm_mark: true,
                    });
                  }}
                >
                  {buttonLabels.btnMark}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!this.state.rowSelected}
                  onClick={() => {
                    this.setState({
                      show_confirm_markSend: true,
                    });
                  }}
                >
                  {buttonLabels.btnMarkSend}
                </Button>
              </Grid>
              
              <Grid item xs={4}>
                <FormControlLabel
                  label="Show report content"
                  labelPlacement="end"
                  fullWidth
                  control={
                    <Checkbox
                      size="medium"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                      checked={this.state.showPreview}
                      onChange={(event) =>
                        this.setState({
                          showPreview: event.target.checked,
                          showLog: event.target.checked
                            ? false
                            : this.state.showLog,
                        })
                      }
                    />
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  label="Show upload log"
                  labelPlacement="end"
                  fullWidth
                  control={
                    <Checkbox
                      size="medium"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                      checked={this.state.showLog}
                      onChange={(event) =>
                        this.setState({
                          showLog: event.target.checked,
                          showPreview: event.target.checked
                            ? false
                            : this.state.showPreview,
                        })
                      }
                    />
                  }
                />
              </Grid>
              <Grid item xs={1}>
                {this.state.loadingLog ? (
                  <CircularProgress color="primary" size={24} />
                ) : (
                  <Badge
                    badgeContent={this.state.logHasError ? 1 : 0}
                    color="error"
                  >
                    <IconButton
                      aria-label="sync"
                      color="primary"
                      sx={{ padding: 0 }}
                      onClick={() => this.loadLogs()}
                    >
                      <SyncIcon />
                    </IconButton>
                  </Badge>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        {this.state.open_code_seach ? (
          <PagingSearchCodeDialog
            open={true}
            DieuKien={this.state.after}
            gridColumns={this.columns_grid}
            close={(record) => {
              this.setState({
                open_code_seach: false,
              });
            }}
          />
        ) : null}
        {this.state.open_columns_define ? (
          <SearchFormColumnsDialog
            open={true}
            formName={this.constructor.name}
            columns_default={this.columns_grid}
            close={(record) => {
              if (record) {
                this.columns_grid = myLib.applyGridColumns(
                  default_gridColumns,
                  record
                );
              }
              this.setState({
                open_columns_define: false,
              });
            }}
          />
        ) : null}
        {this.state.open_generate_code ? (
          <GenerateCodeDialog
            open={true}
            close={(record) => {
              this.setState({
                open_generate_code: false,
              });
            }}
          />
        ) : null}
        {this.state.action_show_form ? (
          <DataEditDialog
            open={true}
            FormName={this.state.action_FormName}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({
                action_show_form: false,
                action_FormName: undefined,
              });
            }}
          />
        ) : null}
        {this.state.open_generate_code ? (
          <GenerateCodeDialog
            open={true}
            close={(record) => {
              this.setState({
                open_generate_code: false,
              });
            }}
          />
        ) : null}
        {this.state.open_generate_react ? (
          <GenerateCodeReactDialog
            open={true}
            close={(record) => {
              this.setState({
                open_generate_react: false,
              });
            }}
          />
        ) : null}

        <>
          {this.state.open_edit_departure ? (
            <DepartureReportEditDialog
              defaultVoyageCode={this.state.after.VoyageCode}
              open={true}
              edittingRecord={this.state.edittingRecord}
              reportStatus={this.state.reportStatus}
              close={(saved) => {
                this.setState({ open_edit_departure: false }, () => {
                  if (saved) {
                    this.doSearch();
                  }
                });
              }}
            />
          ) : null}
          {this.state.open_edit_noonatsea ? (
            <NoonAtSeaReportEditDialog
              open={true}
              defaultVoyageCode={this.state.after.VoyageCode}
              edittingRecord={this.state.edittingRecord}
              reportStatus={this.state.reportStatus}
              close={(saved) => {
                this.setState({ open_edit_noonatsea: false }, () => {
                  if (saved) {
                    this.doSearch();
                  }
                });
              }}
            />
          ) : null}
          {this.state.open_edit_arrival ? (
            <ArrivalReportEditDialog
              open={true}
              defaultVoyageCode={this.state.after.VoyageCode}
              edittingRecord={this.state.edittingRecord}
              reportStatus={this.state.reportStatus}
              close={(saved) => {
                this.setState({ open_edit_arrival: false }, () => {
                  if (saved) {
                    this.doSearch();
                  }
                });
              }}
            />
          ) : null}
          {this.state.open_edit_allfast ? (
            <AllfastReportEditDialog
              open={true}
              defaultVoyageCode={this.state.after.VoyageCode}
              edittingRecord={this.state.edittingRecord}
              reportStatus={this.state.reportStatus}
              close={(saved) => {
                console.log(saved);
                this.setState({ open_edit_allfast: false }, () => {
                  if (saved) {
                    this.doSearch();
                  }
                });
              }}
            />
          ) : null}
          {this.state.open_edit_noonport ? (
            <NoonAtPortReportEditDialog
              open={true}
              defaultVoyageCode={this.state.after.VoyageCode}
              edittingRecord={this.state.edittingRecord}
              reportStatus={this.state.reportStatus}
              close={(saved) => {
                this.setState({ open_edit_noonport: false }, () => {
                  if (saved) {
                    this.doSearch();
                  }
                });
              }}
            />
          ) : null}
          {this.state.open_edit_shifting ? (
            <ShiftingReportEditDialog
              open={true}
              defaultVoyageCode={this.state.after.VoyageCode}
              edittingRecord={this.state.edittingRecord}
              reportStatus={this.state.reportStatus}
              close={(saved) => {
                this.setState({ open_edit_shifting: false }, () => {
                  if (saved) {
                    this.doSearch();
                  }
                });
              }}
            />
          ) : null}
          {this.state.open_edit_shifting_start ? (
            <ShiftingStartReportEditDialog
              open={true}
              defaultVoyageCode={this.state.after.VoyageCode}
              edittingRecord={this.state.edittingRecord}
              reportStatus={this.state.reportStatus}
              close={(saved) => {
                this.setState({ open_edit_shifting_start: false }, () => {
                  if (saved) {
                    this.doSearch();
                  }
                });
              }}
            />
          ) : null}
          {this.state.open_edit_shifting_noon ? (
            <ShiftingNoonReportEditDialog
              open={true}
              defaultVoyageCode={this.state.after.VoyageCode}
              edittingRecord={this.state.edittingRecord}
              reportStatus={this.state.reportStatus}
              close={(saved) => {
                this.setState({ open_edit_shifting_noon: false }, () => {
                  if (saved) {
                    this.doSearch();
                  }
                });
              }}
            />
          ) : null}
          {this.state.open_edit_shifting_finished ? (
            <ShiftingFinishReportEditDialog
              open={true}
              defaultVoyageCode={this.state.after.VoyageCode}
              edittingRecord={this.state.edittingRecord}
              reportStatus={this.state.reportStatus}
              close={(saved) => {
                this.setState({ open_edit_shifting_finished: false }, () => {
                  if (saved) {
                    this.doSearch();
                  }
                });
              }}
            />
          ) : null}
          {this.state.open_edit_bunker ? (
            <BunkerReportEditDialog
              open={true}
              defaultVoyageCode={this.state.after.VoyageCode}
              edittingRecord={this.state.edittingRecord}
              reportStatus={this.state.reportStatus}
              close={(saved) => {
                this.setState({ open_edit_bunker: false }, () => {
                  if (saved) {
                    this.doSearch();
                  }
                });
              }}
            />
          ) : null}
          {this.state.open_edit_cargo ? (
            <CargoReportEditDialog
              open={true}
              defaultVoyageCode={this.state.after.VoyageCode}
              edittingRecord={this.state.edittingRecord}
              reportStatus={this.state.reportStatus}
              close={(saved) => {
                this.setState({ open_edit_cargo: false }, () => {
                  if (saved) {
                    this.doSearch();
                  }
                });
              }}
            />
          ) : null}
        </>

        {isCEVersion ? (
          <>
            {this.state.open_edit_departure ? (
              <CEDepartureReportEditDialog
                defaultVoyageCode={this.state.after.VoyageCode}
                open={true}
                edittingRecord={this.state.edittingRecord}
                reportStatus={this.state.reportStatus}
                close={(saved) => {
                  this.setState({ open_edit_departure: false }, () => {
                    if (saved) {
                      this.doSearch();
                    }
                  });
                }}
              />
            ) : null}
            {this.state.open_edit_noonatsea ? (
              <CENoonAtSeaReportEditDialog
                open={true}
                defaultVoyageCode={this.state.after.VoyageCode}
                edittingRecord={this.state.edittingRecord}
                reportStatus={this.state.reportStatus}
                close={(saved) => {
                  this.setState({ open_edit_noonatsea: false }, () => {
                    if (saved) {
                      this.doSearch();
                    }
                  });
                }}
              />
            ) : null}
            {this.state.open_edit_arrival ? (
              <CEArrivalReportEditDialog
                open={true}
                defaultVoyageCode={this.state.after.VoyageCode}
                edittingRecord={this.state.edittingRecord}
                reportStatus={this.state.reportStatus}
                close={(saved) => {
                  this.setState({ open_edit_arrival: false }, () => {
                    if (saved) {
                      this.doSearch();
                    }
                  });
                }}
              />
            ) : null}
            {this.state.open_edit_allfast ? (
              <CEAllfastReportEditDialog
                open={true}
                defaultVoyageCode={this.state.after.VoyageCode}
                edittingRecord={this.state.edittingRecord}
                reportStatus={this.state.reportStatus}
                close={(saved) => {
                  console.log(saved);
                  this.setState({ open_edit_allfast: false }, () => {
                    if (saved) {
                      this.doSearch();
                    }
                  });
                }}
              />
            ) : null}
            {this.state.open_edit_noonport ? (
              <CENoonAtPortReportEditDialog
                open={true}
                defaultVoyageCode={this.state.after.VoyageCode}
                edittingRecord={this.state.edittingRecord}
                reportStatus={this.state.reportStatus}
                close={(saved) => {
                  this.setState({ open_edit_noonport: false }, () => {
                    if (saved) {
                      this.doSearch();
                    }
                  });
                }}
              />
            ) : null}
            {this.state.open_edit_shifting ? (
              <CEShiftingReportEditDialog
                open={true}
                defaultVoyageCode={this.state.after.VoyageCode}
                edittingRecord={this.state.edittingRecord}
                reportStatus={this.state.reportStatus}
                close={(saved) => {
                  this.setState({ open_edit_shifting: false }, () => {
                    if (saved) {
                      this.doSearch();
                    }
                  });
                }}
              />
            ) : null}
            {this.state.open_edit_bunker ? (
              <BunkerReportEditDialog
                open={true}
                edittingRecord={this.state.edittingRecord}
                reportStatus={this.state.reportStatus}
                close={(saved) => {
                  this.setState({ open_edit_bunker: false }, () => {
                    if (saved) {
                      this.doSearch();
                    }
                  });
                }}
              />
            ) : null}
            {this.state.open_edit_cargo ? (
              <CargoReportEditDialog
                open={true}
                edittingRecord={this.state.edittingRecord}
                reportStatus={this.state.reportStatus}
                close={(saved) => {
                  this.setState({ open_edit_cargo: false }, () => {
                    if (saved) {
                      this.doSearch();
                    }
                  });
                }}
              />
            ) : null}
          </>
        ) : null}
        {this.state.open_add_voyage ? (
          <AddVoyageDialog
            open={true}
            DM_Voyage={this.state.DanhMuc.DM_Voyage}
            close={(saved) => {
              if (saved) {
                this.state.after.VoyageCode = saved;
                this.setState({ open_add_voyage: false }, () => {
                  if (saved) {
                    this.loadDanhMuc("DM_Voyage", () => this.doSearch());
                  }
                });
              } else {
                this.setState({
                  open_add_voyage: false,
                });
              }
            }}
          />
        ) : null}
        {this.state.showConfirmXoa ? (
          <SimpleDialog
            confirm
            message={
              "Bạn muốn xóa:" + this.state.rowSelected.DailyReportID + " ?"
            }
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              console.log(this.state.rowSelected);
              if (!this.state.saving) {
                if (ok) {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  server
                    .post("Data/DoRequest", {
                      Function: "Proc_OS_DailyReport_Delete ",
                      ThamSo: {
                        DailyReportID: this.state.rowSelected.DailyReportID,
                      },
                    })
                    .then((response) => {
                      this.setState({ showConfirmXoa: false, saving: false });
                      //reload
                      this.doSearch();
                    })
                    .catch((error) => {
                      this.setState({ saving: false, showConfirmXoa: false });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ showConfirmXoa: false });
                }
              }
            }}
          />
        ) : null}
        {this.state.show_confirm_mark ? (
          <SimpleDialog
            confirm
            message={
              "Bạn muốn đánh dấu/hủy đánh dấu báo cáo có vấn đề:" +
              this.state.rowSelected.DailyReportID +
              " ?"
            }
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              console.log(this.state.rowSelected);
              if (!this.state.saving) {
                if (ok) {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  server
                    .post("Data/DoRequest", {
                      Function: "Proc_OS_DailyReport_Mark ",
                      ThamSo: {
                        DailyReportID: this.state.rowSelected.DailyReportID,
                      },
                    })
                    .then((response) => {
                      this.setState({
                        show_confirm_mark: false,
                        saving: false,
                      });
                      //reload
                      this.doSearch();
                    })
                    .catch((error) => {
                      this.setState({
                        saving: false,
                        show_confirm_mark: false,
                      });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ show_confirm_mark: false });
                }
              }
            }}
          />
        ) : null}
        {this.state.show_confirm_markSend ? (
          <SimpleDialog
            confirm
            message={
              "Bạn muốn đánh dấu/hủy đánh dấu báo cáo đã gửi qua email:" +
              this.state.rowSelected.DailyReportID +
              " ?"
            }
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              console.log(this.state.rowSelected);
              if (!this.state.saving) {
                if (ok) {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  server
                    .post("Data/DoRequest", {
                      Function: "Proc_OS_DailyReport_MarkSent",
                      ThamSo: {
                        DailyReportID: this.state.rowSelected.DailyReportID,
                      },
                    })
                    .then((response) => {
                      this.setState({
                        show_confirm_markSend: false,
                        saving: false,
                      });
                      //reload
                      this.doSearch();
                    })
                    .catch((error) => {
                      this.setState({
                        saving: false,
                        show_confirm_markSend: false,
                      });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ show_confirm_mark: false });
                }
              }
            }}
          />
        ) : null}
        {this.state.open_delete_voyage &&
        !myLib.isEmpty(this.state.after.VoyageCode) ? (
          <SimpleDialog
            confirm
            message={
              "Bạn muốn xóa mã chuyến:" + this.state.after.VoyageCode + " ?"
            }
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              //console.log(this.state.rowSelected);
              if (!this.state.saving) {
                if (ok) {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  server
                    .post("Data/DoRequest", {
                      Function: "Proc_DM_Voyage_Delete ",
                      ThamSo: {
                        VoyageCode: this.state.after.VoyageCode,
                      },
                    })
                    .then((response) => {
                      var index = this.state.DanhMuc.DM_Voyage.findIndex(
                        (item) => item.VoyageCode == this.state.after.VoyageCode
                      );
                      if (index >= 0) {
                        this.state.DanhMuc.DM_Voyage.splice(index, 1);
                        this.state.after.VoyageCode =
                          this.state.DanhMuc.DM_Voyage.length > 0
                            ? this.state.DanhMuc.DM_Voyage[0].VoyageCode
                            : "";
                      }
                      this.setState(
                        {
                          open_delete_voyage: false,
                          saving: false,
                        },
                        () => {
                          if (index >= 0) {
                            this.doSearch();
                          }
                        }
                      );
                    })
                    .catch((error) => {
                      this.setState({
                        saving: false,
                        open_delete_voyage: false,
                      });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ open_delete_voyage: false });
                }
              }
            }}
          />
        ) : null}
      </div>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(TempReportPage);
