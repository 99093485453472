import { ToastContainer, toast } from "react-toastify";
import ReactDataGrid from "@inovua/reactdatagrid-community";
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/**Cac dinh nghia dung chung */
export const filterStyles = {
  option: (base, state) => ({
    ...base,
    fontSize: 12,
    padding: 2,
  }),
  control: (base, state) => {
    return {
      // none of react-select's styles are passed to <Control />
      ...base,
      minHeight: 20,
      border: state.selectProps.error ? "1px solid red" : undefined,
    };
  },
  input: (base) => ({ ...base, fontSize: 12 }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    fontSize: 13,
  }),
  indicatorsContainer: (base, state) => ({ ...base, maxHeight: 24 }),
  indicatorContainer: (base, state) => {
    return {
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: "yellow",
    };
  },
};
export const formStyles = (theme) => ({
  red: {
    color: "white",
    backgroundColor: "#fc9003",
  },
  blue: {
    color: "white",
    backgroundColor: "#0384fc",
  },
  uppercaseText: {
    textTransform: "uppercase",
  },
  quantrong: {
    fontWeight: "bold !important",
  },
  groupHeader: {
    fontWeight: "bold !important",
    padding: 2,
    margin: 2,
    backgroundColor: '#3de3d5',
  },
  colorBlue: {
    color: "blue",
  },
  timkiem: {
    backgroundColor: "red",
  },
  grow: {
    flexFlow: 1,
  },
  grid: {
    height: `${window.innerHeight - 150}px`,
    backgroundColor: "red",
  },
  alternateRow: {
    backgroundColor: "red",
  },
  iconLabelWrapper: {
    flexDirection: "row",
  },
  labelContainer: {
    width: "auto",
    padding: 0,
  },
  smallRadioButton: {
    "& svg": {
      width: "1.em",
      height: "0.6em",
    },
  },
});

export const loadDataError = (error, empty, log, trace) => {
  return !error ? (
    <b
      style={{
        padding: 8,
        border: "1px solid #7986cb",
        color: "#0000ff",
        borderRadius: 4,
      }}
    >
      {error ? log : empty ? "Không có bản ghi nào!" : ""}
    </b>
  ) : (
    <a
      href="#"
      style={{ textDecoration: "none" }}
      onClick={() =>
        toast.error(trace, {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        })
      }
    >
      <b
        style={{
          padding: 8,
          border: "1px solid #7986cb",
          color: "red",
          borderRadius: 4,
        }}
      >
        {error ? log : empty ? "Không có bản ghi nào!" : ""}
      </b>
    </a>
  );
};
export const handleServerError = (error) => {
  console.log(error);
  var message = "";
  var log = "";
  try {
    var obj = JSON.parse(error);
    message = obj.message;
    log = obj.traceLog;
    if (log) {
      var trace = JSON.parse(log);
      message += "\n" + (trace && trace.Error ? trace.Error.Message : log);
    }
  } catch (e) {
    message = error + "";
  }
  toast.error(message, {
    position: "bottom-center",
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
};
export const showError = (error) => {
  console.log(error);
  toast.error(error + "", {
    position: "bottom-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
};

export const showSuccess = (error) => {
  console.log(error);
  toast.success(error + "", {
    position: "bottom-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
};

export const loadGridColumns = (formName, default_columns_grid, callback) => {
  if (global.grid_columns == undefined) {
    global.grid_columns = {};
  }
  if (global.grid_columns[formName] == undefined) {
    //neu chua co trong global thi load tu csdl
    server
      .post("Data/DoRequest", {
        Function: "Proc_Tool_GridColumns_Get",
        ThamSo: {
          FormName: formName,
        },
      })
      .then((response) => {
        var columns_script =
          response.DataList && response.DataList.length > 0
            ? response.DataList[0].ColumnsJson
            : null;
        if (columns_script) {
          var columns_grid = myLib.applyGridColumns(
            default_columns_grid,
            JSON.parse(columns_script)
          );
          global.grid_columns[formName] = columns_grid;
        } else {
          //gan mac dinh de lan sau ko load lai nua
          global.grid_columns[formName] = default_columns_grid;
        }
        callback(global.grid_columns[formName]);
      })
      .catch((error) => {
        console.log(error);
        callback(default_columns_grid);
      });
  }
  var obj = global.grid_columns[formName];
  if (obj == undefined) {
    obj = default_columns_grid;
  }
  callback(obj);
};
//thong tin label tren phan trang grid
export const ReactDataGrid_i18n = Object.assign(
  {},
  ReactDataGrid.defaultProps.i18n,
  {
    showingText: "Đang hiển thị bản ghi ",
    pageText: "Trang:",
    ofText: "/",
    perPageText: "Bản ghi mỗi trang:",
  }
);
