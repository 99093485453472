import "@inovua/reactdatagrid-enterprise/index.css";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/*----------------------Cac control trong project su dung den trong form----------------*/
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyNumberEditor from "../components/MyNumberEditor";
import SimpleDialog from "../components/SimpleDialog";
import { formStyles, handleServerError, showError } from "../components/common";
import FieldNameDialog from "./FieldNameDialog";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Fully Report" : "Báo cáo đầy đủ - FR";
const fieldLabels_EN = {
  VoyageCode: "Voyage:",
  ReportTime: "Report time:",
  ReportDataTime: "Data time:",
  RpmCounter: "RPM CTR:",
  FlowME: "FM:",
  FlowTemp: "T.FM:",
  METemp: "T.ME:",
  RunhME: "Rnh:",
  ConsFOME: "Cons:",
  ConsCyl: "LO:",
  RobFO: "ROB:",
  RobSump: "LO:",
  RobFW: "FW:",
  RpmME: "RPM ME:",
  BCA: "Pr:",
  Ta: "Ta:",
  Load: "Hand load:",
  Tmin: "Tmin:",
  Tmax: "Tmax:",
  PaME: "Pa:",
  AirME: "Air drop:",
  Ter: "Ter:",
  ConsFO: "Cons-FO:",
  BHP: "BHP:",
  Load: "Load:",
  Rpm: "RPM:",
  Slip: "Slip:",
  AvgSpeed: "Speed:",
  ReceiveDOFO: "Receive:",
  Floating: "Floating:",
  RepairLog: "Repair log:",
  MaterialLog: "Material log:",
  Remark: "Remark:",
};
const fieldLabels_VN = {
  VoyageCode: "Voyage:",
  ReportTime: "Report time:",
  ReportDataTime: "Data time:",
  RpmCounter: "RPM CTR:",
  FlowME: "FM:",
  FlowTemp: "T.FM:",
  METemp: "T.ME:",
  RunhME: "Rnh:",
  ConsFOME: "Cons:",
  ConsCyl: "LO:",
  RobFO: "ROB:",
  RobSump: "LO:",
  RobFW: "FW:",
  RpmME: "RPM ME:",
  BCA: "Pr:",
  Ta: "Ta:",
  Load: "Hand load:",
  Tmin: "Tmin:",
  Tmax: "Tmax:",
  PaME: "Pa:",
  AirME: "Air drop:",
  Ter: "Ter:",
  ConsFO: "Cons-FO:",
  BHP: "BHP:",
  Load: "Load:",
  Rpm: "RPM:",
  Slip: "Slip:",
  AvgSpeed: "Speed:",
  ReceiveDOFO: "Receive:",
  Floating: "Floating:",
  RepairLog: "Repair log:",
  MaterialLog: "Material log:",
  Remark: "Remark:",
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;
function getFieldNames() {
  var list = [];
  var keys = Object.keys(fieldLabels);
  for (var i = 0; i < keys.length; i++) {
    list.push({
      FieldName: keys[i],
    });
  }
  return list;
}
const listFields = getFieldNames();
const buttonLabels = englishUI
  ? {
    btnLoadRandomSample: "Random sample",
    btnSave: "Save",
    btnSend: "Send",
    btnClose: "Close"
  }
  : {
    btnLoadRandomSample: "Dữ liệu ngẫu nhiên",
    btnSave: "Lưu",
    btnSend: "Gửi",
    btnClose: "Thoát"
  };

//ham filter su dung cho combobox
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data, atts, send) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateFields(data, global.DepartureReport);
  if (Object.keys(ret).length == 0 && send) {
    //ko co loi thi kiem tra cac dieu kien khac
    if (
      data.ReceiveDOFO.length > 0 &&
      !myLib.isEmpty(data.ReceiveDOFO[0].ReceiveDate)
    ) {
      if (atts.length <= 1) {
        ret.BunkerFileName = true;
      }
    } else {
      if (atts.length < 1) {
        ret.BunkerFileName = true;
      }
    }
  }
  if (Object.keys(ret).length == 0) {
    //kiem tra thoi gian
    if (new Date(data.TimeDC).getTime() <= new Date(data.TimeDD).getTime()) {
      ret.TimeDC = true;
    }
  }
  ret.error = Object.keys(ret).length > 0;

  return ret;
}

/*------------------------------------FORM--------------------------------------*/
class CEFullyReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      DanhMuc: { OS_DailyReport: [] },
      validateStatus: { error: false }, //trang thai validate
      after: {
        DailyReportID: 0,
        VoyageCode: null,
        ReportTypeID: 1,
        ReportTime: null,
        ReportDataTime: null,
        RpmCounter: null,
        FlowME: null,
        FlowAE: null,
        FlowBLR: null,
        FlowTemp: null,
        METemp: null,
        RunhME: null,
        RunhAE1: null,
        RunhAE2: null,
        RunhAE3: null,
        RunhIG: null,
        ConsFOME: null,
        ConsDOME: null,
        ConsFOAE: null,
        ConsDOAE: null,
        ConsFOBLR: null,
        ConsDOBLR: null,
        ConsCyl: null,
        ConsSys: null,
        ConsAE: null,
        ConsHyd: null,
        RobFO: null,
        RobDO: null,
        RobSump: null,
        RobSys: null,
        RobCyl: null,
        RobAE: null,
        RobHyd: null,
        RobFW: null,
        RpmME: null,
        BCA: null,
        Load: null,
        Tmin: null,
        Tmax: null,
        PaME: null,
        AirME: null,
        Ter: null,
        ConsFO: null,
        BHP: null,
        Load: null,
        Rpm: null,
        Slip: null,
        AvgSpeed: null,
        ReceiveDOFO: [this.newRecord()],
        Floating: null,
        RepairLog: null,
        MaterialLog: null,
        Remark: null,
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
      reportStatus: props.reportStatus,
      Attachs:
        props.edittingRecord == undefined
          ? []
          : props.edittingRecord.Attachs
            ? props.edittingRecord.Attachs
            : [], // danh sach attach file
      FMEnable: global.ShipInfor.FMEnable, //thong tin cau hinh cua danh muc tau
      lastReportData: null,
      LastROB: {
        RobFO: 0,
        RobDO: 0,
        RobCyl: 0,
        RobSys: 0,
        RobAE: 0,
        RobHyd: 0,
      },
    };
  }

  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps) {
    return { open: nextProps.open };
  }

  componentDidMount() {
    this.loadRandomSample();
  }

  //load du lieu randomm
  loadRandomSample(demo) {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 9,
            Demo: demo,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 9; //fully report
          report.ReceiveDOFO = [this.newRecord()];
          report.DailyReportID = 0; // 0 = create new, not 0: update
          report.VoyageCode = myLib.isEmpty(this.props.defaultVoyageCode)
            ? report.VoyageCode
            : this.props.defaultVoyageCode;
          if (demo) {
            //xu ly thogn tin FM
            let keys = Object.keys(report);
            let enables = Object.keys(this.state.FMEnable);
            keys.map((k) => {
              if (k.indexOf("Flow") == 0) {
                let found = enables.find((en) => k.indexOf(en) == 0);
                if (found && !this.state.FMEnable[found]) {
                  report[k] = null;
                }
              }
            });
          }
          this.setState(
            {
              after: report,
              dataChanged: true,
            }
          );
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  //tra ve luong nhap vao
  getReceive(name) {
    var num = 0;
    this.state.after.ReceiveDOFO.map(
      (item) => (num += item.ReceiveDate && item[name] ? item[name] : 0)
    );
    return isNaN(num) ? 0 : num;
  }
  saveField = (fieldName, value) => {
    var s = this.state; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState({ saving: false });
  };
  saveData = (obj) => {
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  saveRecord = (record, index, field, value) => {
    record[field] = value;
    var s = { after: { ...this.state.after } }; //new value
    s.after.ReceiveDOFO[index] = record;
    s.dataChanged = true;
    if (field.indexOf("FO") >= 0 || field == "ReceiveDate") {
      this.saveField("ConsFOAE", s.after.ConsFOAE);
    } else if (field.indexOf("DO") >= 0 || field == "ReceiveDate") {
      this.saveField("ConsDOAE", s.after.ConsDOAE);
    } else if (field.indexOf("LOCyl") >= 0 || field == "ReceiveDate") {
      this.saveField("ConsCyl", s.after.ConsCyl);
    } else if (field.indexOf("LOSys") >= 0 || field == "ReceiveDate") {
      this.saveField("ConsSys", s.after.ConsSys);
    } else if (field.indexOf("LOAE") >= 0 || field == "ReceiveDate") {
      this.saveField("ConsAE", s.after.ConsAE);
    } else if (field.indexOf("LOHyd") >= 0 || field == "ReceiveDate") {
      this.saveField("ConsHyd", s.after.ConsHyd);
    } else this.setState(s);
  };

  newRecord() {
    return {
      ReceiveDate: null,
      FO: null,
      DO: null,
      LOCyl: null,
      LOSys: null,
      LOAE: null,
      LOHyd: null,
      FW: null,
    };
  }

  addRecord() {
    var s = { after: { ...this.state.after } };
    s.after.ReceiveDOFO.push(this.newRecord());
    s.dataChanged = true;
    this.setState(s);
  }

  removeRecord(index) {
    var s = { after: { ...this.state.after } };
    s.after.ReceiveDOFO.splice(index, 1);
    s.dataChanged = true;
    this.setState(s);
  }
  doUpload() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_Upload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then(() => { })
      .catch((error) => {
        console.log(error);
      });
    if (this.props.close) {
      this.props.close(this.state.dataSaved);
    }
  }
  getAttachs() {
    //tra ve ten file attachs
    var st = "";
    if (this.state.Attachs) {
      this.state.Attachs.map((att) => {
        st += (st !== "" ? "," : "") + att.FileName;
      });
    }
    return st;
  }
  readOneFile(fileData) {
    //tao promies de doc 1 file
    return new Promise((resolve, reject) => {
      if (fileData.File) {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          fileData.Content = Buffer.from(text).toString("base64");
          fileData.File = undefined; //xoa file di
          resolve(fileData);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(fileData.File);
      } else {
        resolve(fileData);
      }
    });
  }
  readFile = (onDone) => {
    var atts = []; // danh sach file dinh kem

    this.state.Attachs.map((file) => {
      const fileData = {
        File: file.File,
        FileID: file.FileID,
        FileName: file.FileName,
        Content: null,
      };
      atts.push(this.readOneFile(fileData));
    });
    Promise.all(atts)
      .then((danhsach) => onDone(danhsach))
      .catch((e) => {
        showError(e);
      }); //doi doc tat ca cac file
  };

  doSave = (reportStatus) => {
    try {
      var check = validate_Data(
        this.state.after,
        this.state.Attachs,
        reportStatus
      );
      console.log(check);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        this.readFile((atts) => {
          let attachs = []; //du lieu ve file attach
          atts.map((f) =>
            attachs.push({ FileID: f.FileID, FileName: f.FileName })
          );
          this.state.after.Attachs = attachs;
          server
            .post("Data/DoRequest", {
              Function: "Proc_OS_DailyReport_Update",
              ThamSo: {
                ReportID: this.state.after.ReportID,
                DailyReportID: this.state.after.DailyReportID,
                IsEngine: 0, //Captain
                VoyageCode: this.state.after.VoyageCode,
                ReportTypeID: this.state.after.ReportTypeID,
                ReportTime: this.state.after.ReportTime,
                ReportDataTime: this.state.after.ReportDataTime,
                ReportStatus: reportStatus,
                ReportContent: JSON.stringify(this.state.after),
                AttachedReportID: null, // TODO: select bunker port
                ParentReportID:
                  this.props.edittingRecord !== undefined
                    ? this.props.edittingRecord.ParentReportID
                    : null, // when replace an existing report
                Attachs: atts,
              },
            })
            .then((response) => {
              this.state.after.DailyReportID =
                response.OS_DailyReport[0].DailyReportID;
              this.setState(
                {
                  dataSaved: true,
                  dataChanged: false,
                  canSaved: false,
                  saving: false,
                  errorMessage: "",
                  errorLog: "",
                  reportStatus: reportStatus,
                },
                () => {
                  //upload
                  if (reportStatus == 1) {
                    this.doUpload();
                  } else {
                    alert("Cập nhật thành công!");
                  }
                }
              );
              //request ve server
              //server.doRequestProcess();
            })
            .catch((error) => {
              handleServerError(error);
              this.setState({ saving: false });
            });
        });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };
  render() {
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}

              {myLib.isLocalhost ? (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadRandomSample(true)}
                >
                  {buttonLabels.btnLoadRandomSample}
                </Button>
              ) : null}
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave(0)}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doSave(1)}
              >
                {buttonLabels.btnSend}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 2,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "VoyageCode",
                    })
                  }
                >
                  {fieldLabels.VoyageCode}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  readOnly
                  placeholder=""
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ReportTime",
                    })
                  }
                >
                  {fieldLabels.ReportTime}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ReportDataTime",
                    })
                  }
                >
                  {fieldLabels.ReportDataTime}
                </FormLabel>
              </Grid>

              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ReportDataTime"
                  key="ReportDataTime"
                  autoFocus
                  fullWidth
                  value={this.state.after.ReportDataTime}
                  onChange={(value) => {
                    this.saveField("ReportDataTime", value);
                  }}
                  error={this.state.validateStatus.ReportDataTime !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  RPM & FM
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RpmCounter",
                    })
                  }
                >
                  {fieldLabels.RpmCounter}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="RpmCounter"
                  key="RpmCounter"
                  fullWidth
                  value={this.state.after.RpmCounter}
                  onChange={(value) => {
                    this.saveField("RpmCounter", value);
                  }}
                  error={this.state.validateStatus.RpmCounter !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "FlowME",
                    })
                  }
                >
                  {fieldLabels.FlowME}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowME"
                  key="FlowME"
                  fullWidth
                  placeholder="ME"
                  onChange={(value) => {
                    this.saveField("FlowME", value);
                  }}
                  error={this.state.validateStatus.FlowME !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAE"
                  key="FlowAE"
                  fullWidth
                  placeholder="AE"
                  value={this.state.after.FlowAE}
                  onChange={(value) => {
                    this.saveField("FlowAE", value);
                  }}
                  error={this.state.validateStatus.FlowAE !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowBLR"
                  key="FlowBLR"
                  fullWidth
                  placeholder="BLR"
                  value={this.state.after.FlowBLR}
                  onChange={(value) => {
                    this.saveField("FlowBLR", value);
                  }}
                  error={this.state.validateStatus.FlowBLR !== undefined}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "FlowTemp",
                    })
                  }
                >
                  {fieldLabels.FlowTemp}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTemp"
                  key="FlowTemp"
                  fullWidth
                  placeholder="F.M"
                  value={this.state.after.FlowTemp}
                  onChange={(value) => {
                    this.saveField("FlowTemp", value);
                  }}
                  error={this.state.validateStatus.FlowTemp !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "METemp",
                    })
                  }
                >
                  {fieldLabels.METemp}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="METemp"
                  key="METemp"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.METemp}
                  onChange={(value) => {
                    this.saveField("METemp", value);
                  }}
                  error={this.state.validateStatus.METemp !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  RNH.HR
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RunhME",
                    })
                  }
                >
                  {fieldLabels.RunhME}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhME"
                  key="RunhME"
                  fullWidth
                  placeholder="ME"
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE1"
                  key="RunhAE1"
                  fullWidth
                  placeholder="AE1"
                  value={this.state.after.RunhAE1}
                  onChange={(value) => {
                    this.saveField("RunhAE1", value);
                  }}
                  error={this.state.validateStatus.RunhAE1 !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE2"
                  key="RunhAE2"
                  fullWidth
                  placeholder="AE2"
                  value={this.state.after.RunhAE2}
                  onChange={(value) => {
                    this.saveField("RunhAE2", value);
                  }}
                  error={this.state.validateStatus.RunhAE2 !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE3"
                  key="RunhAE3"
                  fullWidth
                  placeholder="AE3"
                  value={this.state.after.RunhAE3}
                  onChange={(value) => {
                    this.saveField("RunhAE3", value);
                  }}
                  error={this.state.validateStatus.RunhAE3 !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhIG"
                  key="RunhIG"
                  fullWidth
                  placeholder="IG"
                  value={this.state.after.RunhIG}
                  onChange={(value) => {
                    this.saveField("RunhIG", value);
                  }}
                  error={this.state.validateStatus.RunhIG !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  CONS
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>

              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ConsFOME",
                    })
                  }
                >
                  {fieldLabels.ConsFOME}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOME"
                  key="ConsFOME"
                  fullWidth
                  placeholder="ME/FO"
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOMEDD"
                  key="ConsDOMEDD"
                  fullWidth
                  placeholder="ME/DO"
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAE"
                  key="ConsFOAE"
                  fullWidth
                  placeholder="AE/FO"
                  value={this.state.after.ConsFOAE}
                  onChange={(value) => {
                    this.saveField("ConsFOAE", value);
                  }}
                  error={this.state.validateStatus.ConsFOAE !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAE"
                  key="ConsDOAE"
                  fullWidth
                  placeholder="AE/DO"
                  value={this.state.after.ConsDOAE}
                  onChange={(value) => {
                    this.saveField("ConsDOAE", value);
                  }}
                  error={this.state.validateStatus.ConsDOAE !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLR"
                  key="ConsFOBLR"
                  fullWidth
                  placeholder="BLR/FO"
                  value={this.state.after.ConsFOBLR}
                  onChange={(value) => {
                    this.saveField("ConsFOBLR", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLR !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLR"
                  key="ConsDOBLR"
                  fullWidth
                  placeholder="BLR/DO"
                  value={this.state.after.ConsDOBLR}
                  onChange={(value) => {
                    this.saveField("ConsDOBLR", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLR !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ConsCyl",
                    })
                  }
                >
                  {fieldLabels.ConsCyl}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsCyl"
                  key="ConsCyl"
                  fullWidth
                  placeholder="Cyl"
                  decimalScale={0}
                  value={this.state.after.ConsCyl}
                  onChange={(value) => {
                    this.saveField("ConsCyl", value);
                  }}
                  error={this.state.validateStatus.ConsCyl !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsSys"
                  key="ConsSys"
                  fullWidth
                  placeholder="Sys"
                  decimalScale={0}
                  value={this.state.after.ConsSys}
                  onChange={(value) => {
                    this.saveField("ConsSys", value);
                  }}
                  error={this.state.validateStatus.ConsSys !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsAE"
                  key="ConsAE"
                  fullWidth
                  placeholder="AE"
                  decimalScale={0}
                  value={this.state.after.ConsAE}
                  onChange={(value) => {
                    this.saveField("ConsAE", value);
                  }}
                  error={this.state.validateStatus.ConsAE !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsHyd"
                  key="ConsHyd"
                  fullWidth
                  placeholder="Hyd"
                  decimalScale={0}
                  value={this.state.after.ConsHyd}
                  onChange={(value) => {
                    this.saveField("ConsHyd", value);
                  }}
                  error={this.state.validateStatus.ConsHyd !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  R.O.B
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RobFO",
                    })
                  }
                >
                  {fieldLabels.RobFO}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFO"
                  key="RobFO"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.RobFO}
                  onChange={(value) => {
                    this.saveField("RobFO", value);
                  }}
                  error={this.state.validateStatus.RobFO !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDO"
                  key="RobDO"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.RobDO}
                  onChange={(value) => {
                    this.saveField("RobDO", value);
                  }}
                  error={this.state.validateStatus.RobDO !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RobSump",
                    })
                  }
                >
                  {fieldLabels.RobSump}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSump"
                  key="RobSump"
                  fullWidth
                  placeholder="Sump"
                  decimalScale={0}
                  value={this.state.after.RobSump}
                  onChange={(value) => {
                    this.saveField("RobSump", value);
                  }}
                  error={this.state.validateStatus.RobSump !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSys"
                  key="RobSys"
                  fullWidth
                  placeholder="Sys"
                  decimalScale={0}
                  value={this.state.after.RobSys}
                  onChange={(value) => {
                    this.saveField("RobSys", value);
                  }}
                  error={this.state.validateStatus.RobSys !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobCyl"
                  key="RobCyl"
                  fullWidth
                  placeholder="Cyl"
                  decimalScale={0}
                  value={this.state.after.RobCyl}
                  onChange={(value) => {
                    this.saveField("RobCyl", value);
                  }}
                  error={this.state.validateStatus.RobCyl !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobAE"
                  key="RobAE"
                  fullWidth
                  placeholder="AE"
                  decimalScale={0}
                  value={this.state.after.RobAE}
                  onChange={(value) => {
                    this.saveField("RobAE", value);
                  }}
                  error={this.state.validateStatus.RobAE !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobHyd"
                  key="RobHyd"
                  fullWidth
                  placeholder="Hyd"
                  decimalScale={0}
                  value={this.state.after.RobHyd}
                  onChange={(value) => {
                    this.saveField("RobHyd", value);
                  }}
                  error={this.state.validateStatus.RobHyd !== undefined}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RobFW",
                    })
                  }
                >
                  {fieldLabels.RobFW}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFW"
                  key="RobFW"
                  fullWidth
                  decimalScale={0}
                  value={this.state.after.RobFW}
                  onChange={(value) => {
                    this.saveField("RobFW", value);
                  }}
                  error={this.state.validateStatus.RobFW !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  ENGINE
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RpmME",
                    })
                  }>{fieldLabels.RpmME}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmME"
                  key="RpmME"
                  fullWidth
                  value={this.state.after.RpmME}
                  onChange={(value) => {
                    this.saveField("RpmME", value);
                  }}
                  error={this.state.validateStatus.RpmME !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.BCA}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="BCA"
                  key="BCA"
                  fullWidth
                  value={this.state.after.BCA}
                  onChange={(value) => {
                    this.saveField("BCA", value);
                  }}
                  error={this.state.validateStatus.BCA !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Load}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="Load"
                  key="Load"
                  fullWidth
                  value={this.state.after.Load}
                  onChange={(value) => {
                    this.saveField("Load", value);
                  }}
                  error={this.state.validateStatus.Load !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.Tmax}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="Tmax"
                  key="Tmax"
                  fullWidth

                  value={this.state.after.Tmax}
                  onChange={(value) => {
                    this.saveField("Tmax", value);
                  }}
                  error={this.state.validateStatus.Tmax !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.Tmin}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="Tmin"
                  key="Tmin"
                  fullWidth

                  value={this.state.after.Tmin}
                  onChange={(value) => {
                    this.saveField("Tmin", value);
                  }}
                  error={this.state.validateStatus.Tmin !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.PaME}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="PaME"
                  key="PaME"
                  fullWidth
                  value={this.state.after.PaME}
                  onChange={(value) => {
                    this.saveField("PaME", value);
                  }}
                  error={this.state.validateStatus.PaME !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.Ta}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="Ta"
                  key="Ta"
                  fullWidth
                  value={this.state.after.Ta}
                  onChange={(value) => {
                    this.saveField("Ta", value);
                  }}
                  error={this.state.validateStatus.Ta !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.AirME}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="AirME"
                  key="AirME"
                  fullWidth
                  value={this.state.after.AirME}
                  onChange={(value) => {
                    this.saveField("AirME", value);
                  }}
                  error={this.state.validateStatus.AirME !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.Ter}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="Ter"
                  key="Ter"
                  fullWidth
                  value={this.state.after.Ter}
                  onChange={(value) => {
                    this.saveField("Ter", value);
                  }}
                  error={this.state.validateStatus.Ter !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  TRIP
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ConsFO",
                    })
                  }>{fieldLabels.ConsFO}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFO"
                  key="ConsFO"
                  fullWidth
                  value={this.state.after.ConsFO}
                  onChange={(value) => {
                    this.saveField("ConsFO", value);
                  }}
                  error={this.state.validateStatus.ConsFO !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.Rpm}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="Rpm"
                  key="Rpm"
                  fullWidth
                  value={this.state.after.Rpm}
                  onChange={(value) => {
                    this.saveField("Rpm", value);
                  }}
                  error={this.state.validateStatus.Rpm !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.BHP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="BHP"
                  key="BHP"
                  fullWidth
                  value={this.state.after.BHP}
                  onChange={(value) => {
                    this.saveField("BHP", value);
                  }}
                  error={this.state.validateStatus.BHP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Load}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="Load"
                  key="Load"
                  fullWidth
                  value={this.state.after.Load}
                  onChange={(value) => {
                    this.saveField("Load", value);
                  }}
                  error={this.state.validateStatus.Load !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Slip}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="Slip"
                  key="Slip"
                  fullWidth
                  value={this.state.after.Slip}
                  onChange={(value) => {
                    this.saveField("Slip", value);
                  }}
                  error={this.state.validateStatus.Slip !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.AvgSpeed}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="AvgSpeed"
                  key="AvgSpeed"
                  fullWidth
                  value={this.state.after.AvgSpeed}
                  onChange={(value) => {
                    this.saveField("AvgSpeed", value);
                  }}
                  error={this.state.validateStatus.AvgSpeed !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  OTHERS
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <hr />
              </Grid>
            </Grid>

            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              {this.state.after.ReceiveDOFO.map((item, index) => (
                <>
                  {index == 0 ? (
                    <>
                      <Grid item xs={2}>
                        <FormLabel
                          onClick={() =>
                            this.setState({
                              show_thongtin_field: true,
                              Show_FieldName: "ReceiveDOFO",
                            })
                          }
                        >
                          {fieldLabels.ReceiveDOFO}
                        </FormLabel>
                      </Grid>
                    </>
                  ) : (
                    null
                  )}

                  <Grid item xs={4}>
                    <MyDateEditor
                      id="ReceiveDOFODate"
                      key="ReceiveDOFODate"
                      fullWidth
                      value={item.ReceiveDate}
                      onChange={(value) => {
                        this.saveRecord(item, index, "ReceiveDate", value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="ReceiveFO"
                      key="ReceiveFO"
                      fullWidth
                      placeholder="FO"
                      value={item.FO}
                      onChange={(value) => {
                        this.saveRecord(item, index, "FO", value);
                      }}
                      error={this.state.validateStatus.ReceiveFO !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="ReceiveDO"
                      key="ReceiveDO"
                      fullWidth
                      placeholder="DO"
                      value={item.DO}
                      onChange={(value) => {
                        this.saveRecord(item, index, "DO", value);
                      }}
                      error={this.state.validateStatus.ReceiveDO !== undefined}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="ReceiveLOCyl"
                      key="ReceiveLOCyl"
                      fullWidth
                      placeholder="LO-Cyl"
                      decimalScale={0}
                      value={item.LOCyl}
                      onChange={(value) => {
                        this.saveRecord(item, index, "LOCyl", value);
                      }}
                      error={
                        this.state.validateStatus.ReceiveLOCyl !== undefined
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="ReceiveLOSys"
                      key="ReceiveLOSys"
                      fullWidth
                      placeholder="LO-Sys"
                      decimalScale={0}
                      value={item.LOSys}
                      onChange={(value) => {
                        this.saveRecord(item, index, "LOSys", value);
                      }}
                      error={
                        this.state.validateStatus.ReceiveLOSys !== undefined
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="ReceiveLOAE"
                      key="ReceiveLOAE"
                      fullWidth
                      placeholder="LO-AE"
                      decimalScale={0}
                      value={item.LOAE}
                      onChange={(value) => {
                        this.saveRecord(item, index, "LOAE", value);
                      }}
                      error={this.state.validateStatus.LOAE !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="ReceiveLOHyd"
                      key="ReceiveLOHyd"
                      fullWidth
                      placeholder="LO-Hyd"
                      decimalScale={0}
                      value={item.LOHyd}
                      onChange={(value) => {
                        this.saveRecord(item, index, "LOHyd", value);
                      }}
                      error={this.state.validateStatus.LOHyd !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="ReceiveFW"
                      key="ReceiveFW"
                      fullWidth
                      placeholder="FW"
                      decimalScale={0}
                      value={item.FW}
                      onChange={(value) => {
                        this.saveRecord(item, index, "FW", value);
                      }}
                      error={this.state.validateStatus.ReceiveFW !== undefined}
                    />
                  </Grid>

                  {index == 0 ? (
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => {
                          this.addRecord();
                        }}
                      >
                        Add
                      </Button>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={2}>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            this.removeRecord(index);
                          }}
                        >
                          Remove
                        </Button>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={2}></Grid>
                </>
              ))}
            </Grid>
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Floating}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FloatingHours"
                  key="FloatingHours"
                  placeholder="hours"
                  fullWidth
                  value={this.state.after.FloatingHours}
                  onChange={(value) => {
                    this.saveField("FloatingHours", value);
                  }}
                  error={this.state.validateStatus.FloatingHours !== undefined}
                />
              </Grid>
              <Grid item xs={8}>
                <OutlinedInput
                  id="Floating"
                  key="Floating"
                  fullWidth
                  multiline
                  placeholder=""
                  value={this.state.after.Floating}
                  onChange={(event) => {
                    this.saveField("Floating", event.target.value);
                  }}
                  error={this.state.validateStatus.Floating !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RepairLog",
                    })
                  }
                >
                  {fieldLabels.RepairLog}
                </FormLabel>
              </Grid>
              <Grid item xs={10}>
                <OutlinedInput
                  id="RepairLog"
                  key="RepairLog"
                  fullWidth
                  placeholder=""
                  multiline
                  value={this.state.after.RepairLog}
                  onChange={(event) => {
                    this.saveField("RepairLog", event.target.value);
                  }}
                  error={this.state.validateStatus.RepairLog !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "MaterialLog",
                    })
                  }
                >
                  {fieldLabels.MaterialLog}
                </FormLabel>
              </Grid>
              <Grid item xs={10}>
                <OutlinedInput
                  id="MaterialLog"
                  key="MaterialLog"
                  fullWidth
                  multiline
                  placeholder=""
                  value={this.state.after.MaterialLog}
                  onChange={(event) => {
                    this.saveField("MaterialLog", event.target.value);
                  }}
                  error={this.state.validateStatus.MaterialLog !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "Remark",
                    })
                  }
                >
                  {fieldLabels.Remark}
                </FormLabel>
              </Grid>
              <Grid item xs={10}>
                <OutlinedInput
                  id="Remark"
                  key="Remark"
                  fullWidth
                  multiline
                  placeholder=""
                  value={this.state.after.Remark}
                  onChange={(event) => {
                    this.saveField("Remark", event.target.value);
                  }}
                  error={this.state.validateStatus.Remark !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
        {this.state.show_thongtin_field ? (
          <FieldNameDialog
            open={true}
            FormName="CEFullyReportEditDialog"
            ListFields={listFields}
            FieldName={this.state.Show_FieldName}
            close={() => {
              this.setState({ show_thongtin_field: false });
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  CEFullyReportEditDialog
);
