import { Grid, Typography } from "@mui/material";

export default function MyGroupHeader(props) {
  return (
    <>
      <Grid item xs={11}>
        <hr
          sx={{
            paddingTop: 5,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Typography
          sx={{
            fontWeight: "bold !important",
            padding: 2,
          }}
          align="center"
        >
          RPM & FM
        </Typography>
      </Grid>

      <Grid item xs={11}>
        <hr />
      </Grid>
    </>
  );
}
