import "@inovua/reactdatagrid-enterprise/index.css";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyNumberEditor from "../components/MyNumberEditor";
import SimpleDialog from "../components/SimpleDialog";
import {
  formStyles,
  handleServerError,
  showError
} from "../components/common";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI
  ? "Noon At Port Report"
  : "Báo cáo buổi trưa tại cảng";
const fieldLabels = englishUI
  ? {
    VoyageCode: "REF",
    ReportTypeID: "ReportTypeID",
    ReportTime: "Time",
    FlowAENP: "AE FM",
    FlowBLRNP: "BLR FM",
    Flow3NP: "ALL FM",
    FlowTempNP: "T. FM",
    ConsFOAENP: "Cons(Noon) AE(FO/DO)",
    ConsDOAENP: "",
    ConsFOBLRNP: "BLR(FO/DO)",
    ConsDOBLRNP: "ConsDOBLRNP",
    ConsCylNP: "LO (Cyl/Sys/AE/Hyd)",
    ConsSysNP: "",
    ConsAENP: "",
    ConsHydNP: "",
    FWPureNP: "FW Pur",
    RobFONP: "ROB: FO/DO/FW",
    RobDONP: "DO",
    RobFWNP: "FW",
    RunhMENP: "Rnh: ME",
    RunhAENP: "Rnh: AE",
    RunhAE1NP: "AE1",
    RunhAE2NP: "AE2",
    RunhAE3NP: "AE3",
    RunhIGNP: "IG",
    ReceiveDOFO: "Receive",
    RepairLog: "Repair log"
  }
  : {
    VoyageCode: "REF",
    ReportTypeID: "ReportTypeID",
    ReportTime: "Time",
    FlowAENP: "AE FM",
    FlowBLRNP: "BLR FM",
    Flow3NP: "ALL FM",
    FlowTempNP: "T. FM",
    ConsFOAENP: "Cons(Noon) AE(FO/DO)",
    ConsDOAENP: "",
    ConsFOBLRNP: "BLR(FO/DO)",
    ConsDOBLRNP: "ConsDOBLRNP",
    ConsCylNP: "LO (Cyl/Sys/AE/Hyd)",
    ConsSysNP: "",
    ConsAENP: "",
    ConsHydNP: "",
    FWPureNP: "FW Pur",
    RobFONP: "ROB: FO/DO/FW",
    RobDONP: "DO",
    RobFWNP: "FW",
    RunhMENP: "Rnh: ME",
    RunhAENP: "Rnh: AE",
    RunhAE1NP: "AE1",
    RunhAE2NP: "AE2",
    RunhAE3NP: "AE3",
    RunhIGNP: "IG",
    ReceiveDOFO: "Receive",
    RepairLog: "Repair log"
  };

const buttonLabels = englishUI
  ? {
    btnLoadRandomSample: "Random sample",
    btnSave: "Save",
    btnSend: "Send",
    btnClose: "Close",
  }
  : {
    btnLoadRandomSample: "Random sample",
    btnSave: "Lưu",
    btnSend: "Gửi",
    btnClose: "Thoát",
  };

//ham filter su dung cho combobox
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  console.log(data);
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateEmpty(data, []);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
    if (
      data.ReceiveDOFO.length > 1 ||
      !myLib.isEmpty(data.ReceiveDOFO[0].ReceiveDate)
    ) {
      if (myLib.isEmpty(data.BDNFileName) || myLib.isEmpty(data.BDNFileID)) {
        ret.BDNFileName = true;
      }
    }
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}
/*------------------------------------FORM--------------------------------------*/
class CENoonAtPortReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //da save du lieu chua
      validateStatus: { error: false }, //trang thai validate
      BDNFile: null, //file dinh kem
      after: {
        DailyReportID: 0,
        VoyageCode: null,
        ReportTypeID: 5,
        ReportTime: null,
        FlowAENP: null,
        FlowBLRNP: null,
        Flow3NP: null,
        FlowTempNP: null,
        ConsFOAENP: null,
        ConsDOAENP: null,
        ConsFOBLRNP: null,
        ConsDOBLRNP: null,
        ConsCylNP: null,
        ConsSysNP: null,
        ConsAENP: null,
        ConsHydNP: null,
        FWPureNP: null,
        RobFONP: null,
        RobDONP: null,
        RobFWNP: null,
        RunhMENP: null,
        RunhAE1NP: null,
        RunhAE2NP: null,
        RunhAE3NP: null,
        RunhIGNP: null,
        ReceiveDOFO: [this.newRecord()],
        RepairLog: null,
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
      reportStatus: props.reportStatus,
    };
  }

  componentDidMount() {
    this.loadRandomSample();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps) {
    return { open: nextProps.open };
  }

  //load du lieu randomm
  loadRandomSample(demo) {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 5,
            Demo: demo,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 5; //noon report in port
          report.DailyReportID = 0; // 0 = create new, not 0: update
          report.VoyageCode = myLib.isEmpty(this.props.defaultVoyageCode)
            ? report.VoyageCode
            : this.props.defaultVoyageCode;
          this.setState({
            after: report,
            dataChanged: true,
          });
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  saveField = (fieldName, value) => {
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  saveRecord = (record, index, field, value) => {
    record[field] = value;
    var s = { after: { ...this.state.after } }; //new value
    s.after.ReceiveDOFO[index] = record;
    s.dataChanged = true;

    this.setState(s);
  };

  newRecord() {
    return {
      ReceiveDate: null,
      FO: null,
      DO: null,
      LOCyl: null,
      LOSys: null,
      FW: null,
    };
  }

  addRecord() {
    var s = { after: { ...this.state.after } };
    s.after.ReceiveDOFO.push(this.newRecord());
    s.dataChanged = true;
    this.setState(s);
  }

  removeRecord(index) {
    var s = { after: { ...this.state.after } };
    s.after.ReceiveDOFO.splice(index, 1);
    s.dataChanged = true;
    this.setState(s);
  }
  doUpload() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_Upload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then(() => { })
      .catch((error) => {
        console.log(error);
      });
    if (this.props.close) {
      this.props.close(this.state.dataSaved);
    }
  }
  readFile = (file, onDone) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target.result;
        onDone(Buffer.from(text).toString("base64"));
      };
      reader.readAsArrayBuffer(file);
    } else {
      onDone(undefined);
    }
  };
  doSave = (reportStatus) => {
    try {
      var check = validate_Data(this.state.after);
      console.log(check);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        this.readFile(this.state.BDNFile, (content) => {
          server
            .post("Data/DoRequest", {
              Function: "Proc_OS_DailyReport_Update",
              ThamSo: {
                ReportID: this.state.after.ReportID,
                DailyReportID: this.state.after.DailyReportID,
                IsEngine: 0, //Captain
                VoyageCode: this.state.after.VoyageCode,
                ReportTypeID: this.state.after.ReportTypeID,
                ReportTime: this.state.after.ReportTime,
                ReportDataTime: this.state.after.TimeNP,
                ReportStatus: reportStatus,
                ReportContent: JSON.stringify(this.state.after),
                AttachedReportID: null, // TODO: select bunker port
                ParentReportID:
                  this.props.edittingRecord !== undefined
                    ? this.props.edittingRecord.ParentReportID
                    : null, // when replace an existing report
                BDNFileID: this.state.BDNFile ? this.state.after.BDNFileID : undefined,
                BDNFileName: this.state.after.BDNFileName,
                BDNFileContent: content,
              },
            })
            .then((response) => {
              this.state.after.DailyReportID =
                response.OS_DailyReport[0].DailyReportID;
              this.setState(
                {
                  dataSaved: true,
                  dataChanged: false,
                  canSaved: false,
                  saving: false,
                  errorMessage: "",
                  errorLog: "",
                  reportStatus: reportStatus,
                },
                () => {
                  //upload
                  if (reportStatus == 1) {
                    this.doUpload();
                  } else {
                    alert("Cập nhật thành công!");
                  }
                }
              );
            })
            .catch((error) => {
              handleServerError(error);
              this.setState({ saving: false });
            });
        });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };
  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
              {myLib.isLocalhost ? (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadRandomSample(true)}
                >
                  {buttonLabels.btnLoadRandomSample}
                </Button>
              ) : null}

              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave(0)}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doSave(1)}
              >
                {buttonLabels.btnSend}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  readOnly
                  placeholder=""
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ReportTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  autoFocus={true}
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>C-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.FlowAENP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAENP"
                  key="FlowAENP"
                  fullWidth
                  value={this.state.after.FlowAENP}
                  onChange={(value) => {
                    this.saveField("FlowAENP", value);
                  }}
                  error={this.state.validateStatus.FlowAENP !== undefined}
                />
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.FlowBLRNP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowBLRNP"
                  key="FlowBLRNP"
                  fullWidth
                  value={this.state.after.FlowBLRNP}
                  onChange={(value) => {
                    this.saveField("FlowBLRNP", value);
                  }}
                  error={this.state.validateStatus.FlowBLRNP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Flow3NP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="Flow3NP"
                  key="Flow3NP"
                  fullWidth
                  value={this.state.after.Flow3NP}
                  onChange={(value) => {
                    this.saveField("Flow3NP", value);
                  }}
                  error={this.state.validateStatus.Flow3NP !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowTempNP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempNP"
                  key="FlowTempNP"
                  fullWidth
                  value={this.state.after.FlowTempNP}
                  onChange={(value) => {
                    this.saveField("FlowTempNP", value);
                  }}
                  error={this.state.validateStatus.FlowTempNP !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsFOAENP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAENP"
                  key="ConsFOAENP"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.ConsFOAENP}
                  onChange={(value) => {
                    this.saveField("ConsFOAENP", value);
                  }}
                  error={this.state.validateStatus.ConsFOAENP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAENP"
                  key="ConsDOAENP"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.ConsDOAENP}
                  onChange={(value) => {
                    this.saveField("ConsDOAENP", value);
                  }}
                  error={this.state.validateStatus.ConsDOAENP !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsFOBLRNP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRNP"
                  key="ConsFOBLRNP"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.ConsFOBLRNP}
                  onChange={(value) => {
                    this.saveField("ConsFOBLRNP", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRNP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRNP"
                  key="ConsDOBLRNP"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.ConsDOBLRNP}
                  onChange={(value) => {
                    this.saveField("ConsDOBLRNP", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRNP !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsCylNP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsCylNP"
                  key="ConsCylNP"
                  fullWidth
                  placeholder="Cyl"
                  value={this.state.after.ConsCylNP}
                  onChange={(value) => {
                    this.saveField("ConsCylNP", value);
                  }}
                  error={this.state.validateStatus.ConsCylNP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsSysNP"
                  key="ConsSysNP"
                  fullWidth
                  placeholder="Sys"
                  value={this.state.after.ConsSysNP}
                  onChange={(value) => {
                    this.saveField("ConsSysNP", value);
                  }}
                  error={this.state.validateStatus.ConsSysNP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsAENP"
                  key="ConsAENP"
                  fullWidth
                  placeholder="AE"
                  value={this.state.after.ConsAENP}
                  onChange={(value) => {
                    this.saveField("ConsAENP", value);
                  }}
                  error={this.state.validateStatus.ConsAENP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsHydNP"
                  key="ConsHydNP"
                  fullWidth
                  placeholder="Hyd"
                  value={this.state.after.ConsHydNP}
                  onChange={(value) => {
                    this.saveField("ConsHydNP", value);
                  }}
                  error={this.state.validateStatus.ConsHydNP !== undefined}
                />
              </Grid>
              <Grid item xs={5}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.FWPureNP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FWPureNP"
                  key="FWPureNP"
                  fullWidth
                  value={this.state.after.FWPureNP}
                  onChange={(value) => {
                    this.saveField("FWPureNP", value);
                  }}
                  error={this.state.validateStatus.FWPureNP !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>D-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RobFONP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFONP"
                  key="RobFONP"
                  fullWidth
                  placeholder={"FO"}
                  value={this.state.after.RobFONP}
                  onChange={(value) => {
                    this.saveField("RobFONP", value);
                  }}
                  error={this.state.validateStatus.RobFONP !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDONP"
                  key="RobDONP"
                  fullWidth
                  placeholder={"DO"}
                  value={this.state.after.RobDONP}
                  onChange={(value) => {
                    this.saveField("RobDONP", value);
                  }}
                  error={this.state.validateStatus.RobDONP !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWNP"
                  key="RobFWNP"
                  fullWidth
                  placeholder={"FW"}
                  value={this.state.after.RobFWNP}
                  onChange={(value) => {
                    this.saveField("RobFWNP", value);
                  }}
                  error={this.state.validateStatus.RobFWNP !== undefined}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>E-</FormLabel>
              </Grid>

              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RunhMENP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhMENP"
                  key="RunhMENP"
                  fullWidth
                  value={this.state.after.RunhMENP}
                  onChange={(value) => {
                    this.saveField("RunhMENP", value);
                  }}
                  error={this.state.validateStatus.RunhMENP !== undefined}
                />
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RunhAENP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE1NP"
                  key="RunhAE1NP"
                  fullWidth
                  placeholder={fieldLabels.RunhAE1NP}
                  value={this.state.after.RunhAE1NP}
                  onChange={(value) => {
                    this.saveField("RunhAE1NP", value);
                  }}
                  error={this.state.validateStatus.RunhAE1NP !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE2NP"
                  key="RunhAE2NP"
                  fullWidth
                  placeholder={fieldLabels.RunhAE2NP}
                  value={this.state.after.RunhAE2NP}
                  onChange={(value) => {
                    this.saveField("RunhAE2NP", value);
                  }}
                  error={this.state.validateStatus.RunhAE2NP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE3NP"
                  key="RunhAE3NP"
                  fullWidth
                  placeholder={fieldLabels.RunhAE1NP}
                  value={this.state.after.RunhAE3NP}
                  onChange={(value) => {
                    this.saveField("RunhAE3NP", value);
                  }}
                  error={this.state.validateStatus.RunhAE3NP !== undefined}
                />
              </Grid>

              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.RunhIGNP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhIGNP"
                  key="RunhIGNP"
                  fullWidth
                  value={this.state.after.RunhIGNP}
                  onChange={(value) => {
                    this.saveField("RunhIGNP", value);
                  }}
                  error={this.state.validateStatus.RunhIGNP !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>F-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RepairLog}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="RepairLog"
                  key="RepairLog"
                  fullWidth
                  multiline
                  placeholder=""
                  value={this.state.after.RepairLog}
                  onChange={(event) => {
                    this.saveField("RepairLog", event.target.value);
                  }}
                  error={this.state.validateStatus.RepairLog !== undefined}
                />
              </Grid>
            </Grid>
            {this.state.after.ReceiveDOFO.map((item, index) => (
              <Grid container spacing={1} columns={24}>
                {index == 0 ? (
                  <>
                    <Grid item xs={1}>
                      <FormLabel>G-</FormLabel>
                    </Grid>
                    <Grid item xs={3}>
                      <FormLabel
                        onClick={() =>
                          this.setState({
                            show_thongtin_field: true,
                            Show_FieldName: "ReceiveDOFO",
                          })
                        }
                      >
                        {fieldLabels.ReceiveDOFO}
                      </FormLabel>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={4}></Grid>
                )}

                <Grid item xs={4}>
                  <MyDateEditor
                    id="ReceiveDOFODate"
                    key="ReceiveDOFODate"
                    fullWidth
                    value={item.ReceiveDate}
                    onChange={(value) => {
                      this.saveRecord(item, index, "ReceiveDate", value);
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveFO"
                    key="ReceiveFO"
                    fullWidth
                    placeholder="FO"
                    value={item.FO}
                    onChange={(value) => {
                      this.saveRecord(item, index, "FO", value);
                    }}
                    error={this.state.validateStatus.ReceiveFO !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveDO"
                    key="ReceiveDO"
                    fullWidth
                    placeholder="DO"
                    value={item.DO}
                    onChange={(value) => {
                      this.saveRecord(item, index, "DO", value);
                    }}
                    error={this.state.validateStatus.ReceiveDO !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveLOCyl"
                    key="ReceiveLOCyl"
                    fullWidth
                    placeholder="LOCyl"
                    value={item.LOCyl}
                    onChange={(value) => {
                      this.saveRecord(item, index, "LOCyl", value);
                    }}
                    error={this.state.validateStatus.ReceiveLOCyl !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveLOSys"
                    key="ReceiveLOSys"
                    fullWidth
                    placeholder="LOSys"
                    value={item.LOSys}
                    onChange={(value) => {
                      this.saveRecord(item, index, "LOSys", value);
                    }}
                    error={this.state.validateStatus.ReceiveLOSys !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveFW"
                    key="ReceiveFW"
                    fullWidth
                    placeholder="FW"
                    value={item.FW}
                    onChange={(value) => {
                      this.saveRecord(item, index, "FW", value);
                    }}
                    error={this.state.validateStatus.ReceiveFW !== undefined}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                {index == 0 ? (
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={() => {
                        this.addRecord();
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                ) : (
                  <>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={() => {
                          this.removeRecord(index);
                        }}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            ))}
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  CENoonAtPortReportEditDialog
);
