/* Khong hieu sao phai cho menu ra ngoai file rieng thi moi o duoi appbar */
import * as React from "react";
import { Menu, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MenuItem, Divider, ListItemIcon, ListItemText } from "@mui/material";
import ClassIcon from "@mui/icons-material/Class";
import Typography from "@mui/material/Typography";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import BusinessIcon from "@mui/icons-material/Business";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import PagesIcon from "@mui/icons-material/Pages";
import {
  LocationOn,
  MenuBook,
  LocationCity,
  Money,
  MoneyOutlined,
  AttachMoney,
  MapOutlined,
  LegendToggleOutlined,
  Person,
  TrackChangesOutlined,
  AdjustOutlined,
  Adjust,
  Approval,
  Flag,
  CorporateFare,
  Sailing,
  DirectionsBoat,
  Email,
} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  menuText: {
    color: "#FFF",
  },
  menuIcon: {
    color: "#FFF",
  },
}));

export default function MenuHelp(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="basic-button"
        sx={{ marginLeft: "auto" }}
        size="large"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {open ? (
          <ArrowDropDownIcon className={classes.menuIcon} />
        ) : (
          <ArrowRightIcon className={classes.menuIcon} />
        )}
        <Typography className={classes.menuText} variant="h6" noWrap>
          {"HELP"}
        </Typography>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            window.open("/help.pdf", "_blank");
            handleClose();
          }}
        >
          <ListItemIcon>
            <DirectionsBoat fontSize="small" />
          </ListItemIcon>
          <ListItemText>Hướng dẫn sử dụng - Noon Report</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            window.open("/help_chiphi.pdf", "_blank");
            handleClose();
          }}
        >
          <ListItemIcon>
            <DirectionsBoat fontSize="small" />
          </ListItemIcon>
          <ListItemText>Hướng dẫn sử dụng - Chi phí</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            window.open("HOP25.pdf", "_blank");
            handleClose();
          }}
        >
          <ListItemIcon>
            <DirectionsBoat fontSize="small" />
          </ListItemIcon>
          <ListItemText>Quy trình HOP phần 25</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
