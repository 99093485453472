import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  Menu,
  MenuItem,
  getDrawerUtilityClass,
  RadioGroup,
  Radio,
  ButtonBase,
  IconButton,
  Typography,
  CircularProgress,
  Stack,
  AppBar,
  Switch,
} from "@mui/material";
import Dropzone from "react-dropzone";
import uuid from "react-uuid";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
import SelectFilter from "react-select";
import MySelect from "../components/MySelect";
import SyncIcon from "@mui/icons-material/Sync";
/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";
import moment from "moment";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "All Fast Report" : "Báo cáo tàu cập cảng";
const fieldLabels = englishUI
  ? {
      Captain: "CAPT:",
      VoyageCode: "Voyage:",
      ReportTypeID: "ReportTypeID",
      ReportTime: "Time:",
      TimeAF: "Time AF:",
      TimeStart: "Time SS:",
      PortName: "Port:",
      PilotTime: "Pilot on:",
      PilotTime2: "Pilot of:",
      Distance: "Distance:",
      Tugs: "Tug:",
      TugTime: "Hrs:",
      TugPower: "Power:",
      ETC: "E.T.C:",
      ReceiveDOFO: "Receive:",
      RobFOAF: "ROB - AF:",
      RobDOAF: "",
      RobFWAF: "",
      RunhMEAF: "BF - AF:",
      RunhAE1AF: "AE1/AE2/AE3",
      RunhAE2AF: "",
      RunhAE3AF: "",
      RunhIGAF: "IG",
      RunhBLRAF: "BLR",
      ConsFOMEAF: "BF - AF:",
      ConsDOMEAF: "",
      ConsFOAEAF: "AE",
      ConsDOAEAF: "",
      ConsFOBLRAF: "BLR",
      ConsDOBLRAF: "",
      ConsCylAF: "LO:",
      ConsSysAF: "",
      ConsAEAF: "",
      ConsHydAF: "",
      FWPureAF: "FW Pur:",
      FlowMEAF: "F.M:",
      FlowAEAF: "",
      FlowBLRAF: "",
      Flow3AF: "",

      FlowTempAF: "T.FM:",
      METempAF: "T.ME:",
      RpmCounterMEAF: "BF - AF:",
      Remark: "Remark:",
      Bunker: "Attachs:",
    }
  : {
      VoyageCode: "REF",
      ReportTypeID: "ReportTypeID",
      ReportTime: "Time",
      TimeAF: "Time AF",
      Tugs: "Number of tugs used",
      TugTime: "TugTime",
      TugPower: "TugPower",
      ETC: "ETC",
      RobFOAF: "ROB AF: FO/DO/FW",
      RobDOAF: "",
      RobFWAF: "",
      RunhMEAF: "Running.Hr: ME",
      RunhAE1AF: "AE1/AE2/AE3",
      RunhAE2AF: "",
      RunhAE3AF: "",
      RunhIGAF: "IG",
      RunhBLRAF: "BLR",
      ConsFOMEAF: "Cons (AC): ME",
      ConsDOMEAF: "",
      ConsFOAEAF: "AE",
      ConsDOAEAF: "",
      ConsFOBLRAF: "BRL",
      ConsDOBLRAF: "",
      ConsCylAF: "LO",
      ConsSysAF: "",
      ConsAEAF: "",
      ConsHydAF: "",
      FWPureAF: "FW Pur",
      FlowMEAF: "F.M ME/AE/BLR/ALL",
      FlowAEAF: "",
      FlowBLRAF: "",
      Flow3AF: "",
      FlowTempAF: "Temp F.M/ME",
      METempAF: "",
      RpmCounterMEAF: "M/E RPM CTR",
      Remark: "Remark",
      Bunker: "B/R file",
    };

const buttonLabels = englishUI
  ? {
      btnLoadRandomSample: "Random sample",
      btnSave: "Save",
      btnSend: "Send",
      btnClose: "Close",
      btnRob: "Load ROB",
      btnDraft: "Load Draft",
      btnExport: "Export",
      btnEmail: "Email",
    }
  : {
      btnLoadRandomSample: "Random sample",
      btnSave: "Lưu",
      btnSend: "Gửi",
      btnClose: "Thoát",
      btnRob: "Load ROB",
      btnDraft: "Load Draft",
      btnExport: "Xuất file",
      btnEmail: "Email",
    };
const CodeTripList = [
  {
    label: "N",
    value: "N",
  },
  {
    label: "S",
    value: "S",
  },
];
function hasReceive(receives) {
  var has = false;
  receives.map((item) => {
    has =
      has ||
      (!myLib.isEmpty(item.ReceiveDate) &&
        (item.FO ||
          item.DO ||
          item.LOCyl ||
          item.LOSys ||
          item.LOAE ||
          item.LOHyd));
  });
  return has;
}
//ham filter su dung cho combobox
const filterDanhSach = (danhsach, fieldName, inputValue, callback) => {
  inputValue = inputValue ? inputValue.toUpperCase() : "";
  var i = 0;
  var count = 0;
  var list = [];
  for (; i < danhsach.length && count < 10; i++) {
    var item = danhsach[i];
    if (
      inputValue === "" ||
      item[fieldName].toUpperCase().indexOf(inputValue) >= 0
    ) {
      list.push(item);
      count++;
    }
  }
  callback(list);
};
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data, atts, send) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateFields(data, global.AllfastReport);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
    if (data.ReceiveDOFO.length > 0 && hasReceive(data.ReceiveDOFO)) {
      if (atts.length == 0) {
        ret.BunkerFileName = true;
      }
    }
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}
/*------------------------------------FORM--------------------------------------*/
class AllfastReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DanhMuc: { OS_DailyReport: [] },
      validateStatus: { error: false }, //trang thai validate
      after: {
        DailyReportID: 0,
        Captain: localStorage.getItem("Report.Captain"),
        VoyageCode: null,
        CodeTrip: null,
        ReportTypeID: 4,
        ReportTime: null,
        TimeAF: null,
        Tugs: null,
        TugTime: null,
        TugPower: null,
        ETC: null,
        RobFOAF: null,
        RobDOAF: null,
        RobFWAF: null,
        RunhMEAF: null,
        RunhAE1AF: null,
        RunhAE2AF: null,
        RunhAE3AF: null,
        RunhIGAF: null,
        ConsFOMEAF: null,
        ConsDOMEAF: null,
        ConsFOAEAF: null,
        ConsDOAEAF: null,
        ConsFOBLRAF: null,
        ConsDOBLRAF: null,
        ConsCylAF: null,
        ConsSysAF: null,
        ConsAEAF: null,
        ConsHydAF: null,
        FWPureAF: null,
        FlowMEAF: null,
        FlowAEAF: null,
        FlowBLRAF: null,
        Flow3AF: null,
        Flow2AF: null,
        FlowTempAF: null,
        METempAF: null,
        RpmCounterMEAF: null,
        Remark: null,
        ReceiveDOFO: [this.newRecord()],
        BunkerFileID: null,
        BunkerFileName: null,
        BDNFileID: null,
        BDNFileName: null,
        Container: global.ShipInfor.FMEnable.Container ? "Cont" : "", //cho biet co phai tau container khong, phuc vu cho validate
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
      reportStatus: props.reportStatus,
      Attachs:
        props.edittingRecord == undefined
          ? []
          : props.edittingRecord.Attachs
          ? props.edittingRecord.Attachs
          : [], // danh sach attach file
      FMEnable: global.ShipInfor.FMEnable, //thong tin cau hinh cua danh muc tau
      LastROB: {
        RobFO: 0,
        RobDO: 0,
        RobCyl: 0,
        RobSys: 0,
        RobAE: 0,
        RobHyd: 0,
      },
      HasLocalData: !myLib.isEmpty(localStorage.getItem("Report.AF")),
    };
  }

  componentDidMount() {
    this.loadRandomSample();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  //load rob tu bao cao truoc
  loadROB(time) {
    if (true) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_OS_DailyReport_GetRobTruoc",
          ThamSo: {
            VoyageCode: this.props.defaultVoyageCode,
            ReportDataTime: time,
          },
        })
        .then((response) => {
          let rob = response.ROB.length ? response.ROB[0] : undefined;
          if (rob) {
            this.state.LastROB = rob;
            this.calculateROB();
            this.setState({
              LastROB: rob,
            });
          }
        });
    }
  }
  //tra ve luong nhap vao
  getReceive(name) {
    var num = 0;
    this.state.after.ReceiveDOFO.map(
      (item) => (num += item.ReceiveDate && item[name] ? item[name] : 0)
    );
    return isNaN(num) ? 0 : num;
  }
  //tinh lai rob
  calculateROB() {
    var s = this.state;
    if (!myLib.isEmpty(s.LastROB.RobFO)) {
      s.after.RobFOAF =
        s.LastROB.RobFO -
        this.getConsValue(s.after.ConsFOMEAF) -
        this.getConsValue(s.after.ConsFOAEAF) -
        this.getConsValue(s.after.ConsFOBLRAF) +
        this.getReceive("FO");
    }
    if (!myLib.isEmpty(s.LastROB.RobDO)) {
      s.after.RobDOAF =
        s.LastROB.RobDO -
        this.getConsValue(s.after.ConsDOMEAF) -
        this.getConsValue(s.after.ConsDOAEAF) -
        this.getConsValue(s.after.ConsDOPPAF) -
        this.getConsValue(s.after.ConsDOIGAF) -
        this.getConsValue(s.after.ConsDOBLRAF) +
        this.getReceive("DO");
    }
  }
  getConsValue(value) {
    return value == undefined || value == null ? 0 : value;
  }
  //load draft tu local storeage
  loadDraft() {
    try {
      let json = localStorage.getItem("Report.AF");
      if (json && json !== "") {
        this.state.after = JSON.parse(json);
        this.state.after.ReportID = uuid();
        this.state.after.DailyReportID = 0; //dam bao la report moi
        console.log(this.state.after);
        this.setState({ saving: false });
      }
    } catch (e) {
      showError(e);
    }
  }
  //load du lieu randomm
  loadRandomSample(demo) {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 4,
            Demo: demo,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 4; //allfast report
          report.DailyReportID = 0; // 0 = create new, not 0: update
          report.VoyageCode = myLib.isEmpty(this.props.defaultVoyageCode)
            ? report.VoyageCode
            : this.props.defaultVoyageCode;
          if (demo) {
            //xu ly thogn tin FM
            let keys = Object.keys(report);
            let enables = Object.keys(this.state.FMEnable);
            keys.map((k) => {
              if (k.indexOf("Flow") == 0) {
                let found = enables.find((en) => k.indexOf(en) == 0);
                if (found && !this.state.FMEnable[found]) {
                  report[k] = null;
                }
              }
            });
          }
          this.setState(
            {
              after: { ...this.state.after, ...report },
              dataChanged: true,
            },
            () => this.loadROB()
          );
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = this.state; //new value

    s.after[fieldName] = value;
    switch (fieldName) {
      case "ConsFOMEAF":
      case "ConsFOAEAF":
      case "ConsFOBLRAF":
        if (!myLib.isEmpty(s.LastROB.RobFO)) {
          s.after.RobFOAF =
            s.LastROB.RobFO -
            this.getConsValue(s.after.ConsFOMEAF) -
            this.getConsValue(s.after.ConsFOAEAF) -
            this.getConsValue(s.after.ConsFOBLRAF) +
            this.getReceive("FO");
        }
        break;
      case "ConsDOMEAF":
      case "ConsDOAEAF":
      case "ConsDOBLRAF":
      case "ConsDOPPAF":
      case "ConsDOIGAF":
        if (!myLib.isEmpty(s.LastROB.RobDO)) {
          s.after.RobDOAF =
            s.LastROB.RobDO -
            this.getConsValue(s.after.ConsDOMEAF) -
            this.getConsValue(s.after.ConsDOAEAF) -
            this.getConsValue(s.after.ConsDOPPAF) -
            this.getConsValue(s.after.ConsDOIGAF) -
            this.getConsValue(s.after.ConsDOBLRAF) +
            this.getReceive("DO");
        }
        break;
    }
    s.dataChanged = true;
    this.setState({ saving: false });
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  saveRecord = (record, index, field, value) => {
    record[field] = value;
    var s = { after: { ...this.state.after } }; //new value
    s.after.ReceiveDOFO[index] = record;
    s.dataChanged = true;
    if (field.indexOf("FO") >= 0 || field == "ReceiveDate") {
      console.log(record);
      this.saveField("ConsFOAEAF", s.after.ConsFOAEAF);
    } else if (field.indexOf("DO") >= 0 || field == "ReceiveDate") {
      this.saveField("ConsDOAEAF", s.after.ConsDOAEAF);
    } else this.setState(s);
  };

  newRecord() {
    return {
      ReceiveDate: null,
      FO: null,
      DO: null,
      LOCyl: null,
      LOSys: null,
      LOAE: null,
      LOHyd: null,
      FW: null,
    };
  }

  addRecord() {
    var s = { after: { ...this.state.after } };
    s.after.ReceiveDOFO.push(this.newRecord());
    s.dataChanged = true;
    this.setState(s);
  }

  removeRecord(index) {
    var s = { after: { ...this.state.after } };
    s.after.ReceiveDOFO.splice(index, 1);
    s.dataChanged = true;
    this.setState(s);
  }
  doUpload() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_Upload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
    if (this.props.close) {
      this.props.close(this.state.dataSaved);
    }
  }
  getAttachs() {
    //tra ve ten file attachs
    var st = "";
    if (this.state.Attachs) {
      this.state.Attachs.map((att) => {
        st += (st !== "" ? "," : "") + att.FileName;
      });
    }
    return st;
  }
  readOneFile(fileData) {
    //tao promies de doc 1 file
    return new Promise((resolve, reject) => {
      if (fileData.File) {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          fileData.Content = Buffer.from(text).toString("base64");
          fileData.File = undefined; //xoa file di
          resolve(fileData);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(fileData.File);
      } else {
        resolve(fileData);
      }
    });
  }
  readFile = (onDone) => {
    var atts = []; // danh sach file dinh kem

    this.state.Attachs.map((file) => {
      const fileData = {
        File: file.File,
        FileID: file.FileID,
        FileName: file.FileName,
        Content: null,
      };
      atts.push(this.readOneFile(fileData));
    });
    Promise.all(atts)
      .then((danhsach) => onDone(danhsach))
      .catch((e) => {
        showError(e);
      }); //doi doc tat ca cac file
  };

  doSave = (reportStatus) => {
    try {
      if (this.state.after.DailyReportID === 0) {
        localStorage.setItem("Report.AF", JSON.stringify(this.state.after));
      }
      //save vao local
      var check = validate_Data(
        this.state.after,
        this.state.Attachs,
        reportStatus
      );
      console.log(this.state.after, check);
      var action = this.state.after.undefined > 0 ? "update" : "insert";
      if (!check.error) {
        localStorage.setItem("Report.Captain", this.state.after.Captain);
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        this.readFile((atts) => {
          let attachs = []; //du lieu ve file attach
          atts.map((f) =>
            attachs.push({ FileID: f.FileID, FileName: f.FileName })
          );
          this.state.after.Attachs = attachs;
          server
            .post("Data/DoRequest", {
              Function: "Proc_OS_DailyReport_Update",
              ThamSo: {
                ReportID: this.state.after.ReportID,
                DailyReportID: this.state.after.DailyReportID,
                IsEngine: 0, //Captain
                VoyageCode: this.state.after.VoyageCode,
                ReportTypeID: this.state.after.ReportTypeID,
                ReportTime: this.state.after.ReportTime,
                ReportDataTime: this.state.after.TimeAF,
                ReportStatus: reportStatus,
                ReportContent: JSON.stringify(this.state.after),
                AttachedReportID: null, // TODO: select bunker port
                ParentReportID:
                  this.props.edittingRecord !== undefined
                    ? this.props.edittingRecord.ParentReportID
                    : null, // when replace an existing report
                Attachs: atts,
              },
            })
            .then((response) => {
              if (
                response.OS_DailyReport == undefined ||
                response.OS_DailyReport.length === 0
              ) {
                this.props.close(); //de phong truong hop co loi bat thuong xay ra
                return;
              }
              this.state.after.DailyReportID =
                response.OS_DailyReport[0].DailyReportID;
              this.setState(
                {
                  dataSaved: true,
                  dataChanged: false,
                  canSaved: false,
                  saving: false,
                  errorMessage: "",
                  errorLog: "",
                  reportStatus: reportStatus,
                },
                () => {
                  //upload
                  if (reportStatus == 1) {
                    this.doUpload();
                  } else {
                    alert("Cập nhật thành công!");
                  }
                }
              );
              //request ve server
              //server.doRequestProcess();
            })
            .catch((error) => {
              handleServerError(error);
              this.setState({ saving: false });
            });
        });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };
  doExport() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_GetUpload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then((response) => {
        //xuat ra file
        var data = JSON.parse(response.ReportContent[0].NoiDungReport);
        var report_string = data.ReportContent;
        var report = JSON.parse(report_string);
        report.Attachs = []; //bo di phan attachs
        report_string = JSON.stringify(report);
        data.ReportContent = report_string;
        console.log(data);
        var json = JSON.stringify(data);
        const blob = new Blob([json], {
          type: "application/json;charset=utf-8;",
        });
        var fileName = this.state.after.VoyageCode;
        fileName = fileName.replace("/", "-") + "-AF";
        fileName =
          global.ShipInfor.ShipCode +
          "-" +
          moment(this.state.after.ReportTime).format("HHmm-DDMMYY");
        myLib.downloadBlob(blob, fileName);
      })
      .catch((error) => {
        handleServerError(error);
      });
  }
  render() {
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
              {myLib.isLocalhost ? (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadRandomSample(true)}
                >
                  {buttonLabels.btnLoadRandomSample}
                </Button>
              ) : null}
              {this.state.after.DailyReportID === 0 ? (
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    !this.state.HasLocalData ||
                    this.state.after.DailyReportID > 0
                  }
                  onClick={() => this.loadDraft()}
                >
                  {buttonLabels.btnDraft}
                </Button>
              ) : null}
              {this.state.loading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadROB(this.state.after.TimeAF)}
                >
                  {buttonLabels.btnRob}
                </Button>
              )}
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave(0)}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doSave(1)}
              >
                {buttonLabels.btnSend}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doExport()}
              >
                {buttonLabels.btnExport}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => {
                  myLib.sendEmail(this.state.after);
                  this.state.dataSaved = true;
                }}
              >
                {buttonLabels.btnEmail}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
              </Grid>
              <Grid item xs={this.state.FMEnable.Container ? 2 : 4}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  readOnly
                  placeholder=""
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              {this.state.FMEnable.Container ? (
                <Grid item xs={2}>
                  <MySelect
                    id="CodeTrip"
                    key="CodeTrip"
                    fullWidth
                    options={CodeTripList}
                    optionValue="value"
                    optionLabel="label"
                    value={this.state.after.CodeTrip}
                    onChange={(event) => {
                      this.saveField("CodeTrip", event.target.value);
                    }}
                    error={this.state.validateStatus.CodeTrip !== undefined}
                  ></MySelect>
                </Grid>
              ) : null}

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ReportTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TimeStart}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="TimeStart"
                  key="TimeStart"
                  fullWidth
                  autoFocus
                  value={this.state.after.TimeStart}
                  onChange={(value) => {
                    this.saveField("TimeStart", value);
                  }}
                  error={this.state.validateStatus.TimeStart !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TimeAF}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="TimeAF"
                  key="TimeAF"
                  fullWidth
                  autoFocus
                  value={this.state.after.TimeAF}
                  onChange={(value) => {
                    this.saveField("TimeAF", value);
                  }}
                  error={this.state.validateStatus.TimeAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PortName}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="PortName"
                  key="PortName"
                  fullWidth
                  value={this.state.after.PortName}
                  onChange={(event) => {
                    this.saveField(
                      "PortName",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.PortName !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PilotTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="PilotTime"
                  key="PilotTime"
                  fullWidth
                  value={this.state.after.PilotTime}
                  onChange={(value) => {
                    this.saveField("PilotTime", value);
                  }}
                  error={this.state.validateStatus.PilotTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PilotTime2}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="PilotTime2"
                  key="PilotTime2"
                  fullWidth
                  value={this.state.after.PilotTime2}
                  onChange={(value) => {
                    this.saveField("PilotTime2", value);
                  }}
                  error={this.state.validateStatus.PilotTime2 !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Distance}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="Distance"
                  key="Distance"
                  fullWidth
                  value={this.state.after.Distance}
                  onChange={(value) => {
                    this.saveField("Distance", value);
                  }}
                  error={this.state.validateStatus.Distance !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Tugs}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Tugs"
                  key="Tugs"
                  fullWidth
                  value={this.state.after.Tugs}
                  onChange={(event) => {
                    this.saveField("Tugs", event.target.value);
                  }}
                  error={this.state.validateStatus.Tugs !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TugTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="TugTime"
                  key="TugTime"
                  fullWidth
                  placeholder="hours"
                  value={this.state.after.TugTime}
                  onChange={(value) => {
                    this.saveField("TugTime", value);
                  }}
                  error={this.state.validateStatus.TugTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TugPower}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="TugPower"
                  key="TugPower"
                  fullWidth
                  value={this.state.after.TugPower}
                  onChange={(event) => {
                    this.saveField("TugPower", event.target.value);
                  }}
                  error={this.state.validateStatus.TugPower !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ETC}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ETC"
                  key="ETC"
                  fullWidth
                  value={this.state.after.ETC}
                  onChange={(value) => {
                    this.saveField("ETC", value);
                  }}
                  error={this.state.validateStatus.ETC !== undefined}
                />
              </Grid>
            </Grid>
            {this.state.after.ReceiveDOFO.map((item, index) => (
              <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
                {index == 0 ? (
                  <>
                    <Grid item xs={2}>
                      <FormLabel
                        className={classes.quantrong}
                        onClick={() =>
                          this.setState({
                            show_thongtin_field: true,
                            Show_FieldName: "ReceiveDOFO",
                          })
                        }
                      >
                        {fieldLabels.ReceiveDOFO}
                      </FormLabel>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={2}></Grid>
                )}

                <Grid item xs={4}>
                  <MyDateEditor
                    id="ReceiveDOFODate"
                    key="ReceiveDOFODate"
                    fullWidth
                    value={item.ReceiveDate}
                    onChange={(value) => {
                      this.saveRecord(item, index, "ReceiveDate", value);
                    }}
                  />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveFO"
                    key="ReceiveFO"
                    fullWidth
                    placeholder="FO"
                    value={item.FO}
                    onChange={(value) => {
                      this.saveRecord(item, index, "FO", value);
                    }}
                    error={this.state.validateStatus.ReceiveFO !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveDO"
                    key="ReceiveDO"
                    fullWidth
                    placeholder="DO"
                    value={item.DO}
                    onChange={(value) => {
                      this.saveRecord(item, index, "DO", value);
                    }}
                    error={this.state.validateStatus.ReceiveDO !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveLOCyl"
                    key="ReceiveLOCyl"
                    fullWidth
                    placeholder="LO-Cyl"
                    decimalScale={0}
                    value={item.LOCyl}
                    onChange={(value) => {
                      this.saveRecord(item, index, "LOCyl", value);
                    }}
                    error={this.state.validateStatus.ReceiveLOCyl !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveLOSys"
                    key="ReceiveLOSys"
                    fullWidth
                    placeholder="LO-Sys"
                    decimalScale={0}
                    value={item.LOSys}
                    onChange={(value) => {
                      this.saveRecord(item, index, "LOSys", value);
                    }}
                    error={this.state.validateStatus.ReceiveLOSys !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveLOAE"
                    key="ReceiveLOAE"
                    fullWidth
                    placeholder="LO-AE"
                    decimalScale={0}
                    value={item.LOAE}
                    onChange={(value) => {
                      this.saveRecord(item, index, "LOAE", value);
                    }}
                    error={this.state.validateStatus.ReceiveLOAE !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveLOHyd"
                    key="ReceiveLOHyd"
                    fullWidth
                    placeholder="LO-Hyd"
                    decimalScale={0}
                    value={item.LOHyd}
                    onChange={(value) => {
                      this.saveRecord(item, index, "LOHyd", value);
                    }}
                    error={this.state.validateStatus.ReceiveLOHyd !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveFW"
                    key="ReceiveFW"
                    fullWidth
                    placeholder="FW"
                    decimalScale={0}
                    value={item.FW}
                    onChange={(value) => {
                      this.saveRecord(item, index, "FW", value);
                    }}
                    error={this.state.validateStatus.ReceiveFW !== undefined}
                  />
                </Grid>

                {index == 0 ? (
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        this.addRecord();
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          this.removeRecord(index);
                        }}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            ))}
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  RPM & FM
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RpmCounterMEAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmCounterMEAF"
                  key="RpmCounterMEAF"
                  fullWidth
                  placeholder="RPM CTR"
                  decimalScale={0}
                  value={this.state.after.RpmCounterMEAF}
                  onChange={(value) => {
                    this.saveField("RpmCounterMEAF", value);
                  }}
                  error={this.state.validateStatus.RpmCounterMEAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowMEAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMEAF"
                  key="FlowMEAF"
                  fullWidth
                  readOnly={!this.state.FMEnable.FlowME}
                  placeholder="ME"
                  value={this.state.after.FlowMEAF}
                  onChange={(value) => {
                    this.saveField("FlowMEAF", value);
                  }}
                  error={this.state.validateStatus.FlowMEAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAEAF"
                  key="FlowAEAF"
                  fullWidth
                  readOnly={!this.state.FMEnable.FlowAE}
                  placeholder="AE"
                  value={this.state.after.FlowAEAF}
                  onChange={(value) => {
                    this.saveField("FlowAEAF", value);
                  }}
                  error={this.state.validateStatus.FlowAEAF !== undefined}
                />
              </Grid>

              {this.state.FMEnable.Flow2 ? (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow2AF"
                      key="Flow2AF"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow2}
                      placeholder="ME+GE"
                      value={this.state.after.Flow2AF}
                      onChange={(value) => {
                        this.saveField("Flow2AF", value);
                      }}
                      error={this.state.validateStatus.Flow2AF !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRAF"
                      key="FlowBLRAF"
                      fullWidth
                      readOnly={!this.state.FMEnable.FlowBLR}
                      placeholder="BLR"
                      value={this.state.after.FlowBLRAF}
                      onChange={(value) => {
                        this.saveField("FlowBLRAF", value);
                      }}
                      error={this.state.validateStatus.FlowBLRAF !== undefined}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRAF"
                      key="FlowBLRAF"
                      fullWidth
                      readOnly={!this.state.FMEnable.FlowBLR}
                      placeholder="BLR"
                      value={this.state.after.FlowBLRAF}
                      onChange={(value) => {
                        this.saveField("FlowBLRAF", value);
                      }}
                      error={this.state.validateStatus.FlowBLRAF !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow3AF"
                      key="Flow3AF"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow3}
                      placeholder="ALL"
                      value={this.state.after.Flow3AF}
                      onChange={(value) => {
                        this.saveField("Flow3AF", value);
                      }}
                      error={this.state.validateStatus.Flow3AF !== undefined}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowTempAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempAF"
                  key="FlowTempAF"
                  fullWidth
                  placeholder="F.M"
                  value={this.state.after.FlowTempAF}
                  onChange={(value) => {
                    this.saveField("FlowTempAF", value);
                  }}
                  error={this.state.validateStatus.FlowTempAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.METempAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="METempAF"
                  key="METempAF"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.METempAF}
                  onChange={(value) => {
                    this.saveField("METempAF", value);
                  }}
                  error={this.state.validateStatus.METempAF !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  RNH.HR
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhMEAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhMEAF"
                  key="RunhMEAF"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.RunhMEAF}
                  onChange={(value) => {
                    this.saveField("RunhMEAF", value);
                  }}
                  error={this.state.validateStatus.RunhMEAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE1AF"
                  key="RunhAE1AF"
                  fullWidth
                  placeholder="AE1"
                  value={this.state.after.RunhAE1AF}
                  onChange={(value) => {
                    this.saveField("RunhAE1AF", value);
                  }}
                  error={this.state.validateStatus.RunhAE1AF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE2AF"
                  key="RunhAE2AF"
                  fullWidth
                  placeholder="AE2"
                  value={this.state.after.RunhAE2AF}
                  onChange={(value) => {
                    this.saveField("RunhAE2AF", value);
                  }}
                  error={this.state.validateStatus.RunhAE2AF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE3AF"
                  key="RunhAE3AF"
                  fullWidth
                  placeholder="AE3"
                  value={this.state.after.RunhAE3AF}
                  onChange={(value) => {
                    this.saveField("RunhAE3AF", value);
                  }}
                  error={this.state.validateStatus.RunhAE3AF !== undefined}
                />
              </Grid>

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP1AF"
                    key="RunhPP1AF"
                    fullWidth
                    placeholder="PP1"
                    value={this.state.after.RunhPP1AF}
                    onChange={(value) => {
                      this.saveField("RunhPP1AF", value);
                    }}
                    error={this.state.validateStatus.RunhPP1AF !== undefined}
                  />
                </Grid>
              ) : null}

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP2AF"
                    key="RunhPP2AF"
                    fullWidth
                    placeholder="PP2"
                    value={this.state.after.RunhPP2AF}
                    onChange={(value) => {
                      this.saveField("RunhPP2AF", value);
                    }}
                    error={this.state.validateStatus.RunhPP2AF !== undefined}
                  />
                </Grid>
              ) : null}

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhIGAF"
                    key="RunhIGAF"
                    fullWidth
                    placeholder="IGG"
                    readOnly={!this.state.FMEnable.IG}
                    value={this.state.after.RunhIGAF}
                    onChange={(value) => {
                      this.saveField("RunhIGAF", value);
                    }}
                    error={this.state.validateStatus.RunhIGAF !== undefined}
                  />
                </Grid>
              ) : null}
              {this.state.FMEnable.RunhBLR ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhBLRAF"
                    key="RunhBLRAF"
                    fullWidth
                    placeholder="BLR"
                    readOnly={!this.state.FMEnable.RunhBLR}
                    value={this.state.after.RunhBLRAF}
                    onChange={(value) => {
                      this.saveField("RunhBLRAF", value);
                    }}
                    error={this.state.validateStatus.RunhBLRAF !== undefined}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  CONS
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOMEAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOMEAF"
                  key="ConsFOMEAF"
                  fullWidth
                  placeholder="ME/FO"
                  value={this.state.after.ConsFOMEAF}
                  onChange={(value) => {
                    this.saveField("ConsFOMEAF", value);
                  }}
                  error={this.state.validateStatus.ConsFOMEAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOMEAF"
                  key="ConsDOMEAF"
                  fullWidth
                  placeholder="ME/DO"
                  value={this.state.after.ConsDOMEAF}
                  onChange={(value) => {
                    this.saveField("ConsDOMEAF", value);
                  }}
                  error={this.state.validateStatus.ConsDOMEAF !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAEAF"
                  key="ConsFOAEAF"
                  fullWidth
                  placeholder="AE/FO"
                  value={this.state.after.ConsFOAEAF}
                  onChange={(value) => {
                    this.saveField("ConsFOAEAF", value);
                  }}
                  error={this.state.validateStatus.ConsFOAEAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAEAF"
                  key="ConsDOAEAF"
                  fullWidth
                  placeholder="AE/DO"
                  value={this.state.after.ConsDOAEAF}
                  onChange={(value) => {
                    this.saveField("ConsDOAEAF", value);
                  }}
                  error={this.state.validateStatus.ConsDOAEAF !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRAF"
                  key="ConsFOBLRAF"
                  fullWidth
                  placeholder="BLR/FO"
                  value={this.state.after.ConsFOBLRAF}
                  onChange={(value) => {
                    this.saveField("ConsFOBLRAF", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRAF"
                  key="ConsDOBLRAF"
                  fullWidth
                  placeholder="BLR/DO"
                  value={this.state.after.ConsDOBLRAF}
                  onChange={(value) => {
                    this.saveField("ConsDOBLRAF", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                {this.state.FMEnable.IG ? (
                  <MyNumberEditor
                    id="ConsDOPPAF"
                    key="ConsDOPPAF"
                    fullWidth
                    placeholder="PP/DO"
                    value={this.state.after.ConsDOPPAF}
                    onChange={(value) => {
                      this.saveField("ConsDOPPAF", value);
                    }}
                    error={this.state.validateStatus.ConsDOPPAF !== undefined}
                  />
                ) : null}
              </Grid>
              <Grid item xs={2}>
                {this.state.FMEnable.IG ? (
                  <MyNumberEditor
                    id="ConsDOIGAF"
                    key="ConsDOIGAF"
                    fullWidth
                    placeholder="IGG/DO"
                    value={this.state.after.ConsDOIGAF}
                    onChange={(value) => {
                      this.saveField("ConsDOIGAF", value);
                    }}
                    error={this.state.validateStatus.ConsDOIGAF !== undefined}
                  />
                ) : null}
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsCylAF}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsCylAF"
                  key="ConsCylAF"
                  fullWidth
                  placeholder="Cyl"
                  decimalScale={0}
                  value={this.state.after.ConsCylAF}
                  onChange={(value) => {
                    this.saveField("ConsCylAF", value);
                  }}
                  error={this.state.validateStatus.ConsCylAF !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsSysAF"
                  key="ConsSysAF"
                  fullWidth
                  placeholder="Sys"
                  decimalScale={0}
                  value={this.state.after.ConsSysAF}
                  onChange={(value) => {
                    this.saveField("ConsSysAF", value);
                  }}
                  error={this.state.validateStatus.ConsSysAF !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsAEAF"
                  key="ConsAEAF"
                  fullWidth
                  placeholder="AE"
                  decimalScale={0}
                  value={this.state.after.ConsAEAF}
                  onChange={(value) => {
                    this.saveField("ConsAEAF", value);
                  }}
                  error={this.state.validateStatus.ConsAEAF !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsHydAF"
                  key="ConsHydAF"
                  fullWidth
                  placeholder="Hyd"
                  decimalScale={0}
                  value={this.state.after.ConsHydAF}
                  onChange={(value) => {
                    this.saveField("ConsHydAF", value);
                  }}
                  error={this.state.validateStatus.ConsHydAF !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  R.O.B
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFOAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFOAF"
                  key="RobFOAF"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.RobFOAF}
                  onChange={(value) => {
                    this.saveField("RobFOAF", value);
                  }}
                  error={this.state.validateStatus.RobFOAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDOAF"
                  key="RobDOAF"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.RobDOAF}
                  onChange={(value) => {
                    this.saveField("RobDOAF", value);
                  }}
                  error={this.state.validateStatus.RobDOAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWAF"
                  key="RobFWAF"
                  fullWidth
                  placeholder="FW"
                  value={this.state.after.RobFWAF}
                  onChange={(value) => {
                    this.saveField("RobFWAF", value);
                  }}
                  error={this.state.validateStatus.RobFWAF !== undefined}
                />
              </Grid>
            </Grid>

            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  OTHERS
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FWPureAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FWPureAF"
                  key="FWPureAF"
                  fullWidth
                  decimalScale={1}
                  value={this.state.after.FWPureAF}
                  onChange={(value) => {
                    this.saveField("FWPureAF", value);
                  }}
                  error={this.state.validateStatus.FWPureAF !== undefined}
                />
              </Grid>
              <Grid item xs={20}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Remark}</FormLabel>
              </Grid>
              <Grid item xs={22}>
                <OutlinedInput
                  id="Remark"
                  key="Remark"
                  fullWidth
                  multiline
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.Remark}
                  onChange={(event) => {
                    this.saveField("Remark", event.target.value);
                  }}
                  error={this.state.validateStatus.Remark !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Bunker}</FormLabel>
              </Grid>
              <Grid item xs={10}>
                <OutlinedInput
                  id="Attachs"
                  key="Attachs"
                  fullWidth
                  value={this.getAttachs()}
                  onChange={() => {
                    this.state.Attachs = [];
                    this.setState({ dataChanged: true });
                  }}
                  placeholder=""
                  error={this.state.validateStatus.BunkerFileName !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <Dropzone
                  maxFiles={5}
                  minSize={1}
                  maxSize={500 * 1024}
                  multiple={true}
                  validator={(file) => {
                    if (file.size > 0 && file.size < 500 * 1024) {
                      return null;
                    }
                    alert("File too large");
                    return true;
                  }}
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      this.state.Attachs = [];
                      acceptedFiles.map((file) => {
                        this.state.Attachs.push({
                          FileID: uuid(),
                          FileName: file.name,
                          File: file,
                        });
                      });
                      this.setState({ dataChanged: true });
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button fullWidth variant="contained">
                          Upload
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{"(Max size: 500KB)"}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Captain}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Captain"
                  key="Captain"
                  fullWidth
                  placeholder=""
                  value={this.state.after.Captain}
                  onChange={(event) => {
                    this.saveField("Captain", event.target.value);
                  }}
                  error={this.state.validateStatus.Captain !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  AllfastReportEditDialog
);
