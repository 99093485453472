/**Nhap danh co quan */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";
import uuid from "react-uuid";
import {
  filterStyles,
  showError,
  handleServerError,
} from "../components/common";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import { af } from "date-fns/locale";
import { Add, Clear, Delete, Edit, SyncAlt } from "@mui/icons-material";
import BackupIcon from "@mui/icons-material/Backup";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import SimpleDialog from "../components/SimpleDialog";
import SimpleInputDialog from "../components/SimpleInputDialog";
import { useDropzone } from "react-dropzone";
import Dropzone from "react-dropzone";

const dataStruct = require("../data/DataStruct");
const myLib = require("../lib/MyLib");
const server = require("../lib/server");

const DanhSach = [
  {
    ShipName: "FORTUNE FREIGHTER",
    SERVER: "https://freighter.vosco.vietms.vn/",
    SERVER_URL: "https://freighter.vosco.vietms.vn/api/",
  },
  {
    ShipName: "FORTUNE NAVIGATOR",
    SERVER: "https://navigator.vosco.vietms.vn/",
    SERVER_URL: "https://navigator.vosco.vietms.vn/api/",
  },
  {
    ShipName: "LAN HA",
    SERVER: "https://lanha.vosco.vietms.vn/",
    SERVER_URL: "https://lanha.vosco.vietms.vn/api/",
  },
  {
    ShipName: "LUCKY STAR",
    SERVER: "https://luckystar.vosco.vietms.vn/",
    SERVER_URL: "https://luckystar.vosco.vietms.vn/api/",
  },
  {
    ShipName: "BLUE STAR",
    SERVER: "https://bluestar.vosco.vietms.vn/",
    SERVER_URL: "https://bluestar.vosco.vietms.vn/api/",
  },
  {
    ShipName: "NEPTUNE STAR",
    SERVER: "https://neptune.vosco.vietms.vn/",
    SERVER_URL: "https://neptune.vosco.vietms.vn/api/",
  },
  {
    ShipName: "VOSCO STAR",
    SERVER: "https://star.vosco.vietms.vn/",
    SERVER_URL: "https://star.vosco.vietms.vn/api/",
  },
  {
    ShipName: "VOSCO SKY",
    SERVER: "https://sky.vosco.vietms.vn/",
    SERVER_URL: "https://sky.vosco.vietms.vn/api/",
  },
  {
    ShipName: "VOSCO UNITY",
    SERVER: "https://unity.vosco.vietms.vn/",
    SERVER_URL: "https://unity.vosco.vietms.vn/api/",
  },
  {
    ShipName: "VOSCO SUNRISE",
    SERVER: "https://sunrise.vosco.vietms.vn/",
    SERVER_URL: "https://sunrise.vosco.vietms.vn/api/",
  },
  {
    ShipName: "DAI MINH",
    SERVER: "https://daiminh.vosco.vietms.vn/",
    SERVER_URL: "https://daiminh.vosco.vietms.vn/api/",
  },
  {
    ShipName: "DAI AN",
    SERVER: "https://daian.vosco.vietms.vn/",
    SERVER_URL: "https://daian.vosco.vietms.vn/api/",
  },
  {
    ShipName: "DAI PHU",
    SERVER: "https://daiphu.vosco.vietms.vn/",
    SERVER_URL: "https://daiphu.vosco.vietms.vn/api/",
  },
  {
    ShipName: "DAI THANH",
    SERVER: "https://daithanh.vosco.vietms.vn/",
    SERVER_URL: "https://daithanh.vosco.vietms.vn/api/",
  },
  {
    ShipName: "DAI HUNG",
    SERVER: "https://daihung.vosco.vietms.vn/",
    SERVER_URL: "https://daihung.vosco.vietms.vn/api/",
  },
  {
    ShipName: "VOSCO TEST",
    SERVER: "https://vosco.vietms.vn/",
    SERVER_URL: "https://vosco.vietms.vn/api/",
  },
];

//////////////////////////////////////////////---------------///////////////////////////////////////////////////////
export default class ShipSelectDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      DanhMuc: {
        Report_RDLC_Files: [],
      },
      FormConfig: null,
      uploading: false,
      after: {},
    };
  }

  componentDidMount() {}

  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  changeShip(tau) {
    window.myconfig.SERVER = tau.SERVER;
    window.myconfig.SERVER_URL = tau.SERVER_URL;
  }
  render() {
    var stt = 0;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"sm"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              {DanhSach.map((tau) => {
                var so = stt;
                stt++;

                return (
                  <Grid item xs={12}>
                    <Button
                      color={
                        Math.floor(so / 2) % 2 == 0 ? "success" : "primary"
                      }
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        this.changeShip(tau);
                        this.props.close();
                      }}
                    >
                      {tau.ShipName}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
