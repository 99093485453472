import React from "react";
import SelectFilter from "react-select";
import { filterStyles } from "./common";

export default class MySelectFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      inputValue: "",
    };
  }

  componentDidMount() {}
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }
  render() {
    return (
      <SelectFilter
        fullWidth={this.props.fullWidth}
        autoFocus={this.props.autoFocus}
        options={this.props.options}
        getOptionLabel={(item) => item[this.props["optionLabel"]] ?? this.props.placeholder}
        getOptionValue={(item) => item[this.props["optionValue"]]}
        placeholder={this.props.placeholder}
        styles={filterStyles}
        menuPosition={"fixed"}
        value={this.props.value}
        isMulti={this.props.isMulti}
        isClearable
        onChange={(item) => {
          var temp = {};
          if (this.props.isMulti) {
            temp = item;
          } else {
            temp[this.props.optionLabel] = item
              ? item[this.props["optionLabel"]]
              : null;
            temp[this.props.optionValue] = item
              ? item[this.props["optionValue"]]
              : null;
          }
          if (this.props.onChange) {
            this.props.onChange(temp);
          }
        }}
        error={this.props.error}
      ></SelectFilter>
    );
  }
}
