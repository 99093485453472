import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  Menu,
  MenuItem,
  getDrawerUtilityClass,
  RadioGroup,
  Radio,
  ButtonBase,
  IconButton,
  Typography,
  CircularProgress,
  Stack,
  AppBar,
  Switch,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
import SelectFilter from "react-select";
import MySelect from "../components/MySelect";
import SyncIcon from "@mui/icons-material/Sync";
import Dropzone from "react-dropzone";
import uuid from "react-uuid";
import moment from "moment";
/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI
  ? "Noon At Sea Report"
  : "Báo cáo buổi trưa trên biển";
const fieldLabels = englishUI
  ? {
      Captain: "CAPT:",
      VoyageCode: "Voyage:",
      ReportTypeID: "ReportTypeID",
      ReportTime: "Time:",
      TimeNS: "Noon:",
      Position: "Position:",
      DisToGo: "D.T.G:",
      Distance: "Distance:",
      AvgSpeed: "Speed:",
      ETA: "E.T.A:",
      PortName: "Port:",
      WeatherSea: "Weather:",
      WeatherWind: "",
      ShipCourse: "Course:",
      LacanWater: "Lacan:",
      SlopWater: "Slop:",
      RpmMENS: "RPM ME:",
      BCANS: "Pr",
      TaNS: "Ta",
      LoadNS: "Hand load:",
      TminNS: "Tmin:",
      TmaxNS: "Tmax:",
      PaMENS: "Pa:",
      AirMENS: "Air drop:",
      TerNS: "Ter:",
      SlipNS: "Slip:",
      RunhMENS: "BF - N:",
      RunhAENS: "AE:",
      RunhAE1NS: "AE1",
      RunhAE2NS: "AE2",
      RunhAE3NS: "AE3",
      RunhIGNS: "IG",
      RunhBLRNS: "BLR",
      RpmCounterMENS: "RPM CTR:",
      FlowMENS: "FM:",
      FlowAENS: "AE FM",
      FlowBLRNS: "BLR FM",
      Flow3NS: "ALL FM",
      Flow2NS: "ME+GE FM",
      FlowTempNS: "T.FM/ME:",

      METempNS: "T.ME:",
      ConsFOMENS: "BF - N:",
      ConsDOMENS: "",
      ConsFOAENS: "AE",
      ConsDOAENS: "",
      ConsFOBLRNS: "BLR",
      ConsDOBLRNS: "",
      ConsCylNS: "LO:",
      ConsSysNS: "",
      ConsAENS: "",
      ConsHydNS: "",
      FWPureNS: "FW Pur",
      RobFONS: "At Noon:",
      RobDONS: "DO",
      RobFWNS: "FW",
      TimeChanged: "Zone Time Adjust:",
      TimeChangeValue: "",
      Floating: "Drifting:",
      Remark: "Remark:",
      OtherChange: "Changes in the passage:",
    }
  : {
      VoyageCode: "Voyage:",
      ReportTypeID: "ReportTypeID",
      ReportTime: "Time",
      TimeNS: "Noon",
      Position: "PSN",
      DisToGo: "DTG",
      AvgSpeed: "SPD",
      ETA: "ETA",
      PortName: "Port",
      WeatherSea: "Weather (Sea/Wind)",
      WeatherWind: "",
      ShipCourse: "Course",
      LacanWater: "Lacan",
      SlopWater: "Slop",
      RpmMENS: "RPM ME",
      BCANS: "Pr",
      TaNS: "Ta",
      LoadNS: "Hand load",
      TminNS: "Tmin",
      TmaxNS: "Tmax",
      PaMENS: "Pa",
      AirMENS: "Air drop",
      TerNS: "Ter",
      SlipNS: "Slip",
      RunhMENS: "Rnh: ME",
      RunhAENS: "AE:",
      RunhAE1NS: "AE1",
      RunhAE2NS: "AE2",
      RunhAE3NS: "AE3",
      RunhIGNS: "IG",
      RunhBLRNS: "BLR",
      RpmCounterMENS: "M/E RPM CTR",
      FlowMENS: "ME FM",
      FlowAENS: "AE FM",
      FlowBLRNS: "BLR FM",
      Flow3NS: "ALL FM",
      FlowTempNS: "T. FM",
      METempNS: "T. ME",
      ConsFOMENS: "Cons: ME",
      ConsDOMENS: "",
      ConsFOAENS: "AE",
      ConsDOAENS: "",
      ConsFOBLRNS: "BLR",
      ConsDOBLRNS: "",
      ConsCylNS: "Cons: LO",
      ConsSysNS: "",
      ConsAENS: "",
      ConsHydNS: "",
      FWPureNS: "FW Pur",
      RobFONS: "ROB: FO",
      RobDONS: "DO",
      RobFWNS: "FW",
      TimeChanged: "Timezone adjust",
      TimeChangeValue: "",
      Floating: "Floating",
      Remark: "Remark",
    };
const buttonLabels = englishUI
  ? {
      btnLoadRandomSample: "Random sample",
      btnSave: "Save",
      btnSend: "Send",
      btnClose: "Close",
      btnRob: "Load Rob",
      btnDraft: "Load Draft",
      btnExport: "Export",
      btnEmail: "Email",
    }
  : {
      btnLoadRandomSample: "Random sample",
      btnSave: "Lưu",
      btnSend: "Gửi",
      btnClose: "Thoát",
      btnRob: "ROB",
      btnDraft: "Load Draft",
      btnExport: "Export",
      btnEmail: "Email",
    };

//ham filter su dung cho combobox
const filterDanhSach = (danhsach, fieldName, inputValue, callback) => {
  inputValue = inputValue ? inputValue.toUpperCase() : "";
  var i = 0;
  var count = 0;
  var list = [];
  for (; i < danhsach.length && count < 10; i++) {
    var item = danhsach[i];
    if (
      inputValue === "" ||
      item[fieldName].toUpperCase().indexOf(inputValue) >= 0
    ) {
      list.push(item);
      count++;
    }
  }
  callback(list);
};
const CodeTripList = [
  {
    label: "N",
    value: "N",
  },
  {
    label: "S",
    value: "S",
  },
];
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data, atts) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateFields(data, global.NoonAtSeaReport);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}

const TimeAdjusmentTypes = [
  {
    value: 0,
    label: "NIL",
  },
  {
    value: -1,
    label: "Retard",
  },
  {
    value: 1,
    label: "Add",
  },
];

function ParseTimeAdjusmentType(value) {
  return TimeAdjusmentTypes.find((t) => t.value == value);
}

/*------------------------------------FORM--------------------------------------*/
class NoonAtSeaReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      validateStatus: { error: false }, //trang thai validate
      after: {
        DailyReportID: 0,
        Captain: localStorage.getItem("Report.Captain"),
        VoyageCode: null,
        CodeTrip: null,
        ReportTypeID: 2,
        ReportTime: null,
        TimeNS: null,
        Position: null,
        DisToGo: null,
        Distance: null,
        AvgSpeed: null,
        ETA: null,
        PortName: null,
        WeatherSea: null,
        WeatherWind: null,
        ShipCourse: null,
        LacanWater: null,
        SlopWater: null,
        RpmMENS: null,
        BCANS: null,
        LoadNS: null,
        TminNS: null,
        TmaxNS: null,
        PaMENS: null,
        AirMENS: null,
        TerNS: null,
        SlipNS: null,
        RunhMENS: null,
        RunhAE1NS: null,
        RunhAE2NS: null,
        RunhAE3NS: null,
        RunhIGNS: null,
        RunhBLRNS: null,
        RpmCounterMENS: null,
        FlowMENS: null,
        FlowAENS: null,
        FlowBLRNS: null,
        Flow3NS: null,
        Flow2NS: null,
        FlowTempNS: null,
        ConsFOMENS: null,
        ConsDOMENS: null,
        ConsFOAENS: null,
        ConsDOAENS: null,
        ConsFOBLRNS: null,
        ConsDOBLRNS: null,
        ConsCylNS: null,
        ConsSysNS: null,
        ConsAENS: null,
        FWPure: null,
        RobFONS: null,
        RobDONS: null,
        RobFWNS: null,
        TimeChanged: null,
        TimeChangeValue: null,
        FloatingHours: null,
        Floating: null,
        Remark: null,
        Container: global.ShipInfor.FMEnable.Container ? "Cont" : "", //cho biet co phai tau container khong, phuc vu cho validate
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
      reportStatus: props.reportStatus,
      Attachs:
        props.edittingRecord == undefined || !props.edittingRecord.Attachs
          ? []
          : props.edittingRecord.Attachs, // danh sach attach file

      FMEnable: global.ShipInfor.FMEnable, //thong tin cau hinh cua danh muc tau
      LastROB: {
        RobFO: 0,
        RobDO: 0,
        RobCyl: 0,
        RobSys: 0,
        RobAE: 0,
        RobHyd: 0,
      },
      HasLocalData: !myLib.isEmpty(localStorage.getItem("Report.NS")),
    };
  }

  componentDidMount() {
    this.loadRandomSample();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  //load rob tu bao cao truoc
  loadROB(time) {
    if (true) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_OS_DailyReport_GetRobTruoc",
          ThamSo: {
            VoyageCode: this.props.defaultVoyageCode,
            ReportDataTime: time,
          },
        })
        .then((response) => {
          let rob = response.ROB.length ? response.ROB[0] : undefined;
          if (rob) {
            this.state.LastROB = rob;
            this.calculateROB();
            this.setState({
              LastROB: rob,
            });
          }
        });
    }
  }
  //tinh lai ROB
  calculateROB() {
    var s = this.state; //new value
    if (!myLib.isEmpty(s.LastROB.RobFO)) {
      s.after.RobFONS =
        s.LastROB.RobFO -
        this.getConsValue(s.after.ConsFOMENS) -
        this.getConsValue(s.after.ConsFOAENS) -
        this.getConsValue(s.after.ConsFOBLRNS);
    }
    if (!myLib.isEmpty(s.LastROB.RobDO)) {
      s.after.RobDONS =
        s.LastROB.RobDO -
        this.getConsValue(s.after.ConsDOMENS) -
        this.getConsValue(s.after.ConsDOAENS) -
        this.getConsValue(s.after.ConsDOPPNS) -
        this.getConsValue(s.after.ConsDOIGNS) -
        this.getConsValue(s.after.ConsDOBLRNS);
    }
  }
  getConsValue(value) {
    return value == undefined || value == null ? 0 : value;
  }
  //load draft tu local storeage
  loadDraft() {
    try {
      let json = localStorage.getItem("Report.NS");
      if (json && json !== "") {
        this.state.after = JSON.parse(json);
        this.state.after.ReportID = uuid();
        this.state.after.DailyReportID = 0; //dam bao la report moi
        console.log(this.state.after);
        this.setState({ saving: false });
      }
    } catch (e) {
      showError(e);
    }
  }
  //load du lieu randomm
  loadRandomSample(demo) {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 2,
            Demo: demo,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 2; // noon at sea report
          report.DailyReportID = 0; // 0 = create new, not 0: update
          report.TimeChanged = 0;
          report.VoyageCode = myLib.isEmpty(this.props.defaultVoyageCode)
            ? report.VoyageCode
            : this.props.defaultVoyageCode;
          if (demo) {
            //xu ly thogn tin FM
            let keys = Object.keys(report);
            let enables = Object.keys(this.state.FMEnable);
            keys.map((k) => {
              if (k.indexOf("Flow") == 0) {
                let found = enables.find((en) => k.indexOf(en) == 0);
                if (found && !this.state.FMEnable[found]) {
                  report[k] = null;
                }
              }
            });
          } else {
            //report.TimeNS = myLib.calculateNoonBefore(              new Date(report.ReportTime)            );
          }
          this.setState(
            {
              after: { ...this.state.after, ...report },
              dataChanged: true,
            },
            () => this.loadROB()
          );
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  //tinh thoi gian noon

  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = this.state; //new value
    s.after[fieldName] = value;
    if (fieldName == "ReportTime" && value !== null && value instanceof Date) {
      //tinh thoi gian noon
      let date = new Date(value);
      //s.after.TimeNS = myLib.calculateNoonBefore(date);
    }
    switch (fieldName) {
      case "ConsFOMENS":
      case "ConsFOAENS":
      case "ConsFOBLRNS":
        if (!myLib.isEmpty(s.LastROB.RobFO)) {
          s.after.RobFONS =
            s.LastROB.RobFO -
            this.getConsValue(s.after.ConsFOMENS) -
            this.getConsValue(s.after.ConsFOAENS) -
            this.getConsValue(s.after.ConsFOBLRNS);
        }
        break;
      case "ConsDOMENS":
      case "ConsDOAENS":
      case "ConsDOBLRNS":
      case "ConsDOPPNS":
      case "ConsDOIGNS":
        if (!myLib.isEmpty(s.LastROB.RobDO)) {
          s.after.RobDONS =
            s.LastROB.RobDO -
            this.getConsValue(s.after.ConsDOMENS) -
            this.getConsValue(s.after.ConsDOAENS) -
            this.getConsValue(s.after.ConsDOPPNS) -
            this.getConsValue(s.after.ConsDOIGNS) -
            this.getConsValue(s.after.ConsDOBLRNS);
        }
        break;
      case "RpmCounterMENS":
      case "RunhMENS":
      case "Distance":
      case "RunhMENS":
        //tinh slip, rpm
        if (!myLib.isEmpty(s.LastROB.RpmCounterME)) {
          let counter1 = s.LastROB.RpmCounterME;
          let counter2 = s.after.RpmCounterMENS;
          if (counter2) {
            let runh = s.after.RunhMENS;
            if (runh) {
              s.after.RpmMENS =
                Math.round(((counter2 - counter1) * 10) / (runh * 60)) / 10;
            }

            if (this.state.FMEnable.SlipH && s.after.Distance) {
              let dis = s.after.Distance;
              if (!isNaN(dis) && dis && counter1 !== counter2) {
                //quang duong ly thuyet
                let range =
                  ((counter2 - counter1) * this.state.FMEnable.SlipH) / 1852.0;
                s.after.SlipNS = myLib.round(((range - dis) / range) * 100, 2);
              }
            }
          }
        }
        //tinh van toc trung binh theo quang duong
        if (s.after.RunhMENS && s.after.Distance) {
          let dis = s.after.Distance;
          let hours = s.after.RunhMENS;
          s.after.AvgSpeed = Math.round((dis * 10) / hours) / 10;
        }
        break;
    }
    s.dataChanged = true;
    this.setState({ saving: false });
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doUpload() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_Upload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
    if (this.props.close) {
      this.props.close(this.state.dataSaved);
    }
  }
  getAttachs() {
    //tra ve ten file attachs
    var st = "";
    if (this.state.Attachs) {
      this.state.Attachs.map((att) => {
        st += (st !== "" ? "," : "") + att.FileName;
      });
    }
    return st;
  }
  readOneFile(fileData) {
    //tao promies de doc 1 file
    return new Promise((resolve, reject) => {
      if (fileData.File) {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          fileData.Content = Buffer.from(text).toString("base64");
          fileData.File = undefined; //xoa file di
          resolve(fileData);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(fileData.File);
      } else {
        resolve(fileData);
      }
    });
  }
  readFile = (onDone) => {
    var atts = []; // danh sach file dinh kem

    this.state.Attachs.map((file) => {
      const fileData = {
        File: file.File,
        FileID: file.FileID,
        FileName: file.FileName,
        Content: null,
      };
      atts.push(this.readOneFile(fileData));
    });
    Promise.all(atts)
      .then((danhsach) => onDone(danhsach))
      .catch((e) => {
        showError(e);
      }); //doi doc tat ca cac file
  };
  doSave = (reportStatus) => {
    try {
      if (this.state.after.DailyReportID === 0) {
        localStorage.setItem("Report.NS", JSON.stringify(this.state.after));
      }
      var check = validate_Data(this.state.after, this.state.Attachs);
      console.log(this.state.after, check);
      var action = this.state.after.undefined > 0 ? "update" : "insert";
      if (!check.error) {
        localStorage.setItem("Report.Captain", this.state.after.Captain);
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        this.readFile((atts) => {
          let attachs = []; //du lieu ve file attach
          atts.map((f) =>
            attachs.push({ FileID: f.FileID, FileName: f.FileName })
          );
          this.state.after.Attachs = attachs;
          server
            .post("Data/DoRequest", {
              Function: "Proc_OS_DailyReport_Update",
              ThamSo: {
                ReportID: this.state.after.ReportID,
                DailyReportID: this.state.after.DailyReportID,
                IsEngine: 0, //Captain
                VoyageCode: this.state.after.VoyageCode,
                ReportTypeID: this.state.after.ReportTypeID,
                ReportTime: this.state.after.ReportTime,
                ReportDataTime: this.state.after.TimeNS,
                ReportStatus: reportStatus,
                ReportContent: JSON.stringify(this.state.after),
                AttachedReportID: null, // TODO: select bunker port
                ParentReportID:
                  this.props.edittingRecord !== undefined
                    ? this.props.edittingRecord.ParentReportID
                    : null, // when replace an existing report
                Attachs: atts,
              },
            })
            .then((response) => {
              if (
                response.OS_DailyReport == undefined ||
                response.OS_DailyReport.length === 0
              ) {
                this.props.close(); //de phong truong hop co loi bat thuong xay ra
                return;
              }
              this.state.after.DailyReportID =
                response.OS_DailyReport[0].DailyReportID;
              this.setState(
                {
                  dataSaved: true,
                  dataChanged: false,
                  canSaved: false,
                  saving: false,
                  errorMessage: "",
                  errorLog: "",
                  reportStatus: reportStatus,
                },
                () => {
                  //upload
                  if (reportStatus == 1) {
                    this.doUpload();
                  } else {
                    alert("Cập nhật thành công!");
                  }
                }
              );
              //request ve server
              //server.doRequestProcess();
            })
            .catch((error) => {
              handleServerError(error);
              this.setState({ saving: false });
            });
        });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };
  doExport() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_GetUpload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then((response) => {
        //xuat ra file
        var data = JSON.parse(response.ReportContent[0].NoiDungReport);
        var report_string = data.ReportContent;
        var report = JSON.parse(report_string);
        report.Attachs = []; //bo di phan attachs
        report_string = JSON.stringify(report);
        data.ReportContent = report_string;
        console.log(data);
        var json = JSON.stringify(data);
        const blob = new Blob([json], {
          type: "application/json;charset=utf-8;",
        });
        var fileName = this.state.after.VoyageCode;
        fileName = fileName.replace("/", "-") + "-NS";
        fileName =
          global.ShipInfor.ShipCode +
          "-" +
          moment(this.state.after.ReportTime).format("HHmm-DDMMYY");
        myLib.downloadBlob(blob, fileName);
      })
      .catch((error) => {
        handleServerError(error);
      });
  }
  render() {
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
              {myLib.isLocalhost ? (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadRandomSample(true)}
                >
                  {buttonLabels.btnLoadRandomSample}
                </Button>
              ) : null}
              {this.state.after.DailyReportID === 0 ? (
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    !this.state.HasLocalData ||
                    this.state.after.DailyReportID > 0
                  }
                  onClick={() => this.loadDraft()}
                >
                  {buttonLabels.btnDraft}
                </Button>
              ) : null}
              {this.state.loading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadROB(this.state.after.TimeNS)}
                >
                  {buttonLabels.btnRob}
                </Button>
              )}
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave(0)}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doSave(1)}
              >
                {buttonLabels.btnSend}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doExport()}
              >
                {buttonLabels.btnExport}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => {
                  myLib.sendEmail(this.state.after);
                  this.state.dataSaved = true;
                }}
              >
                {buttonLabels.btnEmail}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
              </Grid>
              <Grid item xs={this.state.FMEnable.Container ? 2 : 4}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  readOnly
                  placeholder=""
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              {this.state.FMEnable.Container ? (
                <Grid item xs={2}>
                  <MySelect
                    id="CodeTrip"
                    key="CodeTrip"
                    fullWidth
                    options={CodeTripList}
                    optionValue="value"
                    optionLabel="label"
                    value={this.state.after.CodeTrip}
                    onChange={(event) => {
                      this.saveField("CodeTrip", event.target.value);
                    }}
                    error={this.state.validateStatus.CodeTrip !== undefined}
                  ></MySelect>
                </Grid>
              ) : null}
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ReportTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TimeNS}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="TimeNS"
                  key="TimeNS"
                  fullWidth
                  autoFocus
                  value={this.state.after.TimeNS}
                  onChange={(value) => {
                    this.saveField("TimeNS", value);
                  }}
                  error={this.state.validateStatus.TimeNS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Position}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Position"
                  key="Position"
                  fullWidth
                  value={this.state.after.Position}
                  onChange={(event) => {
                    this.saveField("Position", event.target.value);
                  }}
                  error={this.state.validateStatus.Position !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Distance}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="Distance"
                  key="Distance"
                  fullWidth
                  value={this.state.after.Distance}
                  onChange={(value) => {
                    this.saveField("Distance", value);
                  }}
                  error={this.state.validateStatus.Distance !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.AvgSpeed}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="AvgSpeed"
                  key="AvgSpeed"
                  fullWidth
                  value={this.state.after.AvgSpeed}
                  onChange={(value) => {
                    this.saveField("AvgSpeed", value);
                  }}
                  error={this.state.validateStatus.AvgSpeed !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ETA}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ETA"
                  key="ETA"
                  fullWidth
                  value={this.state.after.ETA}
                  onChange={(value) => {
                    this.saveField("ETA", value);
                  }}
                  error={this.state.validateStatus.ETA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PortName}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="PortName"
                  key="PortName"
                  fullWidth
                  placeholder="Port"
                  inputProps={{
                    textTransform: "uppercase",
                  }}
                  value={this.state.after.PortName}
                  onChange={(event) => {
                    this.saveField(
                      "PortName",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.PortName !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={24}>
                <hr />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.WeatherSea}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="WeatherSea"
                  key="WeatherSea"
                  fullWidth
                  placeholder="Sea"
                  value={this.state.after.WeatherSea}
                  onChange={(event) => {
                    this.saveField("WeatherSea", event.target.value);
                  }}
                  error={this.state.validateStatus.WeatherSea !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="WeatherWind"
                  key="WeatherWind"
                  fullWidth
                  placeholder="Wind"
                  value={this.state.after.WeatherWind}
                  onChange={(event) => {
                    this.saveField("WeatherWind", event.target.value);
                  }}
                  error={this.state.validateStatus.WeatherWind !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ShipCourse}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="ShipCourse"
                  key="ShipCourse"
                  fullWidth
                  value={this.state.after.ShipCourse}
                  onChange={(value) => {
                    this.saveField("ShipCourse", value);
                  }}
                  error={this.state.validateStatus.ShipCourse !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.LacanWater}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="LacanWater"
                  key="LacanWater"
                  fullWidth
                  placeholder="Lacan"
                  readOnly={this.state.FMEnable.Slop}
                  value={this.state.after.LacanWater}
                  onChange={(value) => {
                    this.saveField("LacanWater", value);
                  }}
                  error={this.state.validateStatus.LacanWater !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.SlopWater}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="SlopWater"
                  key="SlopWater"
                  fullWidth
                  placeholder="Slop"
                  readOnly={!this.state.FMEnable.Slop}
                  value={this.state.after.SlopWater}
                  onChange={(value) => {
                    this.saveField("SlopWater", value);
                  }}
                  error={
                    this.state.validateStatus.LacaSlopWaternWater !== undefined
                  }
                />
              </Grid>
            </Grid>

            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  RPM & FM
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RpmCounterMENS}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="RpmCounterMENS"
                  key="RpmCounterMENS"
                  fullWidth
                  value={this.state.after.RpmCounterMENS}
                  onChange={(value) => {
                    this.saveField("RpmCounterMENS", value);
                  }}
                  error={this.state.validateStatus.RpmCounterMENS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowMENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMENS"
                  key="FlowMENS"
                  fullWidth
                  placeholder="ME"
                  readOnly={!this.state.FMEnable.FlowME}
                  value={this.state.after.FlowMENS}
                  onChange={(value) => {
                    this.saveField("FlowMENS", value);
                  }}
                  error={this.state.validateStatus.FlowMENS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAENS"
                  key="FlowAENS"
                  fullWidth
                  placeholder="AE"
                  readOnly={!this.state.FMEnable.FlowAE}
                  value={this.state.after.FlowAENS}
                  onChange={(value) => {
                    this.saveField("FlowAENS", value);
                  }}
                  error={this.state.validateStatus.FlowAENS !== undefined}
                />
              </Grid>

              {this.state.FMEnable.Flow2 ? (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow2NS"
                      key="Flow2NS"
                      fullWidth
                      placeholder="ME+AE"
                      readOnly={!this.state.FMEnable.Flow2}
                      value={this.state.after.Flow2NS}
                      onChange={(value) => {
                        this.saveField("Flow2NS", value);
                      }}
                      error={this.state.validateStatus.Flow2NS !== undefined}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRNS"
                      key="FlowBLRNS"
                      fullWidth
                      placeholder="BLR"
                      readOnly={!this.state.FMEnable.FlowBLR}
                      value={this.state.after.FlowBLRNS}
                      onChange={(value) => {
                        this.saveField("FlowBLRNS", value);
                      }}
                      error={this.state.validateStatus.FlowBLRNS !== undefined}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRNS"
                      key="FlowBLRNS"
                      fullWidth
                      placeholder="BLR"
                      readOnly={!this.state.FMEnable.FlowBLR}
                      value={this.state.after.FlowBLRNS}
                      onChange={(value) => {
                        this.saveField("FlowBLRNS", value);
                      }}
                      error={this.state.validateStatus.FlowBLRNS !== undefined}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow3NS"
                      key="Flow3NS"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow3}
                      placeholder="ALL"
                      value={this.state.after.Flow3NS}
                      onChange={(value) => {
                        this.saveField("Flow3NS", value);
                      }}
                      error={this.state.validateStatus.Flow3NS !== undefined}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowTempNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempNS"
                  key="FlowTempNS"
                  fullWidth
                  placeholder="FM"
                  value={this.state.after.FlowTempNS}
                  onChange={(value) => {
                    this.saveField("FlowTempNS", value);
                  }}
                  error={this.state.validateStatus.FlowTempNS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="METempNS"
                  key="METempNS"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.METempNS}
                  onChange={(value) => {
                    this.saveField("METempNS", value);
                  }}
                  error={this.state.validateStatus.METempNS !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  RNH.HR
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhMENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhMENS"
                  key="RunhMENS"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.RunhMENS}
                  onChange={(value) => {
                    this.saveField("RunhMENS", value);
                  }}
                  error={this.state.validateStatus.RunhMENS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE1NS"
                  key="RunhAE1NS"
                  fullWidth
                  placeholder="AE1"
                  value={this.state.after.RunhAE1NS}
                  onChange={(value) => {
                    this.saveField("RunhAE1NS", value);
                  }}
                  error={this.state.validateStatus.RunhAE1NS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE2NS"
                  key="RunhAE2NS"
                  fullWidth
                  placeholder={fieldLabels.RunhAE2NS}
                  value={this.state.after.RunhAE2NS}
                  onChange={(value) => {
                    this.saveField("RunhAE2NS", value);
                  }}
                  error={this.state.validateStatus.RunhAE2NS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE3NS"
                  key="RunhAE3NS"
                  fullWidth
                  placeholder={fieldLabels.RunhAE3NS}
                  value={this.state.after.RunhAE3NS}
                  onChange={(value) => {
                    this.saveField("RunhAE3NS", value);
                  }}
                  error={this.state.validateStatus.RunhAE3NS !== undefined}
                />
              </Grid>

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP1NS"
                    key="RunhPP1NS"
                    fullWidth
                    placeholder="PP1"
                    value={this.state.after.RunhPP1NS}
                    onChange={(value) => {
                      this.saveField("RunhPP1NS", value);
                    }}
                    error={this.state.validateStatus.RunhPP1NS !== undefined}
                  />
                </Grid>
              ) : null}

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP2NS"
                    key="RunhPP2NS"
                    fullWidth
                    placeholder="PP2"
                    value={this.state.after.RunhPP2NS}
                    onChange={(value) => {
                      this.saveField("RunhPP2NS", value);
                    }}
                    error={this.state.validateStatus.RunhPP2NS !== undefined}
                  />
                </Grid>
              ) : null}

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhIGNS"
                    key="RunhIGNS"
                    fullWidth
                    readOnly={!this.state.FMEnable.IG}
                    placeholder="IGG"
                    value={this.state.after.RunhIGNS}
                    onChange={(value) => {
                      this.saveField("RunhIGNS", value);
                    }}
                    error={this.state.validateStatus.RunhIGNS !== undefined}
                  />
                </Grid>
              ) : null}
              {this.state.FMEnable.RunhBLR ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhBLRNS"
                    key="RunhBLRNS"
                    fullWidth
                    readOnly={!this.state.FMEnable.RunhBLR}
                    placeholder="BLR"
                    value={this.state.after.RunhBLRNS}
                    onChange={(value) => {
                      this.saveField("RunhBLRNS", value);
                    }}
                    error={this.state.validateStatus.RunhBLRNS !== undefined}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  ENGINE
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RpmMENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmMENS"
                  key="RpmMENS"
                  fullWidth
                  value={this.state.after.RpmMENS}
                  onChange={(value) => {
                    this.saveField("RpmMENS", value);
                  }}
                  error={this.state.validateStatus.RpmMENS !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.BCANS}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="BCANS"
                  key="BCANS"
                  fullWidth
                  value={this.state.after.BCANS}
                  onChange={(value) => {
                    this.saveField("BCANS", value);
                  }}
                  error={this.state.validateStatus.BCANS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.LoadNS}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="LoadNS"
                  key="LoadNS"
                  fullWidth
                  value={this.state.after.LoadNS}
                  onChange={(value) => {
                    this.saveField("LoadNS", value);
                  }}
                  error={this.state.validateStatus.LoadNS !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.TmaxNS}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="TmaxNS"
                  key="TmaxNS"
                  fullWidth
                  value={this.state.after.TmaxNS}
                  onChange={(value) => {
                    this.saveField("TmaxNS", value);
                  }}
                  error={this.state.validateStatus.TmaxNS !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.TminNS}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="TminNS"
                  key="TminNS"
                  fullWidth
                  value={this.state.after.TminNS}
                  onChange={(value) => {
                    this.saveField("TminNS", value);
                  }}
                  error={this.state.validateStatus.TminNS !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.PaMENS}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="PaMENS"
                  key="PaMENS"
                  fullWidth
                  value={this.state.after.PaMENS}
                  onChange={(value) => {
                    this.saveField("PaMENS", value);
                  }}
                  error={this.state.validateStatus.PaMENS !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.TaNS}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="TaNS"
                  key="TaNS"
                  fullWidth
                  value={this.state.after.TaNS}
                  onChange={(value) => {
                    this.saveField("TaNS", value);
                  }}
                  error={this.state.validateStatus.TaNS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.AirMENS}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="AirMENS"
                  key="AirMENS"
                  fullWidth
                  value={this.state.after.AirMENS}
                  onChange={(value) => {
                    this.saveField("AirMENS", value);
                  }}
                  error={this.state.validateStatus.AirMENS !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.TerNS}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="TerNS"
                  key="TerNS"
                  fullWidth
                  value={this.state.after.TerNS}
                  onChange={(value) => {
                    this.saveField("TerNS", value);
                  }}
                  error={this.state.validateStatus.TerNS !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.SlipNS}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="SlipNS"
                  key="SlipNS"
                  fullWidth
                  value={this.state.after.SlipNS}
                  onChange={(value) => {
                    this.saveField("SlipNS", value);
                  }}
                  error={this.state.validateStatus.SlipNS !== undefined}
                />
              </Grid>
            </Grid>

            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  CONS
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOMENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOMENS"
                  key="ConsFOMENS"
                  fullWidth
                  placeholder="ME/FO"
                  value={this.state.after.ConsFOMENS}
                  onChange={(value) => {
                    this.saveField("ConsFOMENS", value);
                  }}
                  error={this.state.validateStatus.ConsFOMENS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOMENS"
                  key="ConsDOMENS"
                  fullWidth
                  placeholder="ME/DO"
                  value={this.state.after.ConsDOMENS}
                  onChange={(value) => {
                    this.saveField("ConsDOMENS", value);
                  }}
                  error={this.state.validateStatus.ConsDOMENS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAENS"
                  key="ConsFOAENS"
                  fullWidth
                  placeholder="AE/FO"
                  value={this.state.after.ConsFOAENS}
                  onChange={(value) => {
                    this.saveField("ConsFOAENS", value);
                  }}
                  error={this.state.validateStatus.ConsFOAENS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAENS"
                  key="ConsDOAENS"
                  fullWidth
                  placeholder="AE/DO"
                  value={this.state.after.ConsDOAENS}
                  onChange={(value) => {
                    this.saveField("ConsDOAENS", value);
                  }}
                  error={this.state.validateStatus.ConsDOAENS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRNS"
                  key="ConsFOBLRNS"
                  fullWidth
                  placeholder="BLR/FO"
                  value={this.state.after.ConsFOBLRNS}
                  onChange={(value) => {
                    this.saveField("ConsFOBLRNS", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRNS"
                  key="ConsDOBLRNS"
                  fullWidth
                  placeholder="BLR/DO"
                  value={this.state.after.ConsDOBLRNS}
                  onChange={(value) => {
                    this.saveField("ConsDOBLRNS", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                {this.state.FMEnable.IG ? (
                  <MyNumberEditor
                    id="ConsDOPPNS"
                    key="ConsDOPPNS"
                    fullWidth
                    placeholder="PP/DO"
                    value={this.state.after.ConsDOPPNS}
                    onChange={(value) => {
                      this.saveField("ConsDOPPNS", value);
                    }}
                    error={this.state.validateStatus.ConsDOPPNS !== undefined}
                  />
                ) : null}
              </Grid>
              <Grid item xs={2}>
                {this.state.FMEnable.IG ? (
                  <MyNumberEditor
                    id="ConsDOIGNS"
                    key="ConsDOIGNS"
                    fullWidth
                    placeholder="IGG/DO"
                    value={this.state.after.ConsDOIGNS}
                    onChange={(value) => {
                      this.saveField("ConsDOIGNS", value);
                    }}
                    error={this.state.validateStatus.ConsDOIGNS !== undefined}
                  />
                ) : null}
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.ConsCylNS}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsCylNS"
                  key="ConsCylNS"
                  fullWidth
                  placeholder="Cyl"
                  decimalScale={0}
                  value={this.state.after.ConsCylNS}
                  onChange={(value) => {
                    this.saveField("ConsCylNS", value);
                  }}
                  error={this.state.validateStatus.ConsCylNS !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsSysNS"
                  key="ConsSysNS"
                  fullWidth
                  placeholder="Sys"
                  decimalScale={0}
                  value={this.state.after.ConsSysNS}
                  onChange={(value) => {
                    this.saveField("ConsSysNS", value);
                  }}
                  error={this.state.validateStatus.ConsSysNS !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsAENS"
                  key="ConsAENS"
                  fullWidth
                  placeholder="AE"
                  decimalScale={0}
                  value={this.state.after.ConsAENS}
                  onChange={(value) => {
                    this.saveField("ConsAENS", value);
                  }}
                  error={this.state.validateStatus.ConsAENS !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsHydNS"
                  key="ConsHydNS"
                  fullWidth
                  placeholder="Hyd"
                  decimalScale={0}
                  value={this.state.after.ConsHydNS}
                  onChange={(value) => {
                    this.saveField("ConsHydNS", value);
                  }}
                  error={this.state.validateStatus.ConsHydNS !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  R.O.B
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFONS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFONS"
                  key="RobFONS"
                  fullWidth
                  value={this.state.after.RobFONS}
                  onChange={(value) => {
                    this.saveField("RobFONS", value);
                  }}
                  error={this.state.validateStatus.RobFONS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDONS"
                  key="RobDONS"
                  fullWidth
                  value={this.state.after.RobDONS}
                  onChange={(value) => {
                    this.saveField("RobDONS", value);
                  }}
                  error={this.state.validateStatus.RobDONS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFWNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWNS"
                  key="RobFWNS"
                  fullWidth
                  decimalScale={0}
                  value={this.state.after.RobFWNS}
                  onChange={(value) => {
                    this.saveField("RobFWNS", value);
                  }}
                  error={this.state.validateStatus.RobFWNS !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  OTHERS
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FWPureNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FWPureNS"
                  key="FWPureNS"
                  fullWidth
                  decimalScale={1}
                  value={this.state.after.FWPureNS}
                  onChange={(value) => {
                    this.saveField("FWPureNS", value);
                  }}
                  error={this.state.validateStatus.FWPureNS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TimeChanged}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MySelect
                  id="TimeChanged"
                  key="TimeChanged"
                  fullWidth
                  options={TimeAdjusmentTypes}
                  optionValue="value"
                  optionLabel="label"
                  value={this.state.after.TimeChanged}
                  onChange={(event) => {
                    this.saveData({
                      TimeChanged: event.target.value,
                      TimeChangeValue:
                        event.target.value == 0
                          ? null
                          : this.state.after.TimeChangeValue,
                    });
                  }}
                ></MySelect>
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="TimeChangeValue"
                  key="TimeChangeValue"
                  readOnly={this.state.after.TimeChanged == 0}
                  fullWidth
                  value={this.state.after.TimeChangeValue}
                  onChange={(value) => {
                    this.saveField("TimeChangeValue", value);
                  }}
                  error={
                    this.state.validateStatus.TimeChangeValue !== undefined
                  }
                />
              </Grid>
              <Grid item xs={14}></Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Floating}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FloatingHours"
                  key="FloatingHours"
                  placeholder="Time"
                  fullWidth
                  value={this.state.after.FloatingHours}
                  onChange={(value) => {
                    this.saveField("FloatingHours", value);
                  }}
                  error={this.state.validateStatus.FloatingHours !== undefined}
                />
              </Grid>
              <Grid item xs={8}>
                <OutlinedInput
                  id="Floating"
                  key="Floating"
                  fullWidth
                  multiline
                  placeholder="Cause"
                  value={this.state.after.Floating}
                  onChange={(event) => {
                    this.saveField("Floating", event.target.value);
                  }}
                  error={this.state.validateStatus.Floating !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.OtherChange}</FormLabel>
              </Grid>
              <Grid item xs={8}>
                <OutlinedInput
                  id="OtherChange"
                  key="OtherChange"
                  fullWidth
                  multiline
                  placeholder="(if any)"
                  value={this.state.after.OtherChange}
                  onChange={(event) => {
                    this.saveField("OtherChange", event.target.value);
                  }}
                  error={this.state.validateStatus.OtherChange !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Remark}</FormLabel>
              </Grid>
              <Grid item xs={10}>
                <OutlinedInput
                  id="Remark"
                  key="Remark"
                  fullWidth
                  multiline
                  placeholder=""
                  value={this.state.after.Remark}
                  onChange={(event) => {
                    this.saveField("Remark", event.target.value);
                  }}
                  error={this.state.validateStatus.Remark !== undefined}
                />
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={2}>
                <FormLabel className={classes.quantrong}>Attachs:</FormLabel>
              </Grid>
              <Grid item xs={10}>
                <OutlinedInput
                  id="Attachs"
                  key="Attachs"
                  fullWidth
                  value={this.getAttachs()}
                  onChange={(event) => {
                    this.state.Attachs = [];
                    this.setState({ dataChanged: true });
                  }}
                  placeholder=""
                  error={this.state.validateStatus.BunkerFileName !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <Dropzone
                  maxFiles={5}
                  minSize={1}
                  maxSize={500 * 1024}
                  multiple={true}
                  validator={(file) => {
                    if (file.size > 0 && file.size < 500 * 1024) {
                      return null;
                    }
                    alert("File too large");
                    return true;
                  }}
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      this.state.Attachs = [];
                      acceptedFiles.map((file) => {
                        this.state.Attachs.push({
                          FileID: uuid(),
                          FileName: file.name,
                          File: file,
                        });
                      });
                      this.setState({ dataChanged: true });
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button fullWidth variant="contained">
                          Upload
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
              <Grid item xs={5}>
                <FormLabel>{"(Max size: 500KB)"}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.Captain}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Captain"
                  key="Captain"
                  fullWidth
                  placeholder=""
                  value={this.state.after.Captain}
                  onChange={(event) => {
                    this.saveField("Captain", event.target.value);
                  }}
                  error={this.state.validateStatus.Captain !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  NoonAtSeaReportEditDialog
);
