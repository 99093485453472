import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
} from "@mui/material";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import { parse } from "date-fns/esm";
const myLib = require("../lib/MyLib");
const server = require("../lib/server");

function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateEmpty(data, ["VoyageCode"]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}

function LaySoTiepTheo(danhsach) {
  var voy = "";

  try {
    if (danhsach.length > 0) {
      for (var i = 0; i < danhsach.length; i++) {
        var item = danhsach[i];
        var code = item.VoyageCode;
        console.log(code);
        if (code.length >= 10) {
          var so = parseInt(code.substring(5, 7));
          console.log(so, code.substring(5, 7));
          if (!isNaN(so)) {
            so = so + 1;
            voy =
              code.substring(0, 5) +
              (so >= 10 ? so : "0" + so) +
              code.substring(7);
            console.log(so, voy);
            break;
          }
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
  return voy;
}

export default class AddVoyageDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      VoyageCode: LaySoTiepTheo(props.DM_Voyage),
      validateStatus: {},
    };
  }

  componentDidMount() {}
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  doSave = () => {
    try {
      var check = validate_Data(this.state);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_DM_Voyage_Update",
            ThamSo: {
              VoyageCode: this.state.VoyageCode,
            },
          })
          .then((response) => {
            alert("Cập nhật thành công!");
            this.props.close(this.state.VoyageCode);
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      }
    } catch (ex2) {
      showError(ex2);
    }
  };
  render() {
    let tau = this.state.tauData;
    return (
      <Dialog
        open={this.props.open}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">New Voyage</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 450,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <FormLabel sx={{ ml: 1, flex: 1 }} multiline fullWidth>
              Voyage:
            </FormLabel>
            <br />
            <OutlinedInput
              fullWidth
              autoFocus
              value={this.state.VoyageCode}
              onChange={(event) => {
                this.setState({
                  VoyageCode: myLib.toUpperCase(event.target.value),
                });
              }}
              error={this.state.validateStatus.VoyageCode !== undefined}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (!this.state.saving) {
                this.doSave();
              }
            }}
            variant="contained"
          >
            {this.state.saving ? "Saving..." : "Save"}
          </Button>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
