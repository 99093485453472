/*Quan ly danh sach cac form*/
import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  Stack,
  Grid,
  Paper,
  InputLabel,
  FormLabel,
  Tabs,
  Tab,
  useRadioGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TabContext, TabPanel, TabList } from "@mui/lab";
import { makeStyles, withStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import BlockIcon from "@mui/icons-material/Block";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import RedeemIcon from "@mui/icons-material/Redeem";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import TimelineIcon from "@mui/icons-material/Timeline";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import moment from "moment";
import SelectFilter from "react-select";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { ContactPageSharp } from "@mui/icons-material";
import MenuChuyenMoi from "../components/MenuChuyenMoi";
import MyDateEditor from "../components/MyDateEditor";
import ButtonTimKiem from "../components/ButtonTimKiem";
import PagingSearchCodeDialog from "../tools/PagingSearchCodeDialog";
import SearchFormColumnsDialog from "../tools/SearchFormColumnsDialog";
import GenerateCodeDialog from "../tools/GenerateCodeDialog";

const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const geoLib = require("../lib/geoLib");

const dataStruct = require("../data/DataStruct");

const cellStyles = {
  taubien: {
    color: "blue",
  },
};
const Tau_TrangThai_Render = (props) => {
  let row = props.data;
  return row.TrangThai ? null : (
    <BlockIcon sx={{ color: "red" }} fontSize="small" />
  );
};
const Tau_NgayDen_Render = (props) => {
  let row = props.data;
  return moment(row.NgayDen).format("DD/MM/YYYY");
};
const Tau_NgayHH_Render = (props) => {
  let row = props.data;
  return moment(row.HetHan).format("DD/MM/YYYY");
};

const default_columns_grid = [
  {
    name: "TenSK",
    header: "Tên sự kiện",
    headerAlign: "center",
    defaultWidth: 180,
  },
  {
    name: "TenQuocGia",
    header: "Quốc tịch tàu",
    headerAlign: "center",
    defaultWidth: 150,
  },
  {
    name: "ThoiGianBiNan",
    header: "Bị nạn",
    headerAlign: "center",
    defaultWidth: 120,
    render: (props) =>
      moment(props.data.ThoiGianBiNan).format("DD/MM/YYYY HH:mm"),
  },
  {
    name: "ID3",
    header: "Vị trí",
    headerAlign: "center",
    defaultWidth: 120,
    render: ({ data }) =>
      data.Lat !== null && data.Lon !== null
        ? geoLib.latToDMS(data.Lat) + " - " + geoLib.lonToDMS(data.Lon)
        : "",
  },

  {
    name: "ViTri",
    header: "Vị trí",
    headerAlign: "center",
    defaultWidth: 300,
  },
  {
    name: "TinhTrang",
    header: "Tình trạng",
    headerAlign: "center",
    defaultWidth: 300,
  },
  {
    name: "TenTaiNan",
    header: "Tai nạn",
    headerAlign: "center",
    defaultWidth: 140,
  },
  {
    name: "TrangThai",
    header: "Lần báo phí",
    headerAlign: "center",
    defaultWidth: 100,
    render: ({ data }) => dataStruct.TrangThai_SuKien_Ten[data.TrangThai],
  },
  {
    name: "Duyet",
    header: "Duyệt",
    headerAlign: "center",
    defaultWidth: 100,
    render: ({ data }) => (
      <Checkbox
        size="small"
        sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
        checked={data.Duyet}
      />
    ),
  },
  {
    name: "TenVietTat",
    header: "Đơn vị",
    headerAlign: "center",
    defaultWidth: 180,
  },
  {
    name: "NguoiCN",
    header: "Người CN",
    headerAlign: "center",
    defaultWidth: 120,
  },
  {
    name: "NgayCN",
    header: "Ngày CN",
    headerAlign: "center",
    defaultWidth: 120,
    render: (props) => moment(props.data.NgayCN).format("DD/MM/YYYY HH:mm"),
  },
];

const defaultSortInfo = { name: "MaChuyen", dir: 0, type: "date" };
//tra ve index column tuy theo sort infor
const getSortColumnIndex = (columns, sortInfor) => {
  var find_index = 0;
  for (var i = 0; i < columns.length; i++) {
    var item = columns[i];
    if (item.name == sortInfor.name) {
      find_index = i;
      break;
    }
  }
  return find_index;
};

class SuKienPage extends React.Component {
  selectionCounter = 0; //dem so lan click chon thong tin tau
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);
    this.columns_grid =
      global.grid_columns !== undefined &&
      global.grid_columns[this.constructor.name] !== undefined
        ? global.grid_columns[this.constructor.name]
        : default_columns_grid;
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      canSaved: false, //cho phep save hay khong
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      source_danhmuc: {
        DM_CoQuan: [],
      },
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
      sortInfo: defaultSortInfo,
      after:
        props.value === undefined
          ? {
              PageIndex: 1,
              PageSize: 20,
              Ascending: true,
              SortBy: getSortColumnIndex(this.columns_grid, defaultSortInfo), //index column duoc sap xep

              TenSK: "",
              TrangThai: 1,
              MaCQ: 0,
              Duyet: -1,
              TuNgay: null,
              DenNgay: null,
            }
          : props.value, //thong tin dang edit
      validateStatus: { error: false }, //trang thai validate
      //thong tin tau
      rowSelected: undefined, //record trong danh sach chuyen duoc chon
      date: new Date(),
      //edit
      open_tinhphi_tausong: false, //open form tinh phi tau song
      open_tinhphi_taubien: false, //open form tinh phi tau bien
      open_confirm_xoa: false, //xac nhan xoa
      open_confirm_tinhphi: false, //xac nhan bao phi
      open_confirm_ketthuc: false, //xac nhan ket thuc
      hasThem1: global.hasRoles("R311") && this.props.LoaiTau == 12,
      hasThem2: global.hasRoles("R321") && this.props.LoaiTau == 3,
    };
  }
  saveField = (fieldName, value) => {
    var s = this.state;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy"
    ) {
      this.setMyState(this.state);
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => {
        this.doSearch();
      });
    }
  };
  saveData = (obj) => {
    var s = this.state;
    s.after = { ...s.after, ...obj };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (obj.Ascending || obj.SortBy) {
      this.setMyState(s, () => this.doSearch());
    } else {
      this.setMyState(s);
    }
  };
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  componentDidMount() {
    this.loadDanhMuc();
  }
  componentDidUpdate(props, state) {}
  loadDanhMuc() {
    if (this.state.loadOnStart) {
      this.setState({ loadOnStart: false }, () => this.doSearch());
    }
    //load danh muc
    server
      .post("Data/DoRequest", {
        Function: "Proc_Table_GetAll",
        ThamSo: {
          TableNames: "DM_CoQuan",
        },
      })
      .then((response) => {
        this.setState({
          source_danhmuc: {
            ...response,
          },
        });
      })
      .catch((error) => {
        showError(error);
      });
  }
  //khi thay doi sap xep cot
  onSortInfoChange = (value) => {
    const newSort = value
      ? { type: value.type, name: value.name, dir: value.dir }
      : value;
    //ghi nhan vao sort by
    this.setState(
      {
        lastSortInfo: this.state.sortInfo
          ? this.state.sortInfo
          : this.state.lastSortInfo,
        sortInfo: newSort,
      },
      () => this.doSearch()
    );
  };
  loadData = ({ skip, limit, sortInfo }) => {
    var serverSortInfo = this.state.sortInfo
      ? this.state.sortInfo
      : this.state.lastSortInfo;

    var loader = new Promise((resolve, eject) => {
      server
        .post("Data/DoRequest", {
          Function: "Proc_TK_SuKien_Search",
          ThamSo: {
            ...this.state.after,
            PageIndex: skip / limit + 1,
            PageSize: limit,
            SortBy: getSortColumnIndex(this.columns_grid, serverSortInfo),
            Ascending: serverSortInfo.dir == 1,
          },
        })
        .then((response) => {
          this.setState({
            search_loading: false,
            search_error: false,
            search_result: {
              data: response.DataList,
              count: response.DataCount[0].Total,
            },
            rowSelected: undefined,
          });
          resolve({
            data: response.DataList,
            count: response.DataCount[0].Total,
          });
        })
        .catch((error) => {
          var message = "";
          var log = "";
          try {
            var obj = JSON.parse(error);
            message = obj.message;
            log = obj.traceLog;
          } catch (e) {
            message = error + "";
          }
          this.setState({
            search_loading: false,
            search_error: true,
            errorMessage: message,
            errorLog: log,
            search_result: { data: [], count: 0 },
            rowSelected: undefined,
          });
          resolve({ data: [], count: 0 });
        });
    });
    return loader;
  };
  //Thuc hien search
  doSearch = () => {
    var loader = this.loadData({
      skip: (this.state.after.PageIndex - 1) * this.state.after.PageSize,
      limit: this.state.after.PageSize,
      sortInfo: this.state.sortInfo,
    });
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      selectdReecord: {},
    });
  };

  //call khi user thay doi trang
  render() {
    const { classes, theme, parentHeight } = this.props;

    return (
      <div>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <RadioGroup
                row
                className={classes.smallRadioButton}
                defaultValue="1"
                name="radio-buttons-group"
                value={this.state.after.TrangThai}
                onChange={(event) => {
                  this.saveField("TrangThai", event.target.value);
                }}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="Đang xử lý"
                />
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label="Kết thúc"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={2}>
              <FormLabel></FormLabel>
            </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={3}>
              <FormLabel>Tên sự kiện:</FormLabel>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="sogcn"
                fullWidth
                autoFocus
                inputProps={{ style: { textTransform: "uppercase" } }}
                value={this.state.after.TenSK}
                onChange={(event) =>
                  this.saveField("TenSK", event.target.value)
                }
              />
            </Grid>

            <Grid item xs={2}>
              <FormLabel>Đơn vị:</FormLabel>
            </Grid>
            <Grid item xs={5}>
              <Select
                fullWidth
                value={this.state.after.MaCQ}
                onChange={(event) => this.saveField("MaCQ", event.target.value)}
                error={this.state.validateStatus.MaCQ !== undefined}
              >
                {this.state.source_danhmuc.DM_CoQuan.map((item) => (
                  <MenuItem value={item.MaCQ}>{item.TenVietTat}</MenuItem>
                ))}
                <MenuItem value={0}>-- Tất cả --</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={3}>
              <ButtonTimKiem
                variant="contained"
                disabled={this.state.search_loading}
                onClick={this.doSearch}
                onRightClickMenu={(item) => {
                  if (item.menuName == "CodeSearch") {
                    this.setState({ open_code_seach: true });
                  }
                  if (item.menuName == "Columns") {
                    this.setState({ open_columns_define: true });
                  }
                  if (item.menuName == "Generate") {
                    this.setState({ open_generate_code: true });
                  }
                }}
              ></ButtonTimKiem>
            </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={3}>
              <FormLabel>Thời gian:</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MyDateEditor
                value={this.state.after.TuNgay}
                onChange={(newValue) => this.saveField("TuNgay", newValue)}
              />
            </Grid>
            <Grid item xs={3}>
              <MyDateEditor
                value={this.state.after.DenNgay}
                onChange={(newValue) => this.saveField("DenNgay", newValue)}
              />
            </Grid>
            <Grid item xs={2}>
              <FormLabel>Duyệt:</FormLabel>
            </Grid>
            <Grid item xs={5}>
              <Select
                fullWidth
                value={this.state.after.Duyet}
                onChange={(event) =>
                  this.saveField("Duyet", event.target.value)
                }
                error={this.state.validateStatus.Duyet !== undefined}
              >
                <MenuItem value={-1}>-- Tất cả --</MenuItem>
                <MenuItem value={0}>Chưa duyệt</MenuItem>
                <MenuItem value={1}>Đã duyệt</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ height: 2 }}></div>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ReactDataGrid
              style={{ height: parentHeight - 135, fontSize: 12 }}
              showZebraRows={true}
              i18n={ReactDataGrid_i18n}
              columns={this.columns_grid}
              pagination={true}
              multiSelect={false}
              showHoverRows={false}
              checkboxColumn={{
                render: (props) => (
                  <input
                    type="checkbox"
                    readOnly
                    checked={props.rowSelected}
                  ></input>
                ),
              }}
              defaultLimit={10}
              limit={this.state.after.PageSize}
              skip={
                (this.state.after.PageIndex - 1) * this.state.after.PageSize
              }
              onSkipChange={(skip) => {
                this.saveField(
                  "PageIndex",
                  skip / this.state.after.PageSize + 1
                );
              }}
              dataSource={this.state.search_dataSource}
              idProperty="MaSK"
              headerHeight={30}
              rowHeight={30}
              allowUnsort={false}
              defaultSortInfo={defaultSortInfo}
              sortInfo={this.state.sortInfo}
              onSortInfoChange={this.onSortInfoChange}
              onLimitChange={(v) => this.saveField("PageSize", v)}
              emptyText={loadDataError(
                this.state.search_error,
                this.state.search_result.data.length === 0,
                this.state.errorMessage,
                this.state.errorLog
              )}
              skipLoadOnMount={true}
              enableSelection={true}
              onSelectionChange={({ data, selected, unselected }) => {
                var now = new Date().getTime();
                var diff = now - this.lastClick;
                if (diff > 300) {
                  this.setState({ rowSelected: data });
                } else {
                  //double click
                  this.setState(
                    {
                      rowSelected: data,
                    },
                    () => {
                      if (!this.props.disableChon) {
                        this.props.close(
                          this.state.rowSelected,
                          this.state.search_result.data
                        );
                      }
                    }
                  );
                }
                this.lastClick = now;
              }}
            ></ReactDataGrid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} columns={24}>
              {this.state.hasThem1 || this.state.hasThem2 ? (
                <Grid item xs={3}>
                  <Button
                    variant="contained"
                    disabled={
                      this.state.rowSelected === undefined ||
                      this.state.rowSelected.KetThuc
                    }
                    fullWidth
                    onClick={() => {
                      this.setMyState({
                        open_tinhphi_taubien: true,
                        edittingRecord: this.state.rowSelected,
                      });
                    }}
                  >
                    Tính phí
                  </Button>
                </Grid>
              ) : null}
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    this.setMyState({
                      open_edit_sukien: true,
                      edittingRecord: this.state.rowSelected,
                    });
                  }}
                >
                  Thêm
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        {this.state.open_code_seach ? (
          <PagingSearchCodeDialog
            open={true}
            DieuKien={this.state.after}
            gridColumns={this.columns_grid}
            close={(record) => {
              this.setState({
                open_code_seach: false,
              });
            }}
          />
        ) : null}
        {this.state.open_columns_define ? (
          <SearchFormColumnsDialog
            open={true}
            formName={this.constructor.name}
            columns_default={this.columns_grid}
            close={(record) => {
              if (record) {
                this.columns_grid = myLib.applyGridColumns(
                  default_columns_grid,
                  record
                );
              }
              this.setState({
                open_columns_define: false,
              });
            }}
          />
        ) : null}
        {this.state.open_generate_code ? (
          <GenerateCodeDialog
            open={true}
            close={(record) => {
              this.setState({
                open_generate_code: false,
              });
            }}
          />
        ) : null}
        
      </div>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(SuKienPage);
