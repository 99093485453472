import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  AppBar,
  Stack,
  Typography,
  CircularProgress,
  Paper,
  OutlinedInput,
  Grid,
  FormLabel,
  FormControlLabel,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles, withStyles } from "@mui/styles";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/blue-light.css";
import "@inovua/reactdatagrid-community/theme/blue-dark.css";
import "@inovua/reactdatagrid-community/theme/amber-light.css";
import "@inovua/reactdatagrid-community/theme/amber-dark.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReportDataEditDialog from "./PhanMemEditDialog";
import SimpleDialog from "../components/SimpleDialog";
import { filterStyles } from "../components/common";
import moment from "moment";
import SelectFilter from "react-select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  ClosedCaptionDisabledOutlined,
  ThirtyFpsSelect,
} from "@mui/icons-material";
import { DatePicker, TreeView, TreeItem } from "@mui/lab";
import QuyenEditDialog from "./QuyenEditDialog";

const myLib = require("../lib/MyLib");
const server = require("../lib/server");

const styles = (theme) => ({
  red: {
    color: "white",
    backgroundColor: "#fc9003",
  },
  blue: {
    color: "white",
    backgroundColor: "#0384fc",
  },
  uppercaseText: {
    textTransform: "uppercase",
  },
  quantrong: {
    fontWeight: "bold !important",
  },
  colorBlue: {
    color: "blue",
  },
});

function isEmpty(v) {
  return v === undefined || v === null || v + "" === "";
}
function isZero(v) {
  return v === undefined || v === null || parseFloat(v) <= 0;
}
function validate_Empty(data, fields) {
  var ret = {};
  fields.map((name, index) => {
    if (isEmpty(data[name])) {
      ret[name] = true;
    }
  });
  return ret;
}
function validate_ChuTau(data) {
  var ret = validate_Empty(data, ["SCD", "GRCD", "GRNM"]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
    data.GRCD = data.GRCD.toUpperCase();
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}

class NhomQuyenEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      canSaved: false, //cho phep save hay khong
      dataChanged: false, //du lieu co su thay doi hay khong
      saving: false, //co dang save du lieu hay khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      loading_coquan: true, // co dang load ding danh muc nguon tin ko
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong

      listData: [],
      after: {
        SCD: props.MaPhanMem, //dang cap nhat cho phan mem nao
        GRCD: "",
        GRNM: "",
        DESCRIPTION: "",
        ACTIVE: 1,
        ...props.edittingRecord,
      },
      selectedIds:
        props.edittingRecord !== undefined
          ? props.edittingRecord.RTCDS.split(";")
          : [],
      expandedIds: [], //danh sach id expand
      expandedGroupIds: [], //danh sach id
      DanhSachQuyen: [], //danh sach quyen phan mem
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  handleClose(selection) {
    this.props.close(selection);
  }
  handleServerError(error) {
    var message = "";
    var log = "";
    try {
      var obj = JSON.parse(error);
      message = obj.message;
      log = obj.traceLog;
      var log_detail = JSON.parse(log);

      if (log_detail && log_detail.Message) {
        message += "\n<br>" + log_detail.Message;
        if (log_detail.ExceptionMessage) {
          message = log_detail.ExceptionMessage + "\n" + message;
        }
      }
    } catch (e) {
      message = error + "";
    }
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }
  saveField = (fieldName, value) => {
    var s = this.state;
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    }
    if (fieldName !== "PageSize" && fieldName !== "PageIndex") {
      this.setState(this.state);
    } else {
      this.setState(this.state, () => {
        this.doSearch();
      });
    }
    this.setState(this.state);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = this.state;
    s = { ...s, after: { ...s.after, ...obj } };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    this.setState(s);
  };
  loadDanhMuc() {
    this.doSearch();
  }

  renderTree = (nodeData, listQuyens, PhanMem, classes) => {
    var subList = [];
    //tim danh sach con
    listQuyens.map((item, index) => {
      if (
        (nodeData.RTCD === null && item.GRCD === null) ||
        item.GRCD === nodeData.RTCD
      ) {
        subList.push(item);
      }
    });
    var isRoot = nodeData.RTCD === null;

    var nodeName = nodeData.RTCD === null ? PhanMem.SNM : nodeData.RTNM;
    var nodeId =
      nodeData.RTCD === null ? PhanMem.SCD : nodeData.RTCD;
    var isChecked =
      nodeId === this.state.selectedIds.find((item) => item === nodeId);
    var isRight = nodeData.ISRT === "1";
    
    var has = true;
    return (
      <TreeItem
        key={nodeId}
        nodeId={nodeId}
        label={
          isRoot ? (
            <FormLabel>{nodeName}</FormLabel>
          ) : (
            <FormControlLabel
              control={
                <Checkbox
                  readOnly={!has}
                  size="small"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                  checked={isChecked}
                  onChange={(event) => {
                    this.saveField("rights", nodeId, event.target.checked);
                  }}
                />
              }
              label={
                <Typography className={isRight ? undefined : classes.quantrong}>
                  {nodeId} - {nodeName}
                </Typography>
              }
            />
          )
        }
      >
        {subList.length > 0
          ? subList.map((item, index) =>
              this.renderTree(item, listQuyens, PhanMem, classes)
            )
          : null}
      </TreeItem>
    );
  };
  onNodeSelect = (event, listIds) => {
    if (event.target.checked !== undefined) {
      var list = this.state.selectedIds;
      var index = list.findIndex((item) => item == listIds);
      if (index < 0) {
        list.push(listIds);
      } else {
        list.splice(index, 1);
      }
      this.setState({ selectedIds: list });
    }
  };
  doSearch = () => {
    this.setState({ loading_data: true });
    server
      .query("PhanQuyen/GetQuyenPhanMem", {
        scd: this.props.MaPhanMem,
      })
      .then((response) => {
        var listExpands = [this.props.MaPhanMem];
        response.map((item) => listExpands.push(item.RTCD));
        this.setState({
          DanhSachQuyen: response,
          expandedIds: listExpands,
          search_error: false,
          rowSelected: undefined,
          loading_data: false,
        });
      })
      .catch((error) => {
        var message = "";
        var log = "";
        try {
          var obj = JSON.parse(error);
          message = obj.message;
          log = obj.traceLog;
        } catch (e) {
          message = error + "";
        }
        this.setState({
          search_error: true,
          errorMessage: message,
          errorLog: log,
          search_result: { data: [], count: 0 },
          rowSelected: undefined,
          loading_data: false,
        });
      });
  };
  doSave = () => {
    if (this.saving) {
      return;
    }
    var check = validate_ChuTau(this.state.after);
    if (!check.error) {
      this.setState({ validateStatus: check, saving: true, errorLog: "" });
      var rtcds = "";
      this.state.DanhSachQuyen.map((quyen) => {
        var has = this.state.selectedIds.find((item) => item == quyen.RTCD);
        if (has) {
          rtcds += (rtcds !== "" ? ";" : "") + quyen.RTCD;
        }
      });
      server
        .post("PhanQuyen/SaveNhomQuyen", {
          ...this.state.after,
          RTCDS: rtcds,
        })
        .then((response) => {
          var before = {};
          var after = {
            ...this.state.after,
          };
          Object.assign(before, after);
          this.setState({
            before: before,
            after: after,
            dataChanged: false,
            canSaved: false,
            saving: false,
            errorMessage: "",
            errorLog: "",
          });
          alert("Cập nhật thành công!");
          this.props.close(after);
        })
        .catch((error) => {
          this.setState({ saving: false });
          this.handleServerError(error);
        });
    } else {
      this.setState({ validateStatus: check });
      toast.error("Vui lòng kiểm tra các trường đánh dấu đỏ!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  };
  handleServerError(error) {
    var message = "";
    var log = "";
    try {
      var obj = JSON.parse(error);
      message = obj.message;
      log = obj.traceLog;
      var log_detail = JSON.parse(log);

      if (log_detail && log_detail.Message) {
        message += "\n<br>" + log_detail.Message;
        if (log_detail.ExceptionMessage) {
          message = log_detail.ExceptionMessage + "\n" + message;
        }
      }
    } catch (e) {
      message = error + "";
    }
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }
  render() {
    const { classes, theme } = this.props;
    
    return (
      <Dialog
        open={this.props.open}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <AppBar sx={{ position: "relative" }}>
            <Stack direction="row">
              <Typography
                sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
                variant="h6"
                component="div"
              >
                Nhóm quyền phần mềm: {this.props.MaPhanMem}
              </Typography>
              <Stack direction="row" spacing={4}>
                {this.state.loading_data ? (
                  <CircularProgress color="warning" size={24} />
                ) : null}
                {this.state.saving ? (
                  <CircularProgress color="warning" size={24} />
                ) : null}
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.doSave()}
                >
                  Lưu
                </Button>

                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.handleClose(undefined)}
                >
                  Thoát
                </Button>
              </Stack>
            </Stack>
          </AppBar>
          <Paper variant="outlined">
            <Grid container spacing={1} columns={12}>
              <Grid item xs={1}>
                <FormLabel>Mã nhóm(*):</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  fullWidth
                  autoFocus={this.edittingRecord === undefined}
                  inputProps={{
                    className: classes.uppercaseText,
                    maxLength: 5,
                  }}
                  value={this.state.after.GRCD}
                  readOnly={this.props.edittingRecord !== undefined}
                  onChange={(event) => {
                    this.saveField("GRCD", event.target.value);
                  }}
                  error={this.state.validateStatus.GRCD !== undefined}
                />
              </Grid>
              <Grid item xs={9}></Grid>
              <Grid item xs={1}>
                <FormLabel>Tên nhóm(*):</FormLabel>
              </Grid>
              <Grid item xs={6}>
                <OutlinedInput
                  fullWidth
                  autoFocus={this.props.edittingRecord !== undefined}
                  value={this.state.after.GRNM}
                  onChange={(event) => {
                    this.saveField("GRNM", event.target.value);
                  }}
                  error={this.state.validateStatus.GRNM !== undefined}
                />
              </Grid>
              <Grid item xs={5}></Grid>
              <Grid item xs={1}>
                <FormLabel>Mô tả:</FormLabel>
              </Grid>
              <Grid item xs={6}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.DESCRIPTION}
                  onChange={(event) => {
                    this.saveField("DESCRIPTION", event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={11}>
                <FormControlLabel
                  label="Hoạt động"
                  labelPlacement="end"
                  fullWidth
                  control={
                    <Checkbox
                      size="medium"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                      checked={this.state.after.ACTIVE > 0}
                      onChange={(event) =>
                        this.saveField("ACTIVE", event.target.checked ? 1 : 0)
                      }
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>Danh sách quyền trong nhóm:</FormLabel>
              </Grid>
              <Grid item xs={12}>
                <TreeView
                  aria-label="rich object"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpanded={[]}
                  expanded={this.state.expandedIds}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{
                    height: 510,
                    flexGrow: 1,
                    overflowY: "auto",
                    backgroundColor: "aliceblue",
                  }}
                  onNodeSelect={this.onNodeSelect}
                >
                  {this.renderTree(
                    { RTCD: null, RTNM: null },
                    this.state.DanhSachQuyen,
                    this.props.PhanMem,
                    classes
                  )}
                </TreeView>
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
export default withStyles(styles, { withTheme: true })(NhomQuyenEditDialog);
