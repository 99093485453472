import "@inovua/reactdatagrid-enterprise/index.css";
import { Explicit, Language } from "@mui/icons-material";
import {
  AppBar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  IconButton,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";

import "react-toastify/dist/ReactToastify.css";
import Dropzone from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MenuButtons from "../../components/MenuButtons";
import MyDateEditor from "../../components/MyDateEditor";
import MySelect from "../../components/MySelect";
import MySelectFilter from "../../components/MySelectFilter";
import SimpleDialog from "../../components/SimpleDialog";
import {
  formStyles,
  handleServerError,
  showError,
  showSuccess,
} from "../../components/common";
import FieldNameDialog from "../../pages/FieldNameDialog";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ClearIcon from "@mui/icons-material/Clear";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../../lib/MyLib");
const server = require("../../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Repair Requisition" : "Yêu cầu sửa chữa";
const fieldLabels_EN = {
  ShipCode: "M/V:",
  BoPhan: "Department:",
  MaYeuCau: "Repair Requisition:",
  IsNormal: "Normal:",
  IsDocking: "Docking:",
  NgayYeuCau: "Date:",
  GhiChu: "Remark",
  NguoiDeNghi: "Created by:",
  ThuyenTruong: "Captain:",
};
const fieldLabels_VN = {
  ShipCode: "Tàu:",
  BoPhan: "Bộ phận:",
  MaYeuCau: "YCSC:",
  IsNormal: "Normal:",
  IsDocking: "Docking:",
  NgayYeuCau: "Ngày lập:",
  GhiChu: "Ghi chú",
  NguoiDeNghi: "Người đề nghị:",
  ThuyenTruong: "Thuyền trưởng:",
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;
function getFieldNames() {
  var list = [];
  var keys = Object.keys(fieldLabels);
  for (var i = 0; i < keys.length; i++) {
    list.push({
      FieldName: keys[i],
    });
  }
  return list;
}
const listFields = getFieldNames();
const buttonExports = [
  {
    ID: "TiengViet",
    Label: "Tiếng Việt",
    LabelEN: "Tiếng Việt",
    Icon: <Language fontSize="small" />,
  },
  {
    ID: "TiengAnh",
    Label: "English",
    LabelEN: "English",
    Icon: <Explicit fontSize="small" />,
  },
];
const buttonLabels = englishUI
  ? {
      btnSave: "Save",
      btnClose: "Close",
      btnExport: "Export",
      btnEmail: "Email",
      btnImport: "Import",
    }
  : {
      btnSave: "Lưu",
      btnClose: "Thoát",
      btnExport: "Xuất file",
      btnEmail: "Email",
      btnImport: "Import",
    };
//ham filter su dung cho combobox
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  console.log(data);
  var ret = myLib.validateFields(data, {
    BoPhan: {
      allowEmpty: "false",
    },
    MaYeuCau: {
      allowEmpty: "false",
    },
    NgayYeuCau: {
      allowEmpty: "false",
    },
    NguoiDeNghi: {
      allowEmpty: "false",
    },
    ThuyenTruong: {
      allowEmpty: "false",
    },
  });

  ret.error = Object.keys(ret).length > 0;

  ret.RepairJobs = data.RepairJobs.map((job) => {
    return {
      Code: myLib.isEmpty(job.Code),
      NoiDung:  myLib.isEmpty(job.NoiDung),
    };
  });

  ret.error = ret.error || ret.RepairJobs.some((job) => job.NoiDung || job.Code);

  return ret;
}

/*------------------------------------FORM--------------------------------------*/
class RepairRequisitionEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      DanhMuc: { DM_BoPhan: [], DM_Codes: [] },
      validateStatus: { error: false }, //trang thai validate
      after: {
        YeuCauID: null,
        ShipCode: global.ShipInfor.ShipCode,
        BoPhan: null,
        MaYeuCau: null,
        STT: null,
        IsNormal: true,
        IsDocking: false,
        NgayYeuCau: new Date(),
        Nam: new Date().getFullYear(),
        TrangThai: 0,
        GhiChu: null,
        RepairJobs: [this.newRecord()],
        NguoiDeNghi: localStorage.getItem("RepairRequisition.NguoiDeNghi"),
        ThuyenTruong: localStorage.getItem("RepairRequisition.ThuyenTruong"),
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
    };
  }

  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps) {
    return { open: nextProps.open };
  }

  componentDidMount() {
    this.loadDanhMuc("DM_BoPhan,DM_Codes", () => {});
  }

  loadDanhMuc(danhsach, nextFunc) {
    //load danh muc
    if (danhsach) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: danhsach,
          },
        })
        .then((response) => {
          this.setState(
            {
              DanhMuc: {
                ...this.state.DanhMuc,
                ...response,
              },
            },
            () => nextFunc()
          );
        })
        .catch((error) => {
          showError(error);
          nextFunc();
        });
    }
  }

  getNextStt() {
    if (this.state.after.Nam && this.state.after.BoPhan) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_CP_YeuCauSC_NextSTT",
          ThamSo: {
            Nam: this.state.after.Nam,
            BoPhan: this.state.after.BoPhan,
            IsDocking: this.state.after.IsDocking,
          },
        })
        .then((response) => {
          this.saveField("MaYeuCau", response.CP_YeuCauSC[0].Request);
          this.saveField("STT", response.CP_YeuCauSC[0].STT);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  saveField = (fieldName, value) => {
    var s = this.state; //new value

    s.after[fieldName] = value;

    s.dataChanged = true;
    this.setState({ saving: false });
  };
  saveData = (obj) => {
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  saveRecord = (record, index, field, value) => {
    record[field] = value;
    var s = { after: { ...this.state.after } }; //new value
    s.after.RepairJobs[index] = record;
    s.dataChanged = true;
    this.setState(s);
  };

  newRecord() {
    return {
      STT: 1,
      Code: "N/A",
      CodeName: "N/A - (Not available)",
      NoiDung: null,
      TrangThaiXL: 0,
      GhiChu: null,
    };
  }

  addRecord() {
    var s = { after: { ...this.state.after } };
    s.after.RepairJobs.push(this.newRecord());
    s.after.RepairJobs.forEach((job, index) => {
      return {
        ...job,
        STT: index + 1,
      };
    });
    s.dataChanged = true;
    this.setState(s);
  }

  removeRecord(index) {
    var s = { after: { ...this.state.after } };
    s.after.RepairJobs.splice(index, 1);
    s.after.RepairJobs.forEach((job, index) => {
      return {
        ...job,
        STT: index + 1,
      };
    });
    s.dataChanged = true;

    this.setState(s);
  }

  doSave = () => {
    try {
      if (this.state.after.YeuCauID == null) {
        localStorage.setItem(
          "RepairRequisition.NguoiDeNghi",
          this.state.after.NguoiDeNghi
        );
        localStorage.setItem(
          "RepairRequisition.ThuyenTruong",
          this.state.after.ThuyenTruong
        );
      }
      var check = validate_Data(this.state.after);
      console.log(check, this.state.after);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_CP_YeuCauSC_Update",
            ThamSo: {
              ShipCode: this.state.after.ShipCode,
              YeuCauID: this.state.after.YeuCauID,
              BoPhan: this.state.after.BoPhan,
              NgayYeuCau: this.state.after.NgayYeuCau,
              Nam: this.state.after.Nam,
              STT: this.state.after.STT,
              MaYeuCau: this.state.after.MaYeuCau,
              IsNormal: this.state.after.IsNormal,
              IsDocking: this.state.after.IsDocking,
              NguoiDeNghi: this.state.after.NguoiDeNghi,
              ThuyenTruong: this.state.after.ThuyenTruong,
              TrangThai: this.state.after.TrangThai,
              GhiChu: this.state.after.GhiChu,
              NoiDung: this.state.after.RepairJobs.filter(
                (job) => !myLib.isEmpty(job.Code) && !myLib.isEmpty(job.NoiDung)
              ).map((job, index) => {
                return {
                  ...job,
                  STT: index + 1,
                };
              }),
            },
          })
          .then((response) => {
            this.state.after.YeuCauID = response.CP_YeuCauSC[0].YeuCauID; //ghi lai ma
            this.setState({
              dataSaved: true,
              dataChanged: false,
              canSaved: false,
              saving: false,
              errorMessage: "",
              errorLog: "",
            });
            showSuccess("Cập nhật thành công!");
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };

  doSendEmail = (language) => {
    var thamso = {
      YeuCauID: this.state.after.YeuCauID,
      Form: language,
    };
    server
      .post("ChiPhi/Outlook", thamso)
      .then((response) => {
        //done
      })
      .catch((error) => {
        showError(error);
      });
  };
  readOneFile(fileData) {
    //tao promies de doc 1 file
    return new Promise((resolve, reject) => {
      if (fileData.File) {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const result = e.target.result;
          resolve(result); //ArrayBuffer
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(fileData.File);
      } else {
        resolve(fileData.Content);
      }
    });
  }
  doReadExcel(files) {
    this.setState({ saving: true });
    //upload file excel
    files.map((file, index) => {
      if (file.name !== undefined) {
        const fileData = {
          File: file,
          Content: null,
        };

        this.readOneFile(fileData).then((fileContent) => {
          try {
            this.setState({ uploading: true });
            server
              .post("ChiPhi/DoRequest", {
                Function: "Proc_CP_YeuCauSC_LoadExcel",
                ThamSo: {
                  FileName: file.name,
                  NoiDungFile: Buffer.from(fileContent).toString("base64"),
                },
              })
              .then((response) => {
                let yeucau = response.CP_YeuCauSC[0];
                let hangmuc = response.CP_YeuCauSC_ND; //danh sach noi dung
                var jobs = [];
                hangmuc.map((item, index) => {
                  jobs.push({
                    Code: item.Code ?? "N/A",
                    CodeName: item.Code
                      ? item.Code + ". " + item.CodeName
                      : "N/A - (Not available)",
                    NoiDung: item.NoiDung,
                    SoLuong: item.SoLuong,
                    DVT: item.DVT,
                    MucLuc: item.MucLuc,
                  });
                });
                this.state.after = {
                  ...this.state.after,
                  IsNormal: yeucau.IsNormal === 1,
                  IsDocking: yeucau.IsDocking === 1,
                  NgayYeuCau: yeucau.NgayYeuCau,
                  NguoiDeNghi: yeucau.NguoiDeNghi,
                  ThuyenTruong:
                    yeucau.ThuyenTruong ?? this.state.after.ThuyenTruong,
                };
                this.state.after.RepairJobs = jobs;
                this.setState(
                  {
                    saving: false,
                  },
                  () => this.getNextStt()
                );
              })
              .catch((error) => {
                handleServerError(error);
                this.setState({ saving: false, errorLog: error + "" });
              });
          } catch (e) {
            console.log(e);
            this.setState({
              saving: false,
            });
            showError("File không đúng định dạng.");
          }
        });
      }
    });
  }
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };
  render() {
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
              {this.state.loading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                <></>
              )}
              <Dropzone
                maxFiles={1}
                multiple={false}
                onDrop={(files) => this.doReadExcel(files)}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button color="success" variant="contained" fullWidth>
                      {buttonLabels.btnImport}
                    </Button>
                  </div>
                )}
              </Dropzone>
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave()}
              >
                {buttonLabels.btnSave}
              </Button>

              <MenuButtons
                color="success"
                englishUI={englishUI}
                title={buttonLabels.btnExport}
                SubActions={buttonExports}
                disabled={
                  this.state.after.YeuCauID == null || this.state.saving
                }
                onClick={(subButton) => {
                  window.location.href = server.getRequest(
                    `ChiPhi/GetYeuCauSC?YeuCauID=${this.state.after.YeuCauID}&Form=${subButton.ID}`
                  );
                }}
              />
              <MenuButtons
                color="success"
                englishUI={englishUI}
                title={buttonLabels.btnEmail}
                SubActions={buttonExports}
                disabled={
                  this.state.after.YeuCauID == null || this.state.saving
                }
                onClick={(subButton) => {
                  this.doSendEmail(subButton.ID);
                }}
              />
              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 2,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ShipCode",
                    })
                  }
                >
                  {fieldLabels.ShipCode}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="ShipCode"
                  key="ShipCode"
                  fullWidth
                  readOnly
                  placeholder=""
                  value={this.state.after.ShipCode}
                  onChange={(event) => {
                    this.saveField("ShipCode", event.target.value);
                  }}
                  error={this.state.validateStatus.ShipCode !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "BoPhan",
                    })
                  }
                >
                  {fieldLabels.BoPhan}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MySelect
                  id={"BoPhan"}
                  key={"BoPhan"}
                  fullWidth
                  autoFocus={false}
                  options={this.state.DanhMuc.DM_BoPhan}
                  optionLabel="TenBoPhan"
                  optionValue="BoPhan"
                  value={this.state.after.BoPhan}
                  onChange={(event) => {
                    var bophan_cu = this.state.after.BoPhan;
                    this.saveField("BoPhan", event.target.value);
                    if (this.state.after.YeuCauID == null) {
                      this.getNextStt();
                    } else {
                      var mayeucau = this.state.after.MaYeuCau;
                      mayeucau = mayeucau.replace(
                        "-" + bophan_cu + "-",
                        "-" + event.target.value + "-"
                      );
                      this.saveField("MaYeuCau", mayeucau);
                    }
                  }}
                  error={this.state.validateStatus.BoPhan !== undefined}
                ></MySelect>
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "MaYeuCau",
                    })
                  }
                >
                  {fieldLabels.MaYeuCau}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="MaYeuCau"
                  key="MaYeuCau"
                  fullWidth
                  placeholder=""
                  value={this.state.after.MaYeuCau}
                  onChange={(event) => {
                    this.saveField("MaYeuCau", event.target.value);
                  }}
                  error={this.state.validateStatus.MaYeuCau !== undefined}
                />
              </Grid>
            </Grid>

            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={24}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "IsNormal",
                    })
                  }
                >
                  {fieldLabels.IsNormal}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <Checkbox
                  id="IsNormal"
                  key="IsNormal"
                  sx={{ padding: 0 }}
                  checked={this.state.after.IsNormal}
                  onChange={(event) => {
                    this.saveField("IsNormal", event.target.checked);
                    this.saveField("IsDocking", !event.target.checked);
                    this.getNextStt();
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "IsDocking",
                    })
                  }
                >
                  {fieldLabels.IsDocking}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <Checkbox
                  id="IsDocking"
                  key="IsDocking"
                  sx={{ padding: 0 }}
                  checked={this.state.after.IsDocking}
                  onChange={(event) => {
                    this.saveField("IsDocking", event.target.checked);
                    this.saveField("IsNormal", !event.target.checked);
                    this.getNextStt();
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "NgayYeuCau",
                    })
                  }
                >
                  {fieldLabels.NgayYeuCau}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateEditor
                  id="NgayYeuCau"
                  key="NgayYeuCau"
                  fullWidth
                  value={this.state.after.NgayYeuCau}
                  onChange={(value) => {
                    this.saveField("NgayYeuCau", value);
                    this.saveField("Nam", new Date(value).getFullYear());
                    this.getNextStt();
                  }}
                  error={this.state.validateStatus.NgayYeuCau !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  Repair Jobs
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={1}>
                <FormLabel>
                  <b>TT</b>
                </FormLabel>
              </Grid>
              <Grid item xs={5}>
                <FormLabel>
                  <b>Code</b>
                </FormLabel>
              </Grid>
              <Grid item xs={1}>
                <FormLabel>
                  <b>No.</b>
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <FormLabel>
                  <b>Description</b>
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>
                  <b>Unit</b>
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>
                  <b>Quantity</b>
                </FormLabel>
              </Grid>
              <Grid item xs={1}>
                {" "}
              </Grid>
              {this.state.after.RepairJobs.map((item, index) => (
                <>
                  <Grid item xs={1}>
                    <FormLabel>{index + 1}.</FormLabel>
                  </Grid>
                  <Grid item xs={5}>
                    <MySelectFilter
                      fullWidth
                      options={this.state.DanhMuc.DM_Codes.map((item) => {
                        return {
                          Code: item.Code,
                          CodeName: `${item.Code} - ${item.CodeName}`,
                        };
                      })}
                      optionValue="Code"
                      optionLabel="CodeName"
                      placeholder="Select code"
                      value={item}
                      onChange={(selectedItem) => {
                        if (selectedItem) {
                          this.saveRecord(
                            item,
                            index,
                            "Code",
                            selectedItem.Code
                          );
                          this.saveRecord(
                            item,
                            index,
                            "CodeName",
                            selectedItem.CodeName
                          );
                        }
                      }}
                      error={
                        Array.isArray(this.state.validateStatus.RepairJobs) &&
                        this.state.validateStatus.RepairJobs[index]?.Code ==
                          true
                      }
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <OutlinedInput
                      fullWidth
                      placeholder="No."
                      value={item.MucLuc}
                      onChange={(event) => {
                        this.saveRecord(
                          item,
                          index,
                          "MucLuc",
                          event.target.value
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <OutlinedInput
                      fullWidth
                      placeholder="Description"
                      value={item.NoiDung}
                      multiline
                      onChange={(event) => {
                        this.saveRecord(
                          item,
                          index,
                          "NoiDung",
                          event.target.value
                        );
                      }}
                      error={
                        Array.isArray(this.state.validateStatus.RepairJobs) &&
                        this.state.validateStatus.RepairJobs[index]?.NoiDung ==
                          true
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <OutlinedInput
                      fullWidth
                      value={item.DVT}
                      onChange={(event) => {
                        this.saveRecord(item, index, "DVT", event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <OutlinedInput
                      fullWidth
                      value={item.SoLuong}
                      onChange={(event) => {
                        this.saveRecord(
                          item,
                          index,
                          "SoLuong",
                          event.target.value
                        );
                      }}
                    />
                  </Grid>
                  {index == 0 ? (
                    <Grid item xs={1}>
                      <IconButton
                        size="small"
                        onClick={() => {
                          this.addRecord();
                        }}
                      >
                        <AddBoxIcon color="primary" size={24} />
                      </IconButton>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={1}>
                        <IconButton
                          size="small"
                          fullWidth
                          onClick={() => {
                            this.removeRecord(index);
                          }}
                        >
                          <ClearIcon color="warning" size={24} />
                        </IconButton>
                      </Grid>
                    </>
                  )}
                </>
              ))}
            </Grid>
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={24}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.GhiChu}</FormLabel>
              </Grid>
              <Grid item xs={22}>
                <OutlinedInput
                  id="GhiChu"
                  key="GhiChu"
                  fullWidth
                  multiline
                  value={this.state.after.GhiChu}
                  onChange={(event) => {
                    this.saveField("GhiChu", event.target.value);
                  }}
                  error={this.state.validateStatus.GhiChu !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={24}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "NguoiDeNghi",
                    })
                  }
                >
                  {fieldLabels.NguoiDeNghi}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="NguoiDeNghi"
                  key="NguoiDeNghi"
                  fullWidth
                  value={this.state.after.NguoiDeNghi}
                  onChange={(event) => {
                    this.saveField("NguoiDeNghi", event.target.value);
                  }}
                  error={this.state.validateStatus.NguoiDeNghi !== undefined}
                />
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ThuyenTruong",
                    })
                  }
                >
                  {fieldLabels.ThuyenTruong}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="ThuyenTruong"
                  key="ThuyenTruong"
                  fullWidth
                  value={this.state.after.ThuyenTruong}
                  onChange={(event) => {
                    this.saveField("ThuyenTruong", event.target.value);
                  }}
                  error={this.state.validateStatus.ThuyenTruong !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
        {this.state.show_thongtin_field ? (
          <FieldNameDialog
            open={true}
            FormName="RepairRequisitionEditDialog"
            ListFields={listFields}
            FieldName={this.state.Show_FieldName}
            close={() => {
              this.setState({ show_thongtin_field: false });
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  RepairRequisitionEditDialog
);
